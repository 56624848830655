// src/components/Home.js
import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Button, Navbar, Dropdown, NavDropdown, Nav, ButtonGroup} from 'react-bootstrap';
import './Home.css';  // 导入样式
import { fetchData, filterData } from '../components/dataUtils';  // 导入工具函数用于数据处理
import { useTranslation } from 'react-i18next'; // 导入i18next相关钩子
import {resources} from "../i18next" // 语言库
import LangSvg from "../asset/lang.svg"
import ChevronDownSvg from "../asset/chevron-down.svg"
import {Link} from "react-router-dom";
import brandsChars from "../pidnames/brand_letters"
import aLetters from "../pidnames/3_letters"
import bLetters from "../pidnames/4_letters"
import cLetters from "../pidnames/5_letters"

// 主组件
const Home = () => {
  const { t, i18n } = useTranslation(); // 初始化i18next
  const [selected, setSelected] = useState('A');  // 当前选择的标签
  const [content, setContent] = useState([]);  // 当前显示的内容

    const [select] = useState([{
        title:"Trending"
    },{
        title:"Recently listed"
    },{
        title:"Price: low to high"
    },{
        title:"Price: high to low"
    }])
    const [selectIndex,setSelectIndex] = useState(0)

  // 使用useEffect进行数据获取和设置
  useEffect(() => {
    const fetchAndSetData = async () => {
        let array = []
        const length = ()=>{
            if(selected === 'A'){
                array = aLetters
                return "3"
            }
            if(selected === 'B'){
                array = bLetters
                return "4"
            }
            if(selected === 'C'){
                array = cLetters
                return "5"
            }
            if(selected === 'D'){
                array = brandsChars
                return "all"
            }
        }
      try {
        const data = await fetch('https://api.spiapi.top/v1/Registered', {
            method:"POST",
            headers:{
                'Content-Type': 'application/json', // 指定请求的内容类型为JSON
            },
            body: JSON.stringify({ length:length() })
        })
          const filteredData = (await data.json()).data

            let list = [];
          switch (selectIndex) {
              case 0:
                  list = filteredData.sort((a, b) => {
                      return a.localeCompare(b);
                  })
                  break;
              case 1:
                  list = filteredData.sort((a, b) => {
                      return b.localeCompare(a);
                  })
                  break;
              case 2:
                  list = filteredData.sort((a, b) => {
                      if (a.length !== b.length) {
                          return a.length - b.length
                      }
                  })
                  break;
              case 3:
                  list = filteredData.sort((a, b) => {
                      if (a.length !== b.length) {
                          return b.length - a.length
                      }
                  })
                  break;
          }
          setContent(array.filter(item=>!list.includes(item)) || []);
      } catch (error) {
        console.error(t('error_fetching_data'), error);  // 输出错误信息
      }
    };

    fetchAndSetData();  // 执行数据获取和设置
  }, [selected, t,selectIndex]);  // 当selected或t改变时重新执行

    useEffect(()=>{},[])
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
        <main>
            {/* 容器 */}
            <Container fluid className="panel-container full-screen">
                {/*顶部标题及文本*/}
                <Row className="header-title-box">
                    <span className="title">{t("navbar_brand")}</span>
                    <span className="text">{t("navbar_brand_text")}</span>
                </Row>
                {/* 工具 */}
                <Nav className="tool">
                    {/*筛选 */}
                    <ButtonGroup className="bnt-box">
                        {['A', 'B', 'C', 'D'].map((label, index) => (
                            <Button
                                key={index}
                                className={`panel-button ${selected === label ? 'panel-button-selected' : ''}`}
                                style={{ color: selected === label ? '#252525' : '#6A6A6A' }}
                                onClick={() => setSelected(label)}
                            >
                                {t(`button_label_${label.toLowerCase()}`)}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <div style={{
                        flex:1
                    }} className="flex"/>
                    <div className="r">
                        {/* 排序 */}
                        {/*<Dropdown className="sort" align="end">*/}
                        {/*    <Dropdown.Toggle >*/}
                        {/*        <span>{select[selectIndex].title}</span> <img alt="" src={ChevronDownSvg} className="ChevronDownSvg"/>*/}
                        {/*    </Dropdown.Toggle>*/}
                        {/*    <Dropdown.Menu>*/}
                        {/*        {select.map((item,index:number)=><Dropdown.Item key={index} onClick={()=>setSelectIndex(index)}>{item.title}</Dropdown.Item>)}*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                        {/* 批量查询 */}
                        <Link to={"/query"}>
                            <Button style={{
                                background:"#F0B90B",
                                color: '#040404'
                            }}>
                                {t('floating_button')}
                            </Button>
                        </Link>
                    </div>
                </Nav>
                {/*  列表  */}
                <div className="content-list">
                      {content.map((item, index) => (
                        <span key={index} className="content-item">
                            <div className="content-item-center">
                            <span className="content-text">
                              {item}
                                <span className="highlight-yellow">.Pi</span>
                            </span>
                            </div>
                        </span>
                      ))}
                </div>
            </Container>
        </main>
    </>
  );
};

// 导出组件
export default Home;
