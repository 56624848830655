// src/App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages/Home.css';

import './i18n';  // 导入 i18n 配置
import {BrowserRouter, Link} from "react-router-dom"
import { Route, Routes, useLocation } from "react-router";
import {Button, Dropdown, Nav, Navbar, NavDropdown} from "react-bootstrap";
import LangSvg from "./asset/lang.svg";
import { useTranslation } from 'react-i18next'; // 导入i18next相关钩子

import Home from './pages/Home';
import Query from "./pages/Query"

function App() {
    const { t, i18n } = useTranslation(); // 初始化i18next
    // 路由配置
    const RoutesConfig = [{
        path:"/",
        element:Home
    },{
        path: "/query",
        element:Query
    }]

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
  return (
    <div className="App">
        <BrowserRouter>
            {/* 顶部栏 */}
            <Navbar className="navbar">
                <Link to={"/"}>
                <Navbar.Brand>

                        <img
                            alt="Site Logo"
                            src="/logo.svg"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' '}
                        PID - <span>pi.space</span>


                </Navbar.Brand>
                </Link>

                <Nav className="nav">
                    {/* 新增的翻译按钮 */}
                    <NavDropdown title={<img alt="" src={LangSvg}/>} className="lang">
                        <Dropdown.Item onClick={() => changeLanguage('en')}>{t('language_english')}</Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage('cn')}>{t('language_chinese')}</Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage('ko')}>{t('language_korean')}</Dropdown.Item>
                    </NavDropdown>
                    {/*<Button>*/}
                    {/*    Connect wallet*/}
                    {/*</Button>*/}
                </Nav>
            </Navbar>
            {/*路由*/}
            <Routes>
                {RoutesConfig.map((item, index: number) => (
                    <Route
                        key={index}
                        path={item.path}
                        element={
                        <item.element />
                        }
                    />
                ))}
            </Routes>
            {/*<Home />*/}
        </BrowserRouter>
    </div>
  );
}

export default App;
