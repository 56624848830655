// src/i18next/cn.js

const cn = {
  "navbar_brand": "PID 可注册",
  "navbar_brand_text":"Discover and collect cryptoart",
  "translate_button": "语言",
  "language_english": "English",
  "language_chinese": "中文",
  "language_korean": "한국어",
  "button_label_a": "3 字母",
  "button_label_b": "4 字母",
  "button_label_c": "5 字母",
  "button_label_d": "品牌字母",
  "floating_button": "批量查询",
  "error_fetching_data": "获取数据出错：",
  "batch_query":"批量查询",
  "batch_query_text":"Developed and operated by the SpacePi Foundation.",
  "enter_domain_name":"Enter domain name",
  "one_domain_name_per_line":'One domain name per line',
  "reset":"Reset",
  "get":"Get",
  "registered":"Registered",
  "unregistered":"Unregistered"
};

export default cn;
