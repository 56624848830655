import en from "./en";
import cn from "./cn";
import ko from "./ko";

export const resources ={
    en: {
        translation: en
    },
    cn: {
        translation: cn
    },
    ko: {
        translation: ko
    }
}