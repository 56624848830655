module.exports = [
    "aaa", "abc", "ace", "aim", "ale", "amp", "ana", "api", "ash", "ask",
    "atm", "att", "axe", "bbc", "bhp", "bmw", "boa", "bts", "bwc", "cbs",
    "cdc", "cia", "cib", "cit", "cms", "cnb", "cnn", "cox", "cpa", "cpi",
    "dhl", "dna", "dsm", "ebc", "ebs", "ehs", "emc", "eos", "esl", "esp",
    "eta", "eth", "ets", "evo", "faa", "fed", "fia", "fmc", "fnc", "fog",
    "fsa", "fuj", "gaa", "gdi", "gem", "gig", "gnu", "gsx", "gta", "hbo",
    "hca", "hiv", "hpe", "hsa", "ibm", "icl", "ike", "imo", "ion", "ipc",
    "ips", "jcb", "jlc", "jpm", "kfc", "kpm", "lan", "lci", "lex", "lpl",
    "mac", "mcd", "mcl", "mcs", "mfr", "mms", "moa", "mpg", "mtv", "nbc",
    "nbt", "ncc", "nct", "nfl", "npr", "ntr", "oma", "opt", "orx", "otc",
    "owc", "pbs", "pep", "pga", "php", "pnc", "pwc", "rca", "ric", "rsm",
    "sas", "sbm", "sep", "ses", "skb", "smc", "sos", "sri", "stm", "sts",
    "tds", "tnt", "tsb", "tsn", "uai", "ubs", "ups", "usg", "vhs", "vip",
    "viv", "vma", "vmc", "vol", "wax", "wms", "wwe", "xel", "xpl", "yak",
    "ycc", "yrc", "yum", "zfs", "zmc", "znc", "zoo", "zts", "aah", "aba",
    "abb", "aca", "ada", "aea", "afa", "aga", "aha", "aia", "aja", "aka",
    "ala", "ama", "ana", "aoa", "apa", "aqa", "ara", "asa", "ata", "aua",
    "ava", "awa", "axa", "aya", "aza", "baa", "bba", "bca", "bda", "bea",
    "bfa", "bga", "bha", "bia", "bja", "bka", "bla", "bma", "bna", "boa",
    "bpa", "bqa", "bra", "bsa", "bta", "bua", "bva", "bwa", "bxa", "bya",
    "bza", "caa", "cba", "cca", "cda", "cea", "cfa", "cga", "cha", "cia",
    "cja", "cka", "cla", "cma", "cna", "coa", "cpa", "cqa", "cra", "csa",
    "cta", "cua", "cva", "cwa", "cxa", "cya", "cza", "daa", "dba", "dca",
    "dda", "dea", "dfa", "dga", "dha", "dia", "dja", "dka", "dla", "dma",
    "dna", "doa", "dpa", "dqa", "dra", "dsa", "dta", "dua", "dva", "dwa",
    "dxa", "dya", "dza", "eaa", "eba", "eca", "eda", "eea", "efa", "ega",
    "eha", "eia", "eja", "eka", "ela", "ema", "ena", "eoa", "epa", "eqa",
    "era", "esa", "eta", "eua", "eva", "ewa", "exa", "eya", "eza", "faa",
    "fba", "fca", "fda", "fea", "ffa", "fga", "fha", "fia", "fja", "fka",
    "fla", "fma", "fna", "foa", "fpa", "fqa", "fra", "fsa", "fta", "fua",
    "fva", "fwa", "fxa", "fya", "fza", "gaa", "gba", "gca", "gda", "gea",
    "gfa", "gga", "gha", "gia", "gja", "gka", "gla", "gma", "gna", "goa",
    "gpa", "gqa", "gra", "gsa", "gta", "gua", "gva", "gwa", "gxa", "gya",
    "gza", "haa", "hba", "hca", "hda", "hea", "hfa", "hga", "hha", "hia",
    "hja", "hka", "hla", "hma", "hna", "hoa", "hpa", "hqa", "hra", "hsa",
    "hta", "hua", "hva", "hwa", "hxa", "hya", "hza", "iaa", "iba", "ica",
    "ida", "iea", "ifa", "iga", "iha", "iia", "ija", "ika", "ila", "ima",
    "ina", "ioa", "ipa", "iqa", "ira", "isa", "ita", "iua", "iva", "iwa",
    "ixa", "iya", "iza", "jaa", "jba", "jca", "jda", "jea", "jfa", "jga",
    "jha", "jia", "jja", "jka", "jla", "jma", "jna", "joa", "jpa", "jqa",
    "jra", "jsa", "jta", "jua", "jva", "jwa", "jxa", "jya", "jza", "kaa",
    "kba", "kca", "kda", "kea", "kfa", "kga", "kha", "kia", "kja", "kka",
    "kla", "kma", "kna", "koa", "kpa", "kqa", "kra", "ksa", "kta", "kua",
    "kva", "kwa", "kxa", "kya", "kza", "laa", "lba", "lca", "lda", "lea",
    "lfa", "lga", "lha", "lia", "lja", "lka", "lla", "lma", "lna", "loa",
    "lpa", "lqa", "lra", "lsa", "lta", "lua", "lva", "lwa", "lxa", "lya",
    "lza", "maa", "mba", "mca", "mda", "mea", "mfa", "mga", "mha", "mia",
    "mja", "mka", "mla", "mma", "mna", "moa", "mpa", "mqa", "mra", "msa",
    "mta", "mua", "mva", "mwa", "mxa", "mya", "mza", "naa", "nba", "nca",
    "nda", "nea", "nfa", "nga", "nha", "nia", "nja", "nka", "nla", "nma",
    "nna", "noa", "npa", "nqa", "nra", "nsa", "nta", "nua", "nva", "nwa",
    "nxa", "nya", "nza", "oaa", "oba", "oca", "oda", "oea", "ofa", "oga",
    "oha", "oia", "oja", "oka", "ola", "oma", "ona", "ooa", "opa", "oqa",
    "ora", "osa", "ota", "oua", "ova", "owa", "oxa", "oya", "oza", "paa",
    "pba", "pca", "pda", "pea", "pfa", "pga", "pha", "pia", "pja", "pka",
    "pla", "pma", "pna", "poa", "ppa", "pqa", "pra", "psa", "pta", "pua",
    "pva", "pwa", "pxa", "pya", "pza", "qaa", "qba", "qca", "qda", "qea",
    "qfa", "qga", "qha", "qia", "qja", "qka", "qla", "qma", "qna", "qoa",
    "qpa", "qqa", "qra", "qsa", "qta", "qua", "qva", "qwa", "qxa", "qya",
    "qza", "raa", "rba", "rca", "rda", "rea", "rfa", "rga", "rha", "ria",
    "rja", "rka", "rla", "rma", "rna", "roa", "rpa", "rqa", "rra", "rsa",
    "rta", "rua", "rva", "rwa", "rxa", "rya", "rza", "saa", "sba", "sca",
    "sda", "sea", "sfa", "sga", "sha", "sia", "sja", "ska", "sla", "sma",
    "sna", "soa", "spa", "sqa", "sra", "ssa", "sta", "sua", "sva", "swa",
    "sxa", "sya", "sza", "taa", "tba", "tca", "tda", "tea", "tfa", "tga",
    "tha", "tia", "tja", "tka", "tla", "tma", "tna", "toa", "tpa", "tqa",
    "tra", "tsa", "tta", "tua", "tva", "twa", "txa", "tya", "tza", "uaa",
    "uba", "uca", "uda", "uea", "ufa", "uga", "uha", "uia", "uja", "uka",
    "ula", "uma", "una", "uoa", "upa", "uqa", "ura", "usa", "uta", "uua",
    "uva", "uwa", "uxa", "uya", "uza", "vaa", "vba", "vca", "vda", "vea",
    "vfa", "vga", "vha", "via", "vja", "vka", "vla", "vma", "vna", "voa",
    "vpa", "vqa", "vra", "vsa", "vta", "vua", "vva", "vwa", "vxa", "vya",
    "vza", "waa", "wba", "wca", "wda", "wea", "wfa", "wga", "wha", "wia",
    "wja", "wka", "wla", "wma", "wna", "woa", "wpa", "wqa", "wra", "wsa",
    "wta", "wua", "wva", "wwa", "wxa", "wya", "wza", "xaa", "xba", "xca",
    "xda", "xea", "xfa", "xga", "xha", "xia", "xja", "xka", "xla", "xma",
    "xna", "xoa", "xpa", "xqa", "xra", "xsa", "xta", "xua", "xva", "xwa",
    "xxa", "xya", "xza", "yaa", "yba", "yca", "yda", "yea", "yfa", "yga",
    "yha", "yia", "yja", "yka", "yla", "yma", "yna", "yoa", "ypa", "yqa",
    "yra", "ysa", "yta", "yua", "yva", "ywa", "yxa", "yya", "yza", "zaa",
    "zba", "zca", "zda", "zea", "zfa", "zga", "zha", "zia", "zja", "zka",
    "zla", "zma", "zna", "zoa", "zpa", "zqa", "zra", "zsa", "zta", "zua",
    "zva", "zwa", "zxa", "zya", "zza", "aaa", "aab", "aac", "aad", "aae",
    "aaf", "aag", "aah", "aai", "aaj", "aak", "aal", "aam", "aan", "aao",
    "aap", "aaq", "aar", "aas", "aat", "aau", "aav", "aaw", "aax", "aay",
    "aaz", "aba", "abb", "abc", "abd", "abe", "abf", "abg", "abh", "abi",
    "abj", "abk", "abl", "abm", "abn", "abo", "abp", "abq", "abr", "abs",
    "abt", "abu", "abv", "abw", "abx", "aby", "abz", "aca", "acb", "acc",
    "acd", "ace", "acf", "acg", "ach", "aci", "acj", "ack", "acl", "acm",
    "acn", "aco", "acp", "acq", "acr", "acs", "act", "acu", "acv", "acw",
    "acx", "acy", "acz", "ada", "adb", "adc", "add", "ade", "adf", "adg",
    "adh", "adi", "adj", "adk", "adl", "adm", "adn", "ado", "adp", "adq",
    "adr", "ads", "adt", "adu", "adv", "adw", "adx", "ady", "adz", "aea",
    "aeb", "aec", "aed", "aee", "aef", "aeg", "aeh", "aei", "aej", "aek",
    "ael", "aem", "aen", "aeo", "aep", "aeq", "aer", "aes", "aet", "aeu",
    "aev", "aew", "aex", "aey", "aez", "afa", "afb", "abco", "defi", "ztrx",
    "nano", "btcp", "ethx", "usdt", "link", "coin", "dapp", "moon", "swap",
    "cake", "bake", "mars", "bnbz", "axel", "rari", "maki", "sushi", "yolo",
    "avax", "hoge", "safe", "dodo", "flip", "dego", "unit", "yeld", "fren",
    "vita", "xava", "apes", "wbnb", "shib", "dexe", "jgnn", "zill", "pika",
    "wifi", "nami", "mono", "beef", "slim", "cake", "beth", "wbnb", "hdao",
    "flow", "aave", "uma", "snx", "enj", "mkr", "link", "comp", "uni", "yfi",
    "crv", "sfi", "bnt", "ren", "lrc", "sand", "mana", "bat", "ocean", "storj",
    "aragon", "amp", "cvc", "fun", "icn", "plu", "omg", "lend", "dai", "hbar",
    "xlm", "dot", "sol", "vet", "trx", "theta", "qtum", "ont", "iota", "xem",
    "zrx", "bat","cake", "dodo", "flip", "dego", "unit", "yeld", "fren", "vita", "xava", "apes",
  "wbnb", "shib", "dexe", "jgnn", "zill", "pika", "wifi", "nami", "mono", "beef",
  "slim", "beth", "wbnb", "hdao", "flow", "aave", "uma", "snx", "enj", "mkr",
  "link", "comp", "uni", "yfi", "crv", "sfi", "bnt", "ren", "lrc", "sand",
  "mana", "bat", "ocean", "storj", "aragon", "amp", "cvc", "fun", "icn", "plu",
  "omg", "lend", "dai", "hbar", "xlm", "dot", "sol", "vet", "trx", "theta",
  "qtum", "ont", "iota", "xem", "zrx", "bat", "doge", "link", "eth", "btc",
  "ada", "bnb", "xrp", "ltc", "dot", "xlm", "vet", "eos", "bch", "cro",
  "bsv", "xtz", "atom", "neo", "algo", "btt", "xmr", "dash", "zec", "vet",
  "bnb", "waves", "zil", "vet", "tomo", "ftm", "nkn", "iost", "ark", "wabi",
  "ftt", "luna", "ht", "btmx", "loom", "celr", "matic", "ftt", "sand", "rune",
  "luna", "loom", "celr", "matic", "ankr", "mith", "win", "gto", "evx", "dent",
  "fun", "appc", "stpt", "cocos", "wrx", "matic", "ankr", "mith", "win", "gto",
  "evx", "dent", "fun", "appc", "stpt", "cocos", "wrx", "enj", "mkr", "link", "comp",
  "uni", "yfi", "crv", "sfi", "bnt", "ren", "lrc", "sand", "mana", "bat",
  "ocean", "storj", "aragon", "amp", "cvc", "fun", "icn", "plu", "omg", "lend",
  "dai", "hbar", "xlm", "dot", "sol", "vet", "trx", "theta", "qtum", "ont",
  "iota", "xem", "zrx", "bat", "doge", "link", "eth", "btc", "ada", "bnb","zalo",
    "xava", "apes", "moon", "nano", "meme", "cake", "rari", "jazz", "yolo", "swap", "coin",
  "dapp", "mars", "link", "safe", "bake", "defi", "xava", "avax", "hoge", "cake",
  "sushi", "unit", "yeld", "fren", "vita", "apes", "dodo", "flip", "dego", "bifi",
  "maki", "arap", "wbnb", "shib", "yams", "farm", "sake", "pika", "wifi", "mono",
  "bake", "ethx", "bank", "taco", "coin", "moon", "mars", "sushi", "apes", "xava",
  "yeld", "unit", "link", "safe", "cake", "mars", "bake", "dego", "flip", "dodo",
  "vita", "yams", "farm", "shib", "arap", "maki", "wbnb", "wifi", "pika", "mono",
  "sake", "arap", "maki", "yams", "bank", "mono", "pika", "flip", "sake", "dodo",
  "wifi", "shib", "bake", "vita", "maki", "xava", "moon", "wbnb", "farm", "dego",
  "yams", "sushi", "ethx", "apes", "sake", "mars", "dodo", "cake", "link", "unit",
  "yeld", "pika", "wifi", "mono", "vita", "bake", "sushi", "bank", "arap", "wbnb",
  "moon", "ethx", "maki", "shib", "dego", "apes", "yams", "unit", "link", "xava",
  "cake", "farm", "sake", "mars", "pika", "sushi", "yeld", "wifi", "bank", "dodo",
  "moon", "vita", "ethx", "bake", "wbnb", "arap", "shib", "dego", "cake", "maki",
  "yams", "sake", "mono", "sushi", "mars", "wifi", "pika", "ethx", "apes", "vita",
  "dodo", "bake", "farm", "xava", "link", "unit", "yams", "shib", "bank", "moon",
  "arap", "sake", "dego", "yeld", "wbnb", "mars", "cake", "vita", "pika", "dodo",
  "shib", "xava", "bake", "ethx", "sushi", "wifi", "farm", "unit", "maki", "apes"
]
