import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Button, Navbar, Dropdown, NavDropdown, Nav, ButtonGroup} from 'react-bootstrap';
import './Home.css';  // 导入样式
import './Query.css'
import { fetchData, filterData } from '../components/dataUtils';  // 导入工具函数用于数据处理
import { useTranslation } from 'react-i18next'; // 导入i18next相关钩子
import correctSvg from "../asset/correct.svg"
import mistakeSvg from "../asset/mistake.svg"

// 主组件
const Home = () => {
  const { t, i18n } = useTranslation(); // 初始化i18next
  const [content, setContent] = useState(JSON.parse(localStorage.getItem("Registered")) || []);  // 当前显示的内容

    const [text, setText] = useState('');
    const [textArray,setTextArray] = useState([])

    const [status,setStatus] = useState(0) // 状态: 0重置 1查询
    const [bntStatus,setBntStatus] = useState(false)

    const [registeredArray,setRegisteredArray] = useState([]) // 已注册数组
    const [registeredTotal , setRegisteredTotal] = useState(0)
    const [unregisteredArray,setUnregisteredArray] = useState([]) //未注册数组
    const [unregisteredTotal , setUnregisteredTotal] = useState(0)

    const [mobileStatus,setMobileStatus] = useState(false)
    const isMobileDevice = () => {

        if(window.innerWidth <= 768){
            setMobileStatus(true)
        }else{
            setMobileStatus(false)
        }

    }


    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = event.target.value;
        setText(newText)
        // 检查文本中是否包含换行符
        const containsNewLine = newText.includes('\n');

        // 如果包含换行符，将文本拆分成数组
        const linesArray = newText.split('\n').map(line => {
            // 将每行限制为30个字符
            if (line.length > 30) {
                return line.substring(0, 30);
            }
            return line;
        })

        setTextArray(linesArray)
    };

  // 使用useEffect进行数据获取和设置
  useEffect(() => {
      const fetchAndSetData = async () => {
          try {
              const data = await fetch('https://api.spiapi.top/v1/Registered', {
                  method:"POST",
                  headers:{
                      'Content-Type': 'application/json', // 指定请求的内容类型为JSON
                  },
                  body: JSON.stringify({ length:"all" })
              })
              const filteredData = (await data.json()).data
              setContent(filteredData);
              if(filteredData.length !== content.length){
                  localStorage.setItem("Registered",JSON.stringify(filteredData))
              }


          } catch (error) {
              console.error(t('error_fetching_data'), error);  // 输出错误信息
          }
      };

    fetchAndSetData();  // 执行数据获取和设置

      isMobileDevice()
      window.addEventListener('resize', isMobileDevice);
      return () => {
          window.removeEventListener('resize', isMobileDevice);
      };
  }, [ ]);  // 当selected或t改变时重新执行

    useEffect(() => {
        if(status === 0){
            setText("")
            setRegisteredArray([])
            setRegisteredArray([]);
            setRegisteredTotal(0);
            setUnregisteredArray([]);
            setUnregisteredTotal(0);
        }else{
            if (text !== "") {
                // 使用filter函数排除空值
                const nonEmptyTextArray = textArray.filter(item => item.trim() !== '');

                const { existsInContent, notExistsInContent } = nonEmptyTextArray.reduce(
                    (result, item) => {
                        if (content.includes(item)) {
                            result.existsInContent.push(item);
                        } else {
                            result.notExistsInContent.push(item);
                        }
                        return result;
                    },
                    { existsInContent: [], notExistsInContent: [] }
                );

                // 使用 Set 去除重复值
                const registeredSet = new Set(existsInContent);
                const unregisteredSet = new Set(notExistsInContent);

                // 将 Set 转换回数组
                const registeredArray = Array.from(registeredSet);
                const unregisteredArray = Array.from(unregisteredSet);

                setRegisteredArray(registeredArray);
                setRegisteredTotal(registeredArray.length);
                setUnregisteredArray(unregisteredArray);
                setUnregisteredTotal(unregisteredArray.length);
            }
        }

    }, [status, bntStatus]);


    const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
        <main>
            {/* 容器 */}
            <Container fluid className="panel-container panel-containers full-screen">
                {/*顶部标题及文本*/}
                <Row className="header-title-box">
                    <span className="title">{t("batch_query")}</span>
                    <span className="text">{t("batch_query_text")}</span>
                </Row>
                {/*  批量查询  */}
                <div className="text-box">
                    <span>{t("enter_domain_name")}</span>
                    <textarea placeholder={t("one_domain_name_per_line")} value={text} onChange={handleChange}/>
                    <div className="query-box">
                        <Button style={{
                            background:"#F5F5F5",
                            color:"#252525"
                        }} onClick={()=> {
                            setStatus(0)
                            setBntStatus(!bntStatus)
                        }}>{t("reset")}</Button>
                        <Button onClick={()=> {
                            setStatus(1)
                            setBntStatus(!bntStatus)
                        }}>{t("get")}</Button>
                    </div>
                </div>
            </Container>
            <Container fluid className="panel-container panel-containers full-screen" style={{
                maxWidth:mobileStatus?"":(registeredTotal>=3 || unregisteredTotal) >=3 ? 1200:"",
                display:text.length === 0 ? "none":""
            }}>
                <div className="box" style={{
                    display:unregisteredArray.length === 0 ? "none":""
                }}>
                    <span>{t("unregistered")}</span>
                    <div className="list-box" style={{
                        gridTemplateColumns:mobileStatus?"": unregisteredTotal >=3? "repeat(3,1fr)":""
                    }}>
                        {unregisteredArray.map((item,index)=>(
                            <div className="content-item-center" key={index}>
                                <div>
                                    <span className="content-text">{item}</span>
                                    <span className="highlight-yellow">.Pi</span>
                                </div>
                                <span  className="hr"/>
                                <img src={mistakeSvg} alt=""/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="box"  style={{
                    display:registeredArray.length === 0 ? "none":""
                }}>
                    <span>{t("registered")}</span>
                    <div className="list-box">
                        {registeredArray.map((item,index)=>(
                            <div className="content-item-center" key={index}>
                                <div>
                                    <span className="content-text">{item}</span>
                                    <span className="highlight-yellow">.Pi</span>
                                </div>
                                <span  className="hr"/>
                                <img src={correctSvg} alt=""/>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </main>
    </>
  );
};

// 导出组件
export default Home;
