const ko = {
  "navbar_brand": "PID 사용 가능",
  "navbar_brand_text":"Discover and collect cryptoart",
  "translate_button": "번역",
  "language_english": "English",
  "language_chinese": "中文",
  "language_korean": "한국어",
  "button_label_a": "3글자",
  "button_label_b": "4글자",
  "button_label_c": "5글자",
  "button_label_d": "브랜드 글자",
  "floating_button": "일괄 조회",
  "error_fetching_data": "데이터 가져오기 오류:",
  "batch_query":"批量查询",
  "batch_query_text":"Developed and operated by the SpacePi Foundation.",
  "enter_domain_name":"Enter domain name",
  "one_domain_name_per_line":'One domain name per line',
  "reset":"Reset",
  "get":"Get",
  "registered":"Registered",
  "unregistered":"Unregistered"
};

export default ko;
