const en = {
  "navbar_brand": "PID Available",
  "navbar_brand_text":"Discover and collect cryptoart",
  "translate_button": "Lang",
  "language_english": "English",
  "language_chinese": "中文",
  "language_korean": "한국어",
  "button_label_a": "3 Letters",
  "button_label_b": "4 Letters",
  "button_label_c": "5 Letters",
  "button_label_d": "Brand Letters",
  "floating_button": "Batch Query",
  "error_fetching_data": "Error fetching data:",
  "batch_query":"Batch Query",
  "batch_query_text":"Developed and operated by the SpacePi Foundation.",
  "enter_domain_name":"Enter domain name",
  "one_domain_name_per_line":'One domain name per line',
  "reset":"Reset",
  "get":"Get",
  "registered":"Registered",
  "unregistered":"Unregistered"
};

export default en;
