module.exports = [
  "aaaaa",
  "nnnnn",
  "qqqqq",
  "ccccc",
  "vvvvv",
  "iiiii",
  "fffff",
  "xxxxx",
  "ggggg",
  "ttttt",
  "lllll",
  "jjjjj",
  "ppppp",
  "rrrrr",
  "bbbbb",
  "uuuuu",
  "ooooo",
  "kkkkk",
  "eeeee",
  "wwwww",
  "mmmmm",
  "sssss",
  "hhhhh",
  "yyyyy",
  "ddddd",
  "zzzzz",
  "lpppl",
  "ffcff",
  "bfbbf",
  "bbwww",
  "asaaa",
  "crcrc",
  "dddrr",
  "ggygg",
  "azaaz",
  "fnnnn",
  "hehhh",
  "bffbb",
  "giggi",
  "fzzfz",
  "jnjjj",
  "dmddm",
  "hhahh",
  "bfbff",
  "affaf",
  "ccccf",
  "jmjmm",
  "fafaf",
  "kkckc",
  "eevev",
  "bblll",
  "inini",
  "gaagg",
  "ggxxx",
  "mmqqm",
  "ababb",
  "hfhhh",
  "mfmfm",
  "lllhl",
  "lhhhl",
  "llllo",
  "mqqmm",
  "krkkr",
  "ffjff",
  "ddddx",
  "iiihi",
  "ghggh",
  "llvlv",
  "nanna",
  "didid",
  "jeejj",
  "ghhhh",
  "cccbb",
  "aawww",
  "immii",
  "jhjjj",
  "ggwgw",
  "llllg",
  "iiiqi",
  "gtttg",
  "hhphh",
  "gffgf",
  "ffsff",
  "jhhhj",
  "cscsc",
  "apppp",
  "ccvvc",
  "ellee",
  "bnnnn",
  "kkmmm",
  "dddaa",
  "kmkkm",
  "eteet",
  "jjyyj",
  "fffyy",
  "kjjjk",
  "djjdd",
  "ddfdd",
  "hhhrh",
  "iiiip",
  "dlldd",
  "mjmmj",
  "mmcmm",
  "eewwe",
  "evvee",
  "eveee",
  "eheeh",
  "ffiii",
  "kkyky",
  "lltll",
  "iccci",
  "ggxxg",
  "kkkkd",
  "bdbbd",
  "gzzzg",
  "jrjrj",
  "ddldl",
  "jjjkk",
  "bxbxx",
  "aahha",
  "llele",
  "fufuf",
  "gggsg",
  "jjpjj",
  "kcckk",
  "cqccq",
  "iiiji",
  "kkwkw",
  "guggg",
  "kgkgg",
  "mxmxx",
  "mumuu",
  "hhzhh",
  "eueeu",
  "iaiai",
  "aqqaq",
  "glglg",
  "aaava",
  "eefef",
  "bobob",
  "iwiww",
  "kkvkk",
  "kkhhk",
  "lclcl",
  "jjppj",
  "bfffb",
  "knnkn",
  "allll",
  "hhrhr",
  "lltlt",
  "lxlll",
  "eeenn",
  "ggigi",
  "kkkgg",
  "fnffn",
  "iiiiz",
  "eeheh",
  "ltttl",
  "gzggz",
  "dllld",
  "eeevv",
  "jgjjg",
  "cvccc",
  "llala",
  "bjbjj",
  "rrrll",
  "srrrs",
  "kekkk",
  "hhtht",
  "qmqmq",
  "tctct",
  "ststt",
  "aaavv",
  "eyyey",
  "cddcd",
  "qcccc",
  "ssttt",
  "pplpp",
  "ffofo",
  "sstss",
  "seese",
  "qffqf",
  "nnono",
  "rsrsr",
  "ppopp",
  "aazzz",
  "jyyyj",
  "sccss",
  "rrvvv",
  "nnxnn",
  "pmppm",
  "qdqqq",
  "ckcck",
  "agaga",
  "ajajj",
  "ssgss",
  "jddjd",
  "rrlrr",
  "auauu",
  "ppjpp",
  "llzzl",
  "nvvvv",
  "bbaba",
  "ngngg",
  "pmppp",
  "ojjjo",
  "szssz",
  "aabbb",
  "mbmbb",
  "rqqrr",
  "ggggq",
  "qeqeq",
  "iiidd",
  "itiit",
  "smsmm",
  "bubuu",
  "faafa",
  "kkkkc",
  "qeeeq",
  "ldlld",
  "qeqee",
  "iiipi",
  "ggwgg",
  "pqppp",
  "sppss",
  "ababa",
  "mymmy",
  "ddhhh",
  "oooqq",
  "kkknk",
  "sscsc",
  "ggyyg",
  "cncnn",
  "ttppp",
  "oiiii",
  "syyys",
  "hohhh",
  "otttt",
  "ohoho",
  "rvrvr",
  "bbssb",
  "ooojo",
  "sasss",
  "acccc",
  "rerrr",
  "ccwww",
  "tttuu",
  "smsms",
  "nxnnn",
  "rarrr",
  "kyyky",
  "rryrr",
  "ssxsx",
  "rrttt",
  "lxxll",
  "ntntn",
  "fvvvv",
  "pjjjp",
  "mmnmn",
  "ioioo",
  "jsjss",
  "qhhqh",
  "ttttx",
  "qqqkk",
  "agaag",
  "ssjss",
  "pjjjj",
  "ppllp",
  "aaahh",
  "hkhkh",
  "ppggg",
  "bbbjj",
  "hthhh",
  "mggmm",
  "eeekk",
  "ssxxs",
  "ppipp",
  "nuunn",
  "llnnl",
  "ntntt",
  "nvnvn",
  "sdsds",
  "iiibi",
  "ruruu",
  "ppuup",
  "diiid",
  "aaaii",
  "mmqmq",
  "jjqqq",
  "bmmbm",
  "iisii",
  "ttfft",
  "lglgg",
  "tttot",
  "bybyy",
  "ljjll",
  "dddyd",
  "tkttk",
  "smmmm",
  "riiii",
  "ttttv",
  "skskk",
  "hhnhn",
  "mvvvm",
  "fyffy",
  "teeet",
  "bbsss",
  "issii",
  "ftftf",
  "pkppp",
  "tteee",
  "kokko",
  "dffdf",
  "rrfrf",
  "kjjkk",
  "rgggr",
  "rarra",
  "tttpp",
  "mfmmm",
  "orror",
  "ccpcp",
  "ptptt",
  "ppppn",
  "mzzmm",
  "pphph",
  "trtrr",
  "ssisi",
  "ssaaa",
  "eaeee",
  "iiiwi",
  "hhqqq",
  "ffppp",
  "qjjjj",
  "mjmjj",
  "ggvvg",
  "tttrt",
  "qqiqi",
  "pppkp",
  "bbbbd",
  "kkkks",
  "ppppz",
  "pqqpq",
  "rrrwr",
  "ceece",
  "jujuu",
  "skssk",
  "jyjyy",
  "coocc",
  "pxxxx",
  "ssvss",
  "bblbl",
  "sgsss",
  "cacaa",
  "llulu",
  "ffvvv",
  "pppkk",
  "eeere",
  "feeff",
  "hiiih",
  "dvddv",
  "syssy",
  "qqbqq",
  "qfqfq",
  "hhyhy",
  "dqdqq",
  "cdddc",
  "oeeoe",
  "eesse",
  "jcjcc",
  "kbbbk",
  "ciiic",
  "fofff",
  "rzrrr",
  "lbbbl",
  "rrrkr",
  "aaoao",
  "ppggp",
  "nnxxx",
  "fcccf",
  "tkkkt",
  "jjaja",
  "ataat",
  "bbdbb",
  "ppfpf",
  "onooo",
  "qiiii",
  "qqnnq",
  "hhccc",
  "qqnnn",
  "pqqqp",
  "nfnfn",
  "prrrp",
  "nniin",
  "iriri",
  "rfrrf",
  "qaqaa",
  "qqqff",
  "sbbbb",
  "ididd",
  "nonon",
  "ppupu",
  "eddde",
  "nnnsn",
  "nnngg",
  "ouuuu",
  "gggkg",
  "pspss",
  "krrrk",
  "ttmmm",
  "sjsjj",
  "ooono",
  "ffxxf",
  "nrrnr",
  "jjjvv",
  "pwwww",
  "rssss",
  "puupu",
  "tptpp",
  "hhihi",
  "bxxbx",
  "mwmww",
  "rjrrr",
  "qpqqq",
  "iiiio",
  "anana",
  "jgjjj",
  "ssffs",
  "nxnxx",
  "pnpnp",
  "oofoo",
  "sbbsb",
  "fsfff",
  "amaam",
  "xaaaa",
  "uiuuu",
  "fpfpf",
  "kikik",
  "iiidi",
  "qyyyq",
  "wwwvv",
  "vvnnv",
  "rfrrr",
  "vvrrr",
  "fppff",
  "uzuzz",
  "eehee",
  "wwddw",
  "nrnrn",
  "vyvyy",
  "gggyy",
  "gcccc",
  "vrrvr",
  "ooobo",
  "wbbww",
  "uuttt",
  "fbbfb",
  "sfsss",
  "xbbxx",
  "wwjww",
  "bgbbg",
  "vvlvv",
  "ddnnn",
  "ssjjs",
  "jjjjq",
  "xaxaa",
  "sssfs",
  "eemme",
  "eeeet",
  "erree",
  "vvooo",
  "aaacc",
  "wwwwn",
  "aanna",
  "mhhmh",
  "iiiiq",
  "rzzrz",
  "rrrjj",
  "dpppp",
  "raaaa",
  "eemee",
  "rrraa",
  "rprpr",
  "grrgr",
  "eenne",
  "ehheh",
  "dkkkd",
  "ccddd",
  "wwwxw",
  "qrrrq",
  "vvvcc",
  "bqbbb",
  "kkkvk",
  "fffpf",
  "vddvv",
  "ueeeu",
  "fafaa",
  "rrttr",
  "quuqu",
  "fzzzz",
  "ggggo",
  "tqqtq",
  "cllcc",
  "asaas",
  "iidii",
  "vevee",
  "ggggk",
  "bcbcc",
  "mummu",
  "ccbcb",
  "wwvwv",
  "eekek",
  "vggvv",
  "ggffg",
  "lbllb",
  "sqsqq",
  "bddbd",
  "bqqqb",
  "plppl",
  "dnddn",
  "ijjji",
  "lldld",
  "wwwwq",
  "oobbb",
  "npnpp",
  "ibbbi",
  "ooocc",
  "jjjjo",
  "wwwmw",
  "pppps",
  "lwllw",
  "lllyl",
  "jjjjf",
  "lssls",
  "nxxnx",
  "ogggo",
  "tttht",
  "fzffz",
  "trrtt",
  "lllla",
  "sesss",
  "vivvv",
  "uruur",
  "ukuuk",
  "fiiif",
  "jttjt",
  "wwzzw",
  "iddii",
  "uuuru",
  "qyqqq",
  "qqqaa",
  "epppe",
  "sosss",
  "hqqhq",
  "vzvvz",
  "bpppb",
  "orooo",
  "kppkp",
  "tffft",
  "wxxxw",
  "ssshh",
  "upupu",
  "hshsh",
  "qbbqb",
  "oojoo",
  "kknnk",
  "fssff",
  "kuuuk",
  "tzztt",
  "vxvxx",
  "jbjbb",
  "uuuqq",
  "mmrmr",
  "iixxi",
  "vwwww",
  "vnvvv",
  "mmomm",
  "tutuu",
  "wwuuu",
  "jmmjm",
  "ususs",
  "mmeee",
  "vrvrv",
  "nnana",
  "wwvvw",
  "uluuu",
  "hhpph",
  "agggg",
  "rpprr",
  "tzzzt",
  "wllww",
  "bzzbz",
  "pbbbp",
  "twttt",
  "shhhh",
  "vvzvv",
  "mmbmm",
  "ubbbb",
  "hxxxh",
  "pppee",
  "riiir",
  "ddgdg",
  "nnnmm",
  "rzzzz",
  "edeed",
  "whwwh",
  "uuutt",
  "ddppd",
  "cnncn",
  "poooo",
  "kkeek",
  "ihihi",
  "wwxxx",
  "iiijj",
  "bbbbe",
  "piiii",
  "wwwwo",
  "rwwwr",
  "gqgqg",
  "sisis",
  "nonno",
  "vsvss",
  "vmmvm",
  "vcccv",
  "ttiti",
  "ioooi",
  "rxxxx",
  "tbbbb",
  "czccc",
  "sssgs",
  "ededd",
  "ssbbb",
  "wddwd",
  "llljl",
  "iijij",
  "nnoon",
  "nnnnc",
  "nrrrr",
  "tbtbt",
  "jijii",
  "bbaab",
  "hgggh",
  "uwwwu",
  "amamm",
  "bbboo",
  "ooooz",
  "ryyry",
  "opoop",
  "irrir",
  "cvvvv",
  "wewew",
  "lggll",
  "vrrvv",
  "onnon",
  "cczcz",
  "ggaaa",
  "gwwgg",
  "hhvhh",
  "vsvsv",
  "ffssf",
  "rhrhr",
  "wwlww",
  "avava",
  "wswsw",
  "kykky",
  "qqccq",
  "wccww",
  "nngnn",
  "pppjp",
  "hccch",
  "uuuyu",
  "ddhdh",
  "gguug",
  "nbnnb",
  "fxxfx",
  "dnddd",
  "eennn",
  "illii",
  "jjhjj",
  "ttmtt",
  "wnwnw",
  "tstts",
  "uuuzu",
  "iipii",
  "gvvvg",
  "vmvmv",
  "ooiii",
  "pppcp",
  "vvddd",
  "afffa",
  "vxxvx",
  "bppbb",
  "wywwy",
  "hzzhz",
  "hmmhh",
  "ryyrr",
  "ceeee",
  "uuuuj",
  "jjbbb",
  "ffqfq",
  "djdjj",
  "wwwwg",
  "innin",
  "wkwkw",
  "uuuum",
  "jljlj",
  "ccrcc",
  "tttit",
  "aaaaq",
  "uuupp",
  "pyyyp",
  "jqqqq",
  "oofff",
  "uxuxu",
  "ssqsq",
  "vvvuu",
  "vvdvv",
  "wwowo",
  "bbdbd",
  "bbbvv",
  "apppa",
  "uiuiu",
  "rkkrk",
  "rbrrr",
  "ttttq",
  "fkfkf",
  "aacca",
  "lxxxl",
  "uwwww",
  "iiyyi",
  "vevvv",
  "ggggy",
  "aaaxa",
  "ommoo",
  "qqqqo",
  "cvvcc",
  "lgglg",
  "mjmmm",
  "gpppp",
  "pppep",
  "ugggg",
  "wwffw",
  "kkikk",
  "wrwwr",
  "wzwzz",
  "mdmmd",
  "pipip",
  "qqqxq",
  "gggss",
  "uueeu",
  "bnbnn",
  "eeooe",
  "iitti",
  "nnnmn",
  "wwwwx",
  "doddo",
  "wzzzz",
  "peepp",
  "vrvrr",
  "rruru",
  "ccvcv",
  "nyyyn",
  "iihii",
  "gggpg",
  "usssu",
  "mmmwm",
  "cuccu",
  "eette",
  "vttvt",
  "mjjmj",
  "bbbyy",
  "mfmff",
  "qzqqq",
  "jjjkj",
  "mmmem",
  "rgrgr",
  "eieii",
  "mmmxm",
  "vyyvv",
  "vvsss",
  "cccch",
  "mttmm",
  "hjjjh",
  "tttte",
  "ttddt",
  "rirri",
  "hbbhb",
  "tcctc",
  "hwhww",
  "ffjfj",
  "ooppo",
  "ppmpp",
  "gwgwg",
  "bbbpp",
  "nenee",
  "lelel",
  "eeege",
  "taatt",
  "fffqf",
  "vccvc",
  "vjvvj",
  "hfhff",
  "tddtd",
  "qqqqs",
  "qeqqe",
  "grrrg",
  "wfwfw",
  "ffeff",
  "qqxqq",
  "wwwyw",
  "phphp",
  "mwwmm",
  "ssgsg",
  "ssqqs",
  "vvtvt",
  "naaan",
  "mmttt",
  "snnsn",
  "clllc",
  "iiiib",
  "uuuxu",
  "wpwwp",
  "eqeqq",
  "ooooa",
  "eevvv",
  "ttttk",
  "puuup",
  "msssm",
  "bbbnn",
  "swsww",
  "qqqrq",
  "mdddm",
  "dqddd",
  "ejjee",
  "iiioi",
  "wddww",
  "jjjhj",
  "vvvrr",
  "ppepe",
  "ququq",
  "gzzgz",
  "iioio",
  "mmmrr",
  "ggttt",
  "lllln",
  "vllvv",
  "wwwwv",
  "vpppp",
  "doddd",
  "efeff",
  "aaama",
  "wlwlw",
  "ggqqg",
  "eccee",
  "iirii",
  "ppjpj",
  "trrrr",
  "qsqsq",
  "vvvzv",
  "srsrs",
  "gggwg",
  "uuuii",
  "owooo",
  "bbaaa",
  "ggzgg",
  "fjjff",
  "jjcjc",
  "ggsss",
  "qqqww",
  "oorrr",
  "pmpmm",
  "vvvvk",
  "hhuuu",
  "qyqyq",
  "iiooi",
  "nnhnh",
  "uiiui",
  "llssl",
  "rrppp",
  "ttttn",
  "mnmnm",
  "vvggg",
  "mmddd",
  "ccycc",
  "wfwwf",
  "xaxxa",
  "kkmkk",
  "ucucc",
  "llqlq",
  "vzzzz",
  "ahaah",
  "lrrlr",
  "ddddf",
  "uubuu",
  "waaaa",
  "wwwwa",
  "ezzee",
  "oooww",
  "vyvvv",
  "bibbb",
  "huhhu",
  "pepep",
  "tttoo",
  "ccclc",
  "llnln",
  "uhhuh",
  "ahhha",
  "wwaww",
  "eerre",
  "uuxuu",
  "ouooo",
  "uuufu",
  "lljjl",
  "ddwwd",
  "ccccb",
  "tttth",
  "mvvvv",
  "eeeeb",
  "bbtbb",
  "wgwgg",
  "ccacc",
  "dddqq",
  "fffhh",
  "offff",
  "rhrrh",
  "jqqjj",
  "iiiia",
  "jsssj",
  "ppppa",
  "oogog",
  "ddfdf",
  "vovoo",
  "noonn",
  "nncnn",
  "rcrrc",
  "oohoo",
  "sosso",
  "nngng",
  "ezezz",
  "okook",
  "pwwpp",
  "veeee",
  "vvxxx",
  "bwwbb",
  "pfppf",
  "fjfjf",
  "wwwmm",
  "vhvvv",
  "ggggp",
  "esese",
  "pppap",
  "gugug",
  "ffddf",
  "tcctt",
  "wwwwc",
  "okkkk",
  "fffof",
  "oobbo",
  "bpbbb",
  "ookoo",
  "uummm",
  "njnjj",
  "gmgmm",
  "qyyqq",
  "ttztt",
  "edded",
  "kkaka",
  "wzwzw",
  "mgggg",
  "vnnnv",
  "llllp",
  "huhhh",
  "vvuvu",
  "bybby",
  "swwww",
  "sgsgs",
  "jaajj",
  "nneee",
  "kkdkd",
  "ffllf",
  "ppffp",
  "cccic",
  "gggvg",
  "uuuuf",
  "iiiim",
  "papaa",
  "rirrr",
  "rwrww",
  "aaeae",
  "niiii",
  "qjjqj",
  "iiwwi",
  "eoooe",
  "jjkkk",
  "qwqwq",
  "wwpww",
  "mwwww",
  "mhhmm",
  "ufffu",
  "oeoee",
  "jjkkj",
  "npppp",
  "ppxpx",
  "sssjj",
  "llooo",
  "bbxbb",
  "qqqqw",
  "ivvii",
  "mdddd",
  "hfhfh",
  "vvvii",
  "lbbbb",
  "pnnpn",
  "pjppj",
  "mnmmn",
  "hkhkk",
  "dssdd",
  "qllll",
  "vmvvm",
  "kkkfk",
  "guggu",
  "ppypp",
  "yjjjj",
  "akkka",
  "xkkkk",
  "ikiik",
  "jzzjz",
  "vnnvn",
  "rrhhh",
  "mmkmm",
  "ckckk",
  "wwwss",
  "illll",
  "tettt",
  "moomm",
  "jzjjj",
  "nnnnb",
  "oottt",
  "hiiii",
  "gssgs",
  "qqllq",
  "xxggx",
  "fqqqq",
  "qssqs",
  "xxddx",
  "lllul",
  "pddpp",
  "ddbbb",
  "vcccc",
  "viivv",
  "eejee",
  "tfttt",
  "mmmvm",
  "abaaa",
  "qqwwq",
  "orroo",
  "pqppq",
  "mrmmr",
  "knnkk",
  "qaqaq",
  "ssggs",
  "cwwwc",
  "gggii",
  "oosss",
  "eexxx",
  "tuttt",
  "mbmbm",
  "uauau",
  "eeepe",
  "wwwwd",
  "xxfxf",
  "kkyyy",
  "susus",
  "igigg",
  "pwpwp",
  "mmvvm",
  "gfgfg",
  "qiqiq",
  "iiiif",
  "fnnfn",
  "ffqff",
  "ipppi",
  "trrrt",
  "iilll",
  "eewee",
  "lpllp",
  "ffdfd",
  "pdddd",
  "dbdbd",
  "wiwii",
  "xkxxk",
  "lllwl",
  "ndnnn",
  "yllyl",
  "kksss",
  "ffiff",
  "wlwww",
  "faaff",
  "jjjrj",
  "dyddd",
  "hhhhx",
  "vpvpv",
  "ooppp",
  "ggppg",
  "kzkkz",
  "wpppw",
  "wppww",
  "ccmmm",
  "ymmym",
  "bbbbj",
  "jxxjx",
  "xhxhx",
  "tntnn",
  "iilil",
  "kiikk",
  "iivvi",
  "kkoko",
  "sstst",
  "jjddj",
  "ycccy",
  "mffmf",
  "llllh",
  "ettee",
  "ooioo",
  "bgbgb",
  "pphhh",
  "ujjuu",
  "daadd",
  "rjjjj",
  "xzzzx",
  "googg",
  "cccsc",
  "ysssy",
  "aalal",
  "yllly",
  "qiiiq",
  "hchcc",
  "hxxhx",
  "uummu",
  "ynyyy",
  "mmkkk",
  "uvuuu",
  "eeede",
  "rnrrn",
  "wxxxx",
  "abaab",
  "ozooz",
  "sbbbs",
  "immim",
  "eecce",
  "kbbbb",
  "ypyyp",
  "rgrrr",
  "lvlvv",
  "rrvvr",
  "ccccw",
  "fllff",
  "ttyyy",
  "pupuu",
  "sggss",
  "yhyyy",
  "opooo",
  "iieii",
  "lllml",
  "dxdxx",
  "uuxux",
  "seess",
  "xmxmx",
  "vmmvv",
  "wwawa",
  "aaeea",
  "nkkkk",
  "kkkuu",
  "ufufu",
  "xxpxp",
  "kkkky",
  "iiddi",
  "ccdcd",
  "gqggq",
  "rrccc",
  "eeepp",
  "kkbkb",
  "hphpp",
  "nneen",
  "kkqqq",
  "xxxgg",
  "ggbbb",
  "kkfkf",
  "rdrdd",
  "hhohh",
  "ssshs",
  "ekkkk",
  "tvtvv",
  "nhnnn",
  "yiiyi",
  "xxxix",
  "mmdmm",
  "lmmml",
  "xxvxx",
  "nnnfn",
  "lilii",
  "ddxdx",
  "rrlll",
  "iiioo",
  "nnrnr",
  "xttxx",
  "tggtt",
  "xxqxq",
  "lvvvv",
  "bnbbb",
  "qmmmq",
  "tccct",
  "hvvvv",
  "hjhhj",
  "tzttz",
  "ggwwg",
  "iiihh",
  "grggr",
  "rrooo",
  "jdjjj",
  "xhxxx",
  "vbbvb",
  "iiyyy",
  "ggddg",
  "fxfxx",
  "oooxx",
  "eevee",
  "djjdj",
  "eeeei",
  "ldddl",
  "yjyyy",
  "jjjzj",
  "vcvcv",
  "oooos",
  "nvvvn",
  "wvwvw",
  "viviv",
  "pwpww",
  "ooomo",
  "mmmgg",
  "yddyy",
  "gffgg",
  "ggzgz",
  "kkokk",
  "gygyg",
  "bccbc",
  "ffffm",
  "jhjhh",
  "yllyy",
  "ccbbb",
  "drrdd",
  "xxxhh",
  "waaaw",
  "sssww",
  "kkppk",
  "brrbb",
  "ltttt",
  "gqgqq",
  "gqqqg",
  "qcqcc",
  "hhddd",
  "khkhh",
  "isssi",
  "rmrmm",
  "hhhaa",
  "lllrr",
  "rrrfr",
  "mmaam",
  "xxxax",
  "aaakk",
  "ueuue",
  "ohhoo",
  "lplpp",
  "ffufu",
  "vvviv",
  "gghgg",
  "jmjjm",
  "rzrzr",
  "ooqqq",
  "eeyey",
  "imiim",
  "dtddt",
  "lxlxl",
  "fffnf",
  "aataa",
  "xxxnx",
  "aaiaa",
  "knknk",
  "vvjjj",
  "ycccc",
  "cccww",
  "cddcc",
  "xcxxx",
  "nnnln",
  "kkzkz",
  "rbrbb",
  "ennen",
  "vqvqv",
  "olllo",
  "eggeg",
  "ccnnc",
  "hmhhm",
  "sqssq",
  "unuuu",
  "ooodd",
  "gyyyg",
  "eggee",
  "bbbvb",
  "ddddo",
  "bzbzz",
  "xxlxl",
  "feefe",
  "mgmmg",
  "ycycy",
  "wffwf",
  "ccccv",
  "ogggg",
  "ddcdc",
  "ruuuu",
  "eeexe",
  "llllk",
  "gkkgg",
  "jjwjj",
  "njnnn",
  "mzmzz",
  "eeefe",
  "knkkn",
  "titii",
  "pfffp",
  "jjjww",
  "jjttt",
  "ggggj",
  "wawww",
  "dduud",
  "bdddb",
  "jzjjz",
  "uuuui",
  "dddcd",
  "slsss",
  "sssos",
  "hllll",
  "xkxkx",
  "vwwvv",
  "xpxxp",
  "nnnnd",
  "fffgg",
  "ammma",
  "nvnnv",
  "iliil",
  "lwwll",
  "jjvjv",
  "kkkek",
  "jjbjj",
  "ttutu",
  "jkkjk",
  "lllnl",
  "sdsdd",
  "jjuuu",
  "nnnzz",
  "xzxxx",
  "kkmkm",
  "yhhhh",
  "rmrmr",
  "opppo",
  "xxrrr",
  "nnpnp",
  "eelel",
  "kwkkk",
  "nxnxn",
  "uuuau",
  "wgwgw",
  "hhhmh",
  "rroor",
  "kkqkq",
  "vvwvw",
  "pprrr",
  "acacc",
  "ddedd",
  "slssl",
  "skkks",
  "uhuhu",
  "hhhjh",
  "crcrr",
  "ffffd",
  "wwvww",
  "ejejj",
  "aannn",
  "ddddp",
  "jjaaj",
  "bkbkk",
  "aatta",
  "kuuku",
  "uruuu",
  "uuuut",
  "nbbbn",
  "nnunn",
  "iuiuu",
  "rjrjj",
  "bbggg",
  "aavaa",
  "thhht",
  "ooooq",
  "aaxxa",
  "glllg",
  "ybyyb",
  "qqrqq",
  "tttww",
  "vzvvv",
  "yddyd",
  "wuuuw",
  "ffffb",
  "nznnz",
  "haaah",
  "ttftt",
  "appap",
  "hmhmm",
  "hfffh",
  "ivivi",
  "quuqq",
  "urrru",
  "kkqqk",
  "xxmxm",
  "frrrr",
  "mdmdd",
  "ppgpp",
  "wweww",
  "pxxxp",
  "hhrrr",
  "eaaea",
  "rcrcc",
  "ejeee",
  "ppppy",
  "pppsp",
  "pbpbp",
  "eekkk",
  "izzii",
  "gvvvv",
  "tptpt",
  "uuuyy",
  "wzwww",
  "exxxe",
  "cfccf",
  "ttitt",
  "jnnnn",
  "mmmjm",
  "rvrrr",
  "puppp",
  "bvvvv",
  "ggrgr",
  "nndnn",
  "bbbbl",
  "fffbb",
  "rrrcr",
  "xwxwx",
  "qqqqr",
  "thhhh",
  "fffft",
  "fffhf",
  "ofooo",
  "rtrtt",
  "phphh",
  "ptttt",
  "vvuuu",
  "kukuk",
  "ifffi",
  "cyyyc",
  "ppyyy",
  "jjjjs",
  "gggug",
  "vvvbb",
  "vvffv",
  "tttti",
  "iijjj",
  "bbllb",
  "ddudd",
  "xwwxx",
  "ucucu",
  "wppwp",
  "wcwcw",
  "cccgc",
  "etttt",
  "ggooo",
  "ammaa",
  "eeeyy",
  "qpqqp",
  "rrwrw",
  "nnbnn",
  "bboob",
  "ottoo",
  "wqwwq",
  "ucuuu",
  "qqeqq",
  "vzzzv",
  "tatat",
  "ssyyy",
  "kqqkq",
  "alaaa",
  "mmppm",
  "uwuww",
  "jjbbj",
  "xhxxh",
  "rcrcr",
  "hehhe",
  "yqyyq",
  "kxkxk",
  "ddssd",
  "jjddd",
  "dddvd",
  "dsdds",
  "nnntt",
  "taata",
  "didii",
  "kkkyk",
  "kkffk",
  "ffddd",
  "uddud",
  "ooggg",
  "ggogo",
  "xffxx",
  "yeeey",
  "npnnn",
  "nnddd",
  "gvgvv",
  "ttvvv",
  "hhttt",
  "aappa",
  "ddadd",
  "epepe",
  "jjjgj",
  "pjpjp",
  "sskkk",
  "ehehe",
  "ddnnd",
  "rrzrz",
  "bbubu",
  "jjssj",
  "hvhvh",
  "frffr",
  "ppdpp",
  "mmcmc",
  "vjvvv",
  "xdddd",
  "mllll",
  "utuut",
  "ixxxx",
  "bbbbf",
  "iccii",
  "bhhbh",
  "mmzmz",
  "ooxoo",
  "ppzpz",
  "ypypp",
  "qqvqq",
  "yhhhy",
  "fooof",
  "xbbxb",
  "yqyyy",
  "abbaa",
  "xxxwx",
  "xxnnx",
  "aauau",
  "lllbb",
  "rrdrd",
  "uuquq",
  "nnrnn",
  "llpll",
  "hhwwh",
  "onono",
  "wwwwy",
  "fkkkf",
  "vvkvk",
  "ssiis",
  "yjjjy",
  "pptpt",
  "nnnss",
  "utttu",
  "mmfmf",
  "qqsss",
  "ciiii",
  "prrpp",
  "fcfcf",
  "aavva",
  "djjjj",
  "czzcc",
  "ykyky",
  "llxlx",
  "iiyiy",
  "llklk",
  "yhhyy",
  "xxxxl",
  "heehe",
  "gwwgw",
  "pyppp",
  "ccucu",
  "ddlld",
  "ppxxx",
  "mmjjj",
  "accca",
  "eeeww",
  "jjjnj",
  "loolo",
  "ehehh",
  "nnccc",
  "rrgrg",
  "ucccu",
  "ococo",
  "frfrf",
  "svvvv",
  "pbbbb",
  "iiqii",
  "xxuxu",
  "rtttt",
  "dhhhd",
  "fyyff",
  "eefff",
  "oohoh",
  "ttggt",
  "phhpp",
  "tpppt",
  "qmmqq",
  "cccpp",
  "nqqqn",
  "uuquu",
  "ffftf",
  "fjfff",
  "qqoqo",
  "giigg",
  "mnmmm",
  "uuuur",
  "lllzz",
  "fqqff",
  "wcccc",
  "qzzzz",
  "ttptp",
  "ddeee",
  "qssss",
  "jjnjj",
  "iuuuu",
  "iiggi",
  "meeee",
  "tttii",
  "cecec",
  "wbwbw",
  "ososo",
  "jjrrj",
  "oovvv",
  "xggxx",
  "mjjjm",
  "cqcqq",
  "jjmjm",
  "xxlxx",
  "vkkkk",
  "cyycy",
  "xuxxu",
  "obobo",
  "tmttm",
  "ppupp",
  "vvvrv",
  "ewwew",
  "vgvvv",
  "xoxxo",
  "umumm",
  "mqqqm",
  "lnlll",
  "qxqxq",
  "vvzvz",
  "ddxdd",
  "wewww",
  "vvbvv",
  "dlldl",
  "lwwwl",
  "iipip",
  "ggmgm",
  "vkkvv",
  "jlljj",
  "cccrr",
  "bbjbj",
  "lssll",
  "bbbbn",
  "jqjjq",
  "iaaia",
  "nnhhn",
  "wwmwm",
  "tltlt",
  "haaaa",
  "sxssx",
  "ssrsr",
  "qwwwq",
  "rrirr",
  "xmmxm",
  "sjsjs",
  "jjeej",
  "vyyyv",
  "mdmmm",
  "ettet",
  "fffzf",
  "kkeee",
  "tftff",
  "bcbcb",
  "pcccc",
  "pdddp",
  "jijji",
  "vaava",
  "eoeee",
  "jpjjj",
  "xxttx",
  "xxaax",
  "xffxf",
  "fpfpp",
  "llggg",
  "qquuq",
  "njjjj",
  "xxxmm",
  "rgrrg",
  "sswsw",
  "sssvs",
  "pppwp",
  "aappp",
  "ppzzp",
  "vvppp",
  "ynyyn",
  "kiiik",
  "yllll",
  "wjjwj",
  "lxxlx",
  "xxqxx",
  "ussuu",
  "xzzzz",
  "aggag",
  "qoqoo",
  "xxxfx",
  "qqqyy",
  "ddidi",
  "nyyny",
  "qttqt",
  "aiiia",
  "hyhhh",
  "ssrss",
  "uzzzu",
  "kkbkk",
  "pzppp",
  "wmmmm",
  "yayyy",
  "ggigg",
  "sbssb",
  "nndnd",
  "llmlm",
  "vcvcc",
  "xxmmx",
  "ggssg",
  "xxkxx",
  "rkkkk",
  "njnjn",
  "qxxqq",
  "lttlt",
  "cwwcc",
  "qkqkq",
  "qqfqf",
  "pggpp",
  "vhhvv",
  "ddood",
  "eceec",
  "hzhzh",
  "chchc",
  "memme",
  "uauaa",
  "ddsss",
  "xjxxx",
  "xtxxx",
  "ellle",
  "mzmmz",
  "rrror",
  "rccrr",
  "rrrro",
  "bjjjb",
  "stsss",
  "pkpkp",
  "mzzmz",
  "iwwiw",
  "rrrdd",
  "cocco",
  "aaooo",
  "mqmqq",
  "aoaaa",
  "ererr",
  "fcfff",
  "llwlw",
  "nuuuu",
  "qooqq",
  "utttt",
  "rrrrz",
  "dddjd",
  "oxxxx",
  "uouoo",
  "aaaff",
  "uubbu",
  "hhrrh",
  "jjojj",
  "gggbg",
  "ollll",
  "ksksk",
  "iiiki",
  "eiiei",
  "bgbbb",
  "ooxxx",
  "pprpp",
  "ieiie",
  "unnuu",
  "ccuuc",
  "swsws",
  "ffffe",
  "mmmnn",
  "upppp",
  "puupp",
  "ybyby",
  "vovov",
  "axaxx",
  "ffmmf",
  "bbqbq",
  "tnnnt",
  "fyfyy",
  "mhmhm",
  "fppfp",
  "oqoqo",
  "kklll",
  "fnfnn",
  "oorro",
  "immmi",
  "krkrr",
  "ttftf",
  "boboo",
  "klllk",
  "pspps",
  "fnnnf",
  "xxxcc",
  "kkkkq",
  "eccce",
  "jhjhj",
  "fssfs",
  "azzaz",
  "llltt",
  "ylyyy",
  "mmamm",
  "llgll",
  "xexex",
  "jljll",
  "pzpzp",
  "llppp",
  "jejee",
  "uuubb",
  "jkkkk",
  "pppxp",
  "qqquq",
  "hzzzh",
  "srrrr",
  "mqmmm",
  "czzzz",
  "qzzqz",
  "wvvvv",
  "nyynn",
  "stttt",
  "ddjjd",
  "kkcck",
  "nnlln",
  "smmss",
  "ttntn",
  "ueeue",
  "ffuuf",
  "llwll",
  "bnnnb",
  "tttpt",
  "avvva",
  "pnnpp",
  "hnnnh",
  "vnvnn",
  "xxuxx",
  "iqqii",
  "ooooy",
  "uhhhu",
  "rrrrb",
  "vvvgg",
  "nnnxn",
  "iibib",
  "fhhhh",
  "ezeee",
  "llhlh",
  "qaaqa",
  "lhlhl",
  "prppr",
  "azzza",
  "xpppx",
  "ossos",
  "kmmkk",
  "ttsst",
  "woooo",
  "wwqqw",
  "clcll",
  "wwkww",
  "cvcvv",
  "ororr",
  "ymyym",
  "ngggg",
  "muuum",
  "hhhmm",
  "jjjsj",
  "oowwo",
  "muumu",
  "eeele",
  "baabb",
  "bffbf",
  "lllnn",
  "mmeem",
  "qoqoq",
  "clccl",
  "ssdss",
  "bkkkk",
  "ddddl",
  "xxxoo",
  "rrcrc",
  "kjjjj",
  "eexxe",
  "krrkk",
  "sjssj",
  "kmkkk",
  "ooook",
  "mmccm",
  "hiihh",
  "eehhe",
  "yryyr",
  "ddndn",
  "xxxxq",
  "uuuvv",
  "liili",
  "kaakk",
  "jbbjj",
  "lrrrl",
  "ttkkt",
  "ccczc",
  "jeeje",
  "aagag",
  "ggcgc",
  "ggagg",
  "klkkl",
  "aaccc",
  "ududd",
  "vvvll",
  "eexex",
  "iiicc",
  "yaaya",
  "bebee",
  "cqqqc",
  "iiiis",
  "ppppi",
  "yeyey",
  "bubbu",
  "jllll",
  "jiijj",
  "ykykk",
  "thhtt",
  "kzzzk",
  "gbgbb",
  "wmwmw",
  "yqqqq",
  "aaaax",
  "vfvfv",
  "kvkkv",
  "geegg",
  "ddbdb",
  "ffkkk",
  "tvvvt",
  "bggbb",
  "gjjgj",
  "fiffi",
  "wmwww",
  "frfff",
  "fbfff",
  "uxuuu",
  "nyyyy",
  "ereer",
  "xxxux",
  "sysys",
  "asass",
  "poopp",
  "rroro",
  "ataaa",
  "ddjjj",
  "aaaee",
  "dddad",
  "kbkkk",
  "ccttc",
  "hihhh",
  "aeaee",
  "pppbp",
  "fffvf",
  "bkkbb",
  "eqqqq",
  "xxxtt",
  "nnnen",
  "aadad",
  "rrrdr",
  "ububu",
  "ttccc",
  "xxxqx",
  "ammmm",
  "ffrff",
  "pppzp",
  "hnnhn",
  "vvvjv",
  "bzbzb",
  "qqkkq",
  "bbebb",
  "yccyc",
  "gyygy",
  "wwwwb",
  "ppphh",
  "skkkk",
  "suuss",
  "frrfr",
  "rrxrr",
  "guuuu",
  "bhbbb",
  "llflf",
  "pdppp",
  "ubuuu",
  "dgddd",
  "nnnna",
  "llzll",
  "pppbb",
  "uuwuw",
  "thhth",
  "ggbgb",
  "mmrrm",
  "kkkrr",
  "jjjjw",
  "njjnn",
  "ccmcc",
  "uuull",
  "qhhqq",
  "ggaga",
  "xyyxy",
  "iioii",
  "axxaa",
  "ggmmg",
  "xixxi",
  "ngggn",
  "qlllq",
  "fsfsf",
  "dzddz",
  "qqhqh",
  "mmama",
  "ibbbb",
  "ooaoa",
  "qyqyy",
  "gzzzz",
  "uuuoo",
  "hhjhh",
  "byyyy",
  "lllil",
  "hnnnn",
  "akaak",
  "nkknk",
  "rffff",
  "ieiei",
  "qqqaq",
  "ovovv",
  "bbzzb",
  "adada",
  "njnnj",
  "mmmmz",
  "oosoo",
  "wqqwq",
  "teett",
  "xllxl",
  "hddhd",
  "rrrxr",
  "fefff",
  "fggfg",
  "jmjmj",
  "tlttl",
  "ctctt",
  "hhhkh",
  "xxffx",
  "ttrrr",
  "cuuuu",
  "ggggu",
  "yfyfy",
  "ppzpp",
  "aggaa",
  "hxhhh",
  "xuxuu",
  "eeppp",
  "kkddd",
  "hchhh",
  "rrrur",
  "ssssb",
  "aabab",
  "qzzzq",
  "wwuuw",
  "xxdxx",
  "sssee",
  "qpqpq",
  "qzqzq",
  "tvvvv",
  "fuuuf",
  "jjzjz",
  "cuucc",
  "dfdfd",
  "ggfgf",
  "kkjjk",
  "ooloo",
  "nnhhh",
  "nmmmm",
  "piipi",
  "xxxxd",
  "leeee",
  "mommm",
  "duddd",
  "yyrrr",
  "vgvgv",
  "nynny",
  "ooyyy",
  "llllq",
  "sisss",
  "rrqrr",
  "nnjnj",
  "ytyty",
  "aeaaa",
  "rllrr",
  "kmmmm",
  "ggjgg",
  "xxxss",
  "jjjjm",
  "jjgjj",
  "ayayy",
  "rnnnn",
  "iizii",
  "hwhhh",
  "teeee",
  "vhhhv",
  "mtmtt",
  "kkjjj",
  "xnnxn",
  "whwww",
  "wwiiw",
  "tjjjj",
  "aaata",
  "iiiir",
  "hthth",
  "eaaae",
  "yryyy",
  "rerer",
  "doodo",
  "iiviv",
  "jjjcc",
  "mcmmc",
  "vvavv",
  "immmm",
  "wwvvv",
  "ooogo",
  "uusus",
  "hshss",
  "ffmff",
  "vbvvb",
  "aaaau",
  "mmhmm",
  "yyiyy",
  "gggeg",
  "voovo",
  "jjxjj",
  "ccrcr",
  "vxxvv",
  "ddddg",
  "dpdpd",
  "fgggf",
  "yydyd",
  "vvmmv",
  "wwsss",
  "ddhdd",
  "qaaaq",
  "jojoo",
  "xhxhh",
  "xxnnn",
  "nynyy",
  "ncccn",
  "xxsxx",
  "jjjqq",
  "rgggg",
  "iiirr",
  "ssuus",
  "sssrr",
  "tqqqt",
  "ppaaa",
  "nnonn",
  "fvffv",
  "rrrpr",
  "gjggj",
  "ttntt",
  "tpptt",
  "ttfff",
  "tgtgg",
  "eecec",
  "cxxxx",
  "ymmmm",
  "gbgbg",
  "wiwiw",
  "seees",
  "xyyyy",
  "akakk",
  "oyyoy",
  "raaar",
  "gggxg",
  "dvvvd",
  "ssfss",
  "xxxxv",
  "xxqqq",
  "wkkkw",
  "uppuu",
  "smsss",
  "ewewe",
  "wwnnn",
  "jtjtj",
  "tittt",
  "twtww",
  "ytyyt",
  "eexee",
  "fvfvv",
  "xxgxx",
  "gxxxg",
  "wwiii",
  "blbll",
  "wwkwk",
  "zfzzz",
  "ffooo",
  "baaab",
  "ttqtq",
  "cmccm",
  "qqeeq",
  "tpppp",
  "raara",
  "ddxxx",
  "cbcbb",
  "rrrrn",
  "qqqkq",
  "kakkk",
  "qqkkk",
  "mmmxx",
  "hhllh",
  "tuuut",
  "ptppp",
  "jjgjg",
  "scscs",
  "yyfff",
  "yxxyx",
  "ppppt",
  "addad",
  "mmmdm",
  "egeee",
  "ywyww",
  "rrqqq",
  "edede",
  "ppppj",
  "scssc",
  "qqqql",
  "hxxhh",
  "lllpl",
  "xyyyx",
  "uutuu",
  "itiii",
  "nznnn",
  "pffpf",
  "qppqq",
  "accaa",
  "tbtbb",
  "cfcfc",
  "rrjrr",
  "eiiee",
  "eeeed",
  "eeeqe",
  "pnppn",
  "xjjxx",
  "usuus",
  "tstst",
  "msmss",
  "zmmmz",
  "ubuub",
  "xxxxp",
  "oonon",
  "mmvmv",
  "fkfff",
  "oozzz",
  "tttcc",
  "gkggk",
  "ddiii",
  "ittit",
  "ffhhf",
  "lulll",
  "bbbab",
  "qqrqr",
  "bqqbq",
  "oolol",
  "lhhhh",
  "sstts",
  "kxxkx",
  "kffkk",
  "trttr",
  "wwggw",
  "szzsz",
  "aaeaa",
  "xeeee",
  "hzzhh",
  "eenen",
  "kskss",
  "hbbhh",
  "qnnnq",
  "psspp",
  "zazzz",
  "ywywy",
  "ddqqd",
  "hhhth",
  "aakaa",
  "ayyyy",
  "ohooh",
  "ppeep",
  "ttgtg",
  "wttwt",
  "rnrnr",
  "iiill",
  "zaazz",
  "qqqmm",
  "vvvvl",
  "fmfmm",
  "wlwwl",
  "eerrr",
  "ikiii",
  "qeeee",
  "taaaa",
  "wjwww",
  "nzznz",
  "zlzlz",
  "eueue",
  "wooww",
  "gnngn",
  "ppwwp",
  "nynyn",
  "cceee",
  "kgggg",
  "xxsss",
  "hhhhj",
  "hqqqh",
  "ffxxx",
  "nnjjj",
  "yyyhh",
  "rbrrb",
  "lkkkk",
  "ovvoo",
  "rggrr",
  "ccxxc",
  "ccqqq",
  "pnnnp",
  "mkmkk",
  "dwddd",
  "mzmmm",
  "mmmdd",
  "mtttt",
  "uuhuh",
  "lffll",
  "vvvkv",
  "uuqqu",
  "yyeey",
  "hssss",
  "atttt",
  "uunnu",
  "btbbb",
  "rrorr",
  "ahahh",
  "qqquu",
  "znznn",
  "cccyc",
  "tbbtt",
  "qwqqq",
  "rggrg",
  "vvpvp",
  "tltll",
  "aaada",
  "sllss",
  "dlddd",
  "kwwww",
  "ytttt",
  "hhwww",
  "iiziz",
  "ffeee",
  "blbbl",
  "ctccc",
  "ppepp",
  "zoozo",
  "fafff",
  "qbbbq",
  "tbbbt",
  "nnmmn",
  "nbbnb",
  "ooyyo",
  "nccnc",
  "jjjpp",
  "bbbuu",
  "lliii",
  "hhlhh",
  "mmmmr",
  "dddww",
  "ggbgg",
  "jjaaa",
  "vhvhh",
  "yywww",
  "ljjjl",
  "oovvo",
  "xxkkk",
  "zezze",
  "ttcct",
  "llrrr",
  "odddd",
  "ciici",
  "fkkkk",
  "urrur",
  "ppcpc",
  "idddi",
  "wwwwh",
  "qkqkk",
  "kllkl",
  "addda",
  "bbnnb",
  "rwwww",
  "eeeec",
  "nnenn",
  "ffvvf",
  "iiaaa",
  "yffyy",
  "vvyvy",
  "kmkmm",
  "eeell",
  "ggrgg",
  "cxxcx",
  "mmmgm",
  "qxqqx",
  "ooioi",
  "bbqqq",
  "mfffm",
  "vvvvp",
  "hjjjj",
  "uueee",
  "yyyii",
  "isiss",
  "sswws",
  "sttss",
  "aaaaf",
  "yyhyy",
  "wqqqq",
  "fffjf",
  "bbbmb",
  "gtgtt",
  "vvvqq",
  "gffff",
  "ppppu",
  "mqmqm",
  "pccpp",
  "rhhrr",
  "rdrrd",
  "jssss",
  "cjjcj",
  "dzzdz",
  "rrzzr",
  "rqrqq",
  "gzggg",
  "iuuiu",
  "ffnnf",
  "ooofo",
  "uuuqu",
  "yaaaa",
  "iiitt",
  "wwwwe",
  "jtjjj",
  "ddrrd",
  "rvvvr",
  "ffiif",
  "wwooo",
  "bxxxb",
  "hsshh",
  "wwxww",
  "mmmim",
  "grrgg",
  "iqqqi",
  "eeeeq",
  "aauaa",
  "ooddo",
  "rruuu",
  "ddrdd",
  "eekke",
  "qppqp",
  "jijij",
  "rdddr",
  "memmm",
  "ooooc",
  "ullul",
  "xtttt",
  "xsxss",
  "izizi",
  "mrmmm",
  "mmhmh",
  "qxqxx",
  "yzzzz",
  "gggtg",
  "ekeke",
  "lxllx",
  "aaqaq",
  "ummmu",
  "waaww",
  "xyxyx",
  "bppbp",
  "wvwwv",
  "gzzgg",
  "woowo",
  "dyyyy",
  "jzjzj",
  "aavvv",
  "moomo",
  "wwuwu",
  "xxxww",
  "illil",
  "qtqtt",
  "uppup",
  "ssnnn",
  "txttx",
  "gppgg",
  "zkzzk",
  "eneen",
  "qzqqz",
  "deded",
  "momom",
  "ciccc",
  "assas",
  "pvvvv",
  "kwkkw",
  "eebbb",
  "dgdgd",
  "yyyff",
  "llbll",
  "aoooa",
  "wxwxx",
  "saaaa",
  "jjvvj",
  "ywwwy",
  "zffzz",
  "jvjvj",
  "ppfpp",
  "lyyll",
  "klkll",
  "fbfbb",
  "fflfl",
  "eeeff",
  "aaaab",
  "aaaac",
  "qbqbq",
  "vvqvq",
  "upupp",
  "eeerr",
  "gjjjj",
  "ffaff",
  "ggddd",
  "wywyy",
  "qqqqa",
  "oeeoo",
  "eeppe",
  "dcddd",
  "hvvhv",
  "eeeex",
  "bbeeb",
  "wqqqw",
  "qcqqq",
  "jvjvv",
  "dadaa",
  "tttee",
  "mmxxx",
  "ititt",
  "xxcxc",
  "rnnnr",
  "cqqcq",
  "xxxaa",
  "kskks",
  "ggggc",
  "nnzzz",
  "fdddd",
  "ttjtj",
  "boooo",
  "wwllw",
  "lljjj",
  "nuunu",
  "ppppf",
  "uuuuh",
  "aalll",
  "vvvvw",
  "ggggw",
  "ereee",
  "ssssv",
  "tttlt",
  "ppppm",
  "rkrkr",
  "eemmm",
  "ddjdj",
  "qqyyy",
  "wqwww",
  "bbsbs",
  "ffbff",
  "qhhhq",
  "yykyk",
  "hdhhh",
  "vwvwv",
  "epppp",
  "xxxqq",
  "nnnwn",
  "ilili",
  "brrrr",
  "iiixx",
  "jjjnn",
  "bqbqb",
  "kkkkw",
  "dddgg",
  "owoow",
  "mmbmb",
  "vwvvw",
  "wwwcw",
  "aaaar",
  "fffxx",
  "wwcwc",
  "wkkww",
  "ecccc",
  "wbbbb",
  "vvvnn",
  "aaaat",
  "ttata",
  "twwww",
  "ugugu",
  "cccmc",
  "nnxxn",
  "giggg",
  "fddfd",
  "aarar",
  "pmmpm",
  "qiiqq",
  "sssus",
  "mmaaa",
  "xgxxx",
  "vppvv",
  "qvvqq",
  "blllb",
  "lllbl",
  "qqqvv",
  "zczzz",
  "aiaai",
  "ooouo",
  "aeaea",
  "moooo",
  "lloll",
  "vgvgg",
  "aasas",
  "bdbdb",
  "ssqss",
  "ooyoy",
  "bbnnn",
  "eeeme",
  "adadd",
  "dwddw",
  "mrmrm",
  "xqqxq",
  "ehhhe",
  "jjjvj",
  "ggvvv",
  "mnnnm",
  "jnjnj",
  "yylyy",
  "osoos",
  "qeeqq",
  "bpbbp",
  "rkrrr",
  "gwggw",
  "sooss",
  "ppaap",
  "kqqkk",
  "ririi",
  "rlrlr",
  "rooor",
  "wiiii",
  "fvfvf",
  "ccocc",
  "smssm",
  "tttrr",
  "cmmcm",
  "xxrxx",
  "wewwe",
  "wwwwj",
  "qmmmm",
  "iffff",
  "sssas",
  "aiiaa",
  "vdddd",
  "fwwfw",
  "dnnnd",
  "tnntt",
  "sssns",
  "ououu",
  "nnffn",
  "eeess",
  "wwewe",
  "miimm",
  "hzhhz",
  "exexe",
  "jcjcj",
  "sgssg",
  "nonnn",
  "tttmt",
  "wvvvw",
  "jgjgg",
  "fmfmf",
  "eeeew",
  "mddmm",
  "ririr",
  "poppo",
  "kkyyk",
  "fflff",
  "sssll",
  "cttcc",
  "ddvdd",
  "vvvff",
  "wrwww",
  "oocoo",
  "rddrd",
  "ibiib",
  "ssiii",
  "lqlqq",
  "ykyyy",
  "iccic",
  "cccbc",
  "mmmom",
  "ttete",
  "ygggg",
  "cccrc",
  "neeen",
  "gcccg",
  "ppphp",
  "ppmmp",
  "nqnqn",
  "gggcc",
  "llull",
  "zazaa",
  "cccgg",
  "hahhh",
  "yiyyy",
  "ssusu",
  "ssszs",
  "yyggg",
  "xwxww",
  "lrlrr",
  "lldll",
  "snsns",
  "okkko",
  "sshhh",
  "uuucu",
  "uuddu",
  "xxxhx",
  "hhdhd",
  "eeueu",
  "qqjjj",
  "xxbxx",
  "aaaza",
  "kllll",
  "cwwcw",
  "jxjxj",
  "bhbbh",
  "uiiuu",
  "ffftt",
  "cccmm",
  "rcccr",
  "eqqqe",
  "obboo",
  "mmmtm",
  "uuuiu",
  "sspps",
  "bbbbr",
  "wgwwg",
  "geege",
  "ggzzz",
  "mpmmm",
  "biiii",
  "yggyg",
  "vvhhv",
  "oooyy",
  "wnnwn",
  "upuup",
  "lkkll",
  "jijjj",
  "miiim",
  "hffhh",
  "qqffq",
  "eegge",
  "rdrdr",
  "fvfff",
  "jpppp",
  "gegee",
  "aagaa",
  "xxxjj",
  "bhbhh",
  "yvyvy",
  "uuccc",
  "mmpmp",
  "uyuyu",
  "zfzzf",
  "yqyqy",
  "zizzi",
  "dndnn",
  "pxpxx",
  "zdddz",
  "wjjjj",
  "kkkkj",
  "frfrr",
  "llqql",
  "zllzl",
  "aawaa",
  "zkzkz",
  "uuusu",
  "cyyyy",
  "ozozo",
  "ururu",
  "ttjtt",
  "tcttt",
  "hvhhh",
  "onnoo",
  "nnwnn",
  "wwnnw",
  "rraaa",
  "iiiuu",
  "xxoxo",
  "cpppc",
  "bbzbb",
  "mmddm",
  "ppkpk",
  "awwaw",
  "tjttt",
  "qqqnn",
  "peppp",
  "eeuuu",
  "eeaaa",
  "vjjjj",
  "xssss",
  "bkbbb",
  "jjooj",
  "lqqll",
  "mmjjm",
  "wzzzw",
  "sdssd",
  "ggguu",
  "eppep",
  "deedd",
  "xuxxx",
  "rjjrj",
  "ssnss",
  "ggggt",
  "jnnjn",
  "niiin",
  "imimi",
  "kkmmk",
  "xxxxs",
  "ruurr",
  "ssjsj",
  "xssxs",
  "lmmll",
  "ppkkk",
  "eaeaa",
  "ddqdq",
  "reerr",
  "rrwwr",
  "hhtth",
  "fmfff",
  "googo",
  "ninni",
  "yiyiy",
  "hhmmh",
  "bbbbg",
  "idiid",
  "sddss",
  "aaddd",
  "pphhp",
  "wvwvv",
  "nnpnn",
  "mzmzm",
  "bbkbk",
  "wqwqw",
  "smmsm",
  "jjjji",
  "oppop",
  "jaaaj",
  "ggsgs",
  "sssws",
  "ssasa",
  "rriir",
  "iiikk",
  "vuuuu",
  "yaayy",
  "pmpmp",
  "jjjxj",
  "xxxxz",
  "qgqgg",
  "wxxwx",
  "muuuu",
  "oooor",
  "ugugg",
  "yyjyy",
  "grggg",
  "kkkxx",
  "hghhg",
  "nnunu",
  "jjeee",
  "yssys",
  "ihhii",
  "rxrrx",
  "bbbbo",
  "ccaaa",
  "ceccc",
  "nnncn",
  "xlllx",
  "eaeea",
  "rrrri",
  "iiixi",
  "ttgtt",
  "xzxxz",
  "qffff",
  "ggnng",
  "mppmp",
  "akkak",
  "ywwyy",
  "uyuyy",
  "hhfhf",
  "qqjqj",
  "eleee",
  "nnnvn",
  "trttt",
  "errre",
  "fiiff",
  "tttgg",
  "lilil",
  "llill",
  "pccpc",
  "nhhhh",
  "xxjxj",
  "qqsqs",
  "zazaz",
  "wdddw",
  "aqaqq",
  "fttff",
  "xnnnn",
  "cbbcc",
  "ddzzz",
  "aasaa",
  "nqnnq",
  "hhhtt",
  "rrddr",
  "aaooa",
  "ddddk",
  "hbhbh",
  "kllkk",
  "pcppc",
  "pmmpp",
  "rrmmm",
  "wyyyw",
  "hhxhx",
  "gmggg",
  "iwwww",
  "nnttn",
  "rrsrr",
  "mmtmm",
  "brbbb",
  "spsps",
  "kkhhh",
  "qggqg",
  "ffffr",
  "qqwww",
  "wtwtt",
  "nnbbb",
  "fffaf",
  "hrrhr",
  "lklkk",
  "eebee",
  "bmbbb",
  "fttft",
  "jvjjv",
  "bbwwb",
  "hjhjh",
  "rkrkk",
  "eeeev",
  "qqqqh",
  "pmmmp",
  "jyyjj",
  "iiizz",
  "gagaa",
  "oxxox",
  "gggww",
  "jojjo",
  "rllrl",
  "ttxxx",
  "kykkk",
  "rkkrr",
  "abbbb",
  "enene",
  "dadda",
  "jjdjd",
  "shhss",
  "elell",
  "myymy",
  "xrrrx",
  "gkgkk",
  "hwhhw",
  "tttaa",
  "aiaii",
  "ggvgg",
  "hhooh",
  "cbccb",
  "ysysy",
  "gggvv",
  "cmmcc",
  "mlmml",
  "oroor",
  "ykyyk",
  "mbbmb",
  "kkhkh",
  "phppp",
  "llttt",
  "wwwkk",
  "psssp",
  "llrll",
  "kgkkk",
  "xxmmm",
  "lzllz",
  "bvbvb",
  "jjjjk",
  "pxppp",
  "pvppv",
  "gbbbg",
  "auaau",
  "snssn",
  "oooll",
  "ssvsv",
  "yyvvv",
  "hhhhn",
  "qvqvq",
  "psppp",
  "pddpd",
  "aaaas",
  "uqquq",
  "kkknn",
  "vvvdv",
  "pppyy",
  "tqqtt",
  "eetet",
  "kkuuu",
  "yyywy",
  "yppyy",
  "fffwf",
  "mmhhh",
  "wfwww",
  "eepep",
  "brbbr",
  "xuuxu",
  "pprrp",
  "mmkmk",
  "tbttt",
  "eieee",
  "xcccx",
  "rlrrl",
  "llluu",
  "vxvvv",
  "mmjmj",
  "jjbjb",
  "llnll",
  "lslll",
  "uuhhu",
  "ssssc",
  "cffcf",
  "hhdhh",
  "lrrrr",
  "tiiit",
  "rrdrr",
  "hlhlh",
  "yyyye",
  "rrfff",
  "ggggm",
  "xrxxx",
  "ddkkk",
  "kkuku",
  "mmmmb",
  "uwuuu",
  "rhhhh",
  "rrrnn",
  "jjjdj",
  "bddbb",
  "jjujj",
  "pjjpj",
  "kkkbb",
  "tgggt",
  "tytyy",
  "sttts",
  "hhhjj",
  "tyttt",
  "fffdd",
  "yyyqy",
  "hhhdh",
  "pppii",
  "ppqqq",
  "jjrjj",
  "rbbbr",
  "srrss",
  "ooeee",
  "arrar",
  "vovvv",
  "gxggx",
  "ssvvv",
  "xxxxt",
  "kbkbb",
  "sshhs",
  "zeezz",
  "gggll",
  "gghhg",
  "mmfff",
  "iiffi",
  "tktkk",
  "wawaa",
  "qddqd",
  "jjjtj",
  "kwwkw",
  "yyyvv",
  "rrnrn",
  "vdvvd",
  "eteee",
  "ppxpp",
  "ttthh",
  "ahaaa",
  "ooeeo",
  "pttpp",
  "xxoox",
  "rmmrm",
  "wpwpp",
  "ooooi",
  "rnrrr",
  "lqqlq",
  "yyqyq",
  "bwwwb",
  "uuyuy",
  "nnnll",
  "gllll",
  "sesee",
  "tnntn",
  "yyhhy",
  "xaxax",
  "fffxf",
  "jjjpj",
  "ssmsm",
  "uuaaa",
  "rlrrr",
  "jjjbj",
  "xlxxl",
  "jddjj",
  "lblll",
  "qddqq",
  "ymymm",
  "mmmmf",
  "yyryy",
  "zhhhh",
  "vzvzz",
  "yytyt",
  "ndndd",
  "evvvv",
  "sqqsq",
  "nnndn",
  "bgggg",
  "zhzhz",
  "xcxxc",
  "jjuuj",
  "vvqqv",
  "bobbo",
  "yxyyy",
  "tkkkk",
  "ooood",
  "oooco",
  "ywwyw",
  "faaaa",
  "mmwwm",
  "gyggg",
  "ttttu",
  "hnhnh",
  "nhhnh",
  "kggkk",
  "vkvkv",
  "yyycy",
  "tztzz",
  "eyyyy",
  "kkukk",
  "uttut",
  "bpbpp",
  "zmzzm",
  "rssrs",
  "neenn",
  "sssmm",
  "rrrjr",
  "kakak",
  "ffyyf",
  "yyyyr",
  "ppppv",
  "dodod",
  "kkkkr",
  "esses",
  "grgrg",
  "eepee",
  "ououo",
  "llwwl",
  "dgdgg",
  "aaadd",
  "yyccc",
  "dttdt",
  "euueu",
  "llkkk",
  "cllcl",
  "xxxex",
  "iliii",
  "ggyyy",
  "gccgg",
  "sggsg",
  "eewew",
  "rbrbr",
  "lllfl",
  "bffff",
  "jrrrj",
  "zczcz",
  "cbbbb",
  "iziii",
  "xxnxn",
  "mmqqq",
  "nknnk",
  "zddzd",
  "ddbbd",
  "mtmmm",
  "nnnrn",
  "cgggg",
  "rzzrr",
  "uduuu",
  "chhcc",
  "arrrr",
  "yytyy",
  "mhmmm",
  "bhhhh",
  "mffff",
  "qpqpp",
  "fuuff",
  "llllj",
  "iiigg",
  "qhhhh",
  "ldlll",
  "ubbuu",
  "ffppf",
  "ffhfh",
  "hhhuu",
  "ueuee",
  "guuug",
  "svsvv",
  "kkzzz",
  "pvvpv",
  "ggggd",
  "wbbbw",
  "bbbby",
  "xmmmm",
  "ssjjj",
  "yyoyy",
  "rrroo",
  "llllc",
  "kskkk",
  "sissi",
  "xhhxx",
  "bbkbb",
  "ussss",
  "pmmmm",
  "yttyy",
  "rrxrx",
  "llppl",
  "ennee",
  "aazaa",
  "yyyyj",
  "fdfdd",
  "kkkok",
  "ueeee",
  "yxyyx",
  "phhhh",
  "twwwt",
  "qqttt",
  "xxxxn",
  "yyyyh",
  "agagg",
  "otott",
  "uuiiu",
  "ccscc",
  "rhhrh",
  "yyyoo",
  "jjjyy",
  "uucuc",
  "eoeeo",
  "ggfgg",
  "uuddd",
  "lqqql",
  "goooo",
  "lclll",
  "llllr",
  "ffffa",
  "ddddj",
  "bbybb",
  "oddod",
  "dkddd",
  "yyttt",
  "rurur",
  "dppdd",
  "cmcmc",
  "kkvvv",
  "gjjgg",
  "kkwwk",
  "ddjdd",
  "ttkkk",
  "uyuuy",
  "kkkka",
  "nnnkn",
  "yynyy",
  "qqoqq",
  "aaiai",
  "xpppp",
  "iiiic",
  "ddkkd",
  "biibi",
  "ddgdd",
  "rrrcc",
  "xgggx",
  "evvve",
  "xnnxx",
  "mmnnm",
  "iiigi",
  "yydyy",
  "xxnxx",
  "vvvvq",
  "iqiqi",
  "aassa",
  "mddmd",
  "gggtt",
  "ibbii",
  "tpptp",
  "vviiv",
  "bmbbm",
  "yyaaa",
  "uiiii",
  "kkkkh",
  "hihih",
  "tktkt",
  "jjfjj",
  "bbpbp",
  "sjjsj",
  "neene",
  "pgppp",
  "cmccc",
  "eebeb",
  "tntnt",
  "hhsss",
  "opopp",
  "uooou",
  "fdfff",
  "kkxxx",
  "kckck",
  "keeek",
  "xzzxz",
  "uuuuq",
  "hzhhh",
  "mmuum",
  "annna",
  "fxfff",
  "jpjpj",
  "vvvev",
  "bbhbh",
  "yiyyi",
  "vlllv",
  "xmxxx",
  "hjhhh",
  "kkkkp",
  "gigii",
  "affff",
  "sxxxs",
  "ttttl",
  "bbddd",
  "vmvmm",
  "yxxxx",
  "wswss",
  "gfggg",
  "cicci",
  "ubbub",
  "uuiuu",
  "zcczc",
  "jgggj",
  "hhhpp",
  "nwnnw",
  "gpggp",
  "qqbqb",
  "jttjj",
  "xxixx",
  "nnnbb",
  "mmzzm",
  "iwiiw",
  "luulu",
  "zbbbz",
  "ccuuu",
  "ryyyr",
  "sslsl",
  "iiini",
  "uuooo",
  "bbbpb",
  "uvuuv",
  "ohooo",
  "iiiri",
  "jnjjn",
  "qnqqn",
  "ryryr",
  "roror",
  "vbvbv",
  "icccc",
  "rrnnn",
  "yylyl",
  "vvqqq",
  "eeddd",
  "ffffu",
  "aauua",
  "wtwww",
  "ddded",
  "scsss",
  "nnuun",
  "qqqqu",
  "bbbbi",
  "dzzzd",
  "ppqpq",
  "slsll",
  "pipii",
  "pppss",
  "ckkck",
  "puppu",
  "hrhhh",
  "qfqqf",
  "hqhhh",
  "jjljl",
  "uuttu",
  "qsqss",
  "zezee",
  "qqqeq",
  "byyyb",
  "mvmvm",
  "rkrrk",
  "yygyy",
  "twwtw",
  "maamm",
  "fsffs",
  "lhhll",
  "aaamm",
  "kkjkj",
  "btttb",
  "kekke",
  "dyydy",
  "zmmzm",
  "ccppc",
  "uxuxx",
  "eyyee",
  "oommm",
  "bbbzz",
  "gngnn",
  "vvrvr",
  "joooj",
  "eeeen",
  "uxxxu",
  "ininn",
  "eeoee",
  "rarar",
  "lpppp",
  "jjjjn",
  "iigig",
  "tiiti",
  "ggppp",
  "qqqii",
  "nenne",
  "pppgp",
  "ccoco",
  "bebeb",
  "xiiix",
  "iviiv",
  "tcccc",
  "btttt",
  "gfffg",
  "fefef",
  "ttaaa",
  "nnsss",
  "zmzmm",
  "tthtt",
  "awwwa",
  "mimmi",
  "goggg",
  "yysys",
  "iqiiq",
  "yyooo",
  "giiig",
  "iixii",
  "nannn",
  "jjjuu",
  "qqnqn",
  "qnqnn",
  "vavav",
  "hyyhh",
  "frrff",
  "kkgkg",
  "dddpp",
  "ddooo",
  "raarr",
  "ggngg",
  "ekkke",
  "rrqrq",
  "xkkxx",
  "qwqww",
  "ssskk",
  "wwttt",
  "yylly",
  "jrjjj",
  "pbppb",
  "ddtdt",
  "wkkkk",
  "wllwl",
  "efefe",
  "pffff",
  "ppqqp",
  "wgggw",
  "wlwll",
  "kikii",
  "ememe",
  "iqiii",
  "lxlxx",
  "xmxmm",
  "yyxxx",
  "vjvjv",
  "vzzvz",
  "eeeez",
  "iiddd",
  "yvvyv",
  "hhhha",
  "qqyyq",
  "naaaa",
  "hlhll",
  "xsxxx",
  "rqqqq",
  "hhhhy",
  "hthht",
  "tssst",
  "lllcc",
  "dwwww",
  "vttvv",
  "aaggg",
  "vjjvj",
  "wooow",
  "gfgff",
  "ttxxt",
  "zgggz",
  "ptppt",
  "yjyjj",
  "uuhuu",
  "plplp",
  "fffuu",
  "rhrhh",
  "bvbbb",
  "xsssx",
  "kpkkk",
  "ssccs",
  "sppsp",
  "fmmmf",
  "lqlql",
  "ypyyy",
  "ccpcc",
  "kkkzz",
  "thttt",
  "iiqiq",
  "pprpr",
  "ppppw",
  "ixiix",
  "wqwqq",
  "qvvqv",
  "lnnll",
  "cacac",
  "mgmgm",
  "xbxbx",
  "iisis",
  "ohhho",
  "aahhh",
  "qqqqe",
  "csssc",
  "szszs",
  "fqqqf",
  "uzzuz",
  "ggghg",
  "cvvcv",
  "qeeqe",
  "ppvpv",
  "xhhhx",
  "ppppo",
  "ojooo",
  "tttzt",
  "yyzzz",
  "cycyy",
  "vvvvz",
  "zkzkk",
  "fiifi",
  "yyyys",
  "hhvvv",
  "ocooc",
  "qqqoo",
  "uuupu",
  "yoooo",
  "tttss",
  "qqeee",
  "dsdsd",
  "dggdd",
  "innni",
  "ynnnn",
  "wdddd",
  "weewe",
  "naann",
  "xxxbb",
  "nnnxx",
  "fqffq",
  "jajaa",
  "hehee",
  "mcccm",
  "vkvkk",
  "ejjje",
  "nnfff",
  "ononn",
  "ububb",
  "vkkvk",
  "xgxgg",
  "qquuu",
  "nnvvn",
  "kqkqq",
  "nggnn",
  "wwggg",
  "rrssr",
  "fggff",
  "nnqqn",
  "rrrbr",
  "ddada",
  "wbwwb",
  "nnntn",
  "rrara",
  "lvllv",
  "ssssg",
  "gyyyy",
  "dpddp",
  "tdtdd",
  "llglg",
  "znnzz",
  "wwwdw",
  "ddduu",
  "qiqqi",
  "ppsps",
  "bnbbn",
  "pzpzz",
  "yuuuy",
  "iiinn",
  "llrlr",
  "bbblb",
  "tattt",
  "wxxww",
  "zllzz",
  "ddfff",
  "glggl",
  "cwccc",
  "fwfwf",
  "gggyg",
  "hhhyh",
  "hhchc",
  "dxddx",
  "qqqgg",
  "xvvvx",
  "gdgdd",
  "dddhd",
  "urrrr",
  "juuuj",
  "iinin",
  "wwmmm",
  "ooaoo",
  "jljjl",
  "eeete",
  "irrrr",
  "bccbb",
  "bhhbb",
  "axaxa",
  "yykyy",
  "ivvvi",
  "xxxpp",
  "eooee",
  "anaaa",
  "bbkkk",
  "hhhoh",
  "effee",
  "glggg",
  "ekeee",
  "titti",
  "nrrrn",
  "llaaa",
  "uuauu",
  "mjjmm",
  "jmjjj",
  "oxooo",
  "pwwpw",
  "rryyy",
  "hhhah",
  "jjuju",
  "pxppx",
  "kqqqk",
  "wwrww",
  "uguuu",
  "chchh",
  "ssggg",
  "eeoeo",
  "nqqnq",
  "auuua",
  "nnnii",
  "jsjjj",
  "rjrjr",
  "ddrrr",
  "ooroo",
  "ffffc",
  "ktktk",
  "bbooo",
  "coooo",
  "ccncc",
  "ttmmt",
  "wzzww",
  "lblbl",
  "ghghg",
  "flllf",
  "gigig",
  "ebeeb",
  "joojo",
  "ggygy",
  "sqsqs",
  "bbuub",
  "uuzzz",
  "jppjj",
  "hgggg",
  "eeeoo",
  "ffaaf",
  "yoooy",
  "zaaza",
  "pppgg",
  "pcccp",
  "hhhih",
  "vvrrv",
  "eueee",
  "ggggx",
  "tnttn",
  "eeqqe",
  "khkkh",
  "vvvnv",
  "ndndn",
  "gwggg",
  "vaaav",
  "fhffh",
  "tuutu",
  "qdqqd",
  "epepp",
  "cjcjj",
  "ccjjc",
  "fzzzf",
  "brbrr",
  "fnnff",
  "wwwff",
  "mmffm",
  "lyyly",
  "ioioi",
  "yybyy",
  "cccaa",
  "gggfg",
  "tette",
  "kmmkm",
  "ninnn",
  "fuffu",
  "lglgl",
  "rfrfr",
  "ddaaa",
  "jjjxx",
  "hhcch",
  "llllm",
  "ygygy",
  "qqmqq",
  "tggtg",
  "vfffv",
  "luluu",
  "lleee",
  "pppfp",
  "vvtvv",
  "ttbtt",
  "npnpn",
  "ewwww",
  "paapa",
  "sssms",
  "nxxxx",
  "zjzjj",
  "mpppm",
  "csscs",
  "nfnnf",
  "jjwwj",
  "jqjjj",
  "cpcpp",
  "nfnnn",
  "ddddn",
  "lkklk",
  "bbbbq",
  "mgggm",
  "wwfww",
  "bzzzb",
  "yyppy",
  "wwppw",
  "vvggv",
  "iiizi",
  "yyryr",
  "oopoo",
  "dbbdd",
  "rmrrr",
  "ppbbb",
  "mbmmb",
  "qiiqi",
  "dccdd",
  "onnno",
  "oovoo",
  "uoouo",
  "bbbbp",
  "ddmdm",
  "qmqqm",
  "abbab",
  "hihii",
  "ddzzd",
  "vqqqq",
  "xyyxx",
  "dcdcc",
  "kkakk",
  "czccz",
  "njjnj",
  "bdbdd",
  "oooqo",
  "fhhff",
  "bbcbb",
  "nntnn",
  "vvyyy",
  "eenee",
  "xxkkx",
  "fzzff",
  "booob",
  "tstss",
  "yjyjy",
  "vvoov",
  "hsshs",
  "gggzz",
  "maaam",
  "npppn",
  "errer",
  "wwjwj",
  "oozzo",
  "wwwrr",
  "ktkkt",
  "ggggb",
  "prprp",
  "tfftf",
  "msmms",
  "vxvvx",
  "zbzzb",
  "dvdvv",
  "uuuww",
  "ykkky",
  "wwwaw",
  "oodod",
  "mmssm",
  "aaaca",
  "fkkff",
  "jajjj",
  "jyyyy",
  "sgsgg",
  "pppzz",
  "qqqll",
  "xwwwx",
  "ppppx",
  "ffsss",
  "hxxxx",
  "xfffx",
  "ldddd",
  "viiii",
  "mymmm",
  "jqjqj",
  "qqfqq",
  "tttdt",
  "wwhwh",
  "evvev",
  "jjrrr",
  "teete",
  "uuouo",
  "ccyyc",
  "hhssh",
  "jjsjj",
  "iyiii",
  "irrii",
  "vvvfv",
  "yyeye",
  "stsst",
  "xxixi",
  "rrrrh",
  "gttgg",
  "nooon",
  "daaaa",
  "dddid",
  "ffffo",
  "ffffz",
  "yyyyb",
  "mllmm",
  "mmmee",
  "wwqqq",
  "vvyvv",
  "hhkkh",
  "llell",
  "uukuu",
  "wwqwq",
  "kdkdk",
  "phpph",
  "ufuff",
  "bbbob",
  "qqzqz",
  "dvvdd",
  "ywwww",
  "eeebb",
  "clccc",
  "blbbb",
  "mmmcc",
  "kckkk",
  "qlqlq",
  "vyyyy",
  "ororo",
  "yybyb",
  "ddpdp",
  "llili",
  "gggaa",
  "zkkzk",
  "ghghh",
  "hhhhb",
  "ojjoo",
  "gggcg",
  "qqqtq",
  "xllll",
  "ueueu",
  "xuxux",
  "ppzzz",
  "essee",
  "ggiii",
  "gxgxx",
  "ggggr",
  "ppxxp",
  "llltl",
  "bbhhh",
  "oqooq",
  "cvcvc",
  "nhhnn",
  "mmbbm",
  "ffbbf",
  "aabba",
  "jjttj",
  "oaaao",
  "ieeie",
  "aaeee",
  "scscc",
  "gmmmm",
  "oqqoo",
  "tttff",
  "feeef",
  "khhhh",
  "wtwwt",
  "tdddd",
  "ppoop",
  "rrkrk",
  "jcjjc",
  "gggdg",
  "jjvjj",
  "uwwuu",
  "dddzd",
  "aeeee",
  "yvvyy",
  "bmmmb",
  "lgllg",
  "enenn",
  "qqqlq",
  "dvvdv",
  "omoom",
  "unnnn",
  "kkaaa",
  "cnnnc",
  "kjkjk",
  "bbbbk",
  "voovv",
  "ebebe",
  "ccecc",
  "kkklk",
  "eleel",
  "dqdqd",
  "kppkk",
  "ihihh",
  "mmuuu",
  "ttrtt",
  "uujju",
  "mmmmw",
  "qqqtt",
  "leeel",
  "aaqqa",
  "kkkss",
  "ppwww",
  "aaaha",
  "lelee",
  "vvvvt",
  "ieiii",
  "jjggg",
  "jjffj",
  "ttddd",
  "kfkkf",
  "kkaak",
  "uccuc",
  "knknn",
  "vcvvv",
  "kkiik",
  "wxwxw",
  "jjjjd",
  "prprr",
  "hllhl",
  "bbvbb",
  "lwlww",
  "mmrrr",
  "vrrrv",
  "fefee",
  "zjjzz",
  "ccccr",
  "nnngn",
  "lllli",
  "viivi",
  "hjhjj",
  "fffif",
  "lllel",
  "illli",
  "ssppp",
  "bssss",
  "yzzyz",
  "ttytt",
  "ssssx",
  "fbffb",
  "ogoog",
  "vaaaa",
  "vvkkk",
  "fcccc",
  "sysyy",
  "llyll",
  "bjbbb",
  "oooot",
  "kkkkt",
  "oooop",
  "papap",
  "pppdp",
  "yxyxy",
  "ytyyy",
  "wwwaa",
  "qoqqq",
  "yhyhy",
  "aaxxx",
  "bllbl",
  "pxxpx",
  "xbxxb",
  "yyqqy",
  "mwmmw",
  "bbhbb",
  "mmimi",
  "ttbtb",
  "irrri",
  "qqiiq",
  "ccscs",
  "mssmm",
  "eieei",
  "ollol",
  "gggpp",
  "jjjlj",
  "aaatt",
  "iifii",
  "eeebe",
  "shhhs",
  "tiiii",
  "ofofo",
  "kxxkk",
  "xccxc",
  "mmdmd",
  "pzppz",
  "bbbaa",
  "ssbsb",
  "ommmm",
  "iiiit",
  "bfbbb",
  "hbhbb",
  "szzzz",
  "dkkdd",
  "rrhrr",
  "dddkd",
  "ccncn",
  "ddpdd",
  "vvuuv",
  "pepee",
  "nunuu",
  "oopop",
  "cbbcb",
  "awaaw",
  "mmeme",
  "iciii",
  "iiiui",
  "nnjjn",
  "seeee",
  "iiiyy",
  "ccjcj",
  "qqwqw",
  "uzuuz",
  "aaann",
  "dddfd",
  "hghgh",
  "jjxxx",
  "bbbbh",
  "mvmvv",
  "fifif",
  "lalll",
  "snsss",
  "dnnnn",
  "lmlll",
  "znzzn",
  "ppapa",
  "qxxxq",
  "iaaii",
  "dggdg",
  "aeeaa",
  "awaww",
  "jrrjj",
  "qggqq",
  "kyykk",
  "azazz",
  "lmlml",
  "llsls",
  "kddkk",
  "addaa",
  "cccec",
  "zkkzz",
  "ttltt",
  "xxxxc",
  "hzhzz",
  "yycyy",
  "iiiie",
  "gbggb",
  "yyddd",
  "xxttt",
  "aooaa",
  "iinni",
  "sshsh",
  "lhhlh",
  "xvxxv",
  "hohho",
  "ioiio",
  "fwfww",
  "llvvv",
  "vvcvv",
  "aeeae",
  "ggttg",
  "vvwwv",
  "xppxx",
  "wrrrr",
  "hdhhd",
  "xllxx",
  "lllss",
  "obbob",
  "ffzzf",
  "vvllv",
  "qttqq",
  "mmmmc",
  "eeexx",
  "dddde",
  "yfyff",
  "lllql",
  "hhbhh",
  "vovvo",
  "vvvwv",
  "laall",
  "uuhhh",
  "pooop",
  "qgggq",
  "pwwwp",
  "yyhyh",
  "tsttt",
  "vaavv",
  "qqgqg",
  "pppyp",
  "llhll",
  "gnggg",
  "koook",
  "yvvvv",
  "yuyyy",
  "fpfff",
  "ddtdd",
  "onnnn",
  "yyybb",
  "ggcgg",
  "jjiij",
  "exxee",
  "qpppp",
  "wwuww",
  "hoooh",
  "eeeef",
  "ttiit",
  "whhwh",
  "xixix",
  "eeiei",
  "doooo",
  "tqtqq",
  "ttwww",
  "yypyy",
  "iiivi",
  "zlzll",
  "hhhfh",
  "txtxx",
  "kkssk",
  "yfyyy",
  "wwtww",
  "qqaqa",
  "vvvjj",
  "xxxnn",
  "yyymm",
  "iaiia",
  "ijiji",
  "ssssz",
  "rmrrm",
  "wvvwv",
  "fffrf",
  "oeeee",
  "bjjbj",
  "zgzgz",
  "jjcjj",
  "zdzzz",
  "noooo",
  "tddtt",
  "hbbbb",
  "kkiki",
  "hyyyh",
  "aadaa",
  "anann",
  "ffeef",
  "mbbbm",
  "cccdc",
  "fbfbf",
  "ytytt",
  "bbbbm",
  "eeiie",
  "ttstt",
  "iippp",
  "kkkww",
  "yyvvy",
  "iicci",
  "ggzzg",
  "qqdqd",
  "ruuur",
  "eooeo",
  "ggpgp",
  "sxxxx",
  "yyoyo",
  "zeeze",
  "qvqvv",
  "jvvvj",
  "ttqqq",
  "aaffa",
  "esess",
  "yywwy",
  "gtggt",
  "uueuu",
  "dqddq",
  "kakka",
  "cccoo",
  "ffffx",
  "aajaj",
  "dddii",
  "yvyyv",
  "geggg",
  "ppssp",
  "ooouu",
  "lluuu",
  "rxrrr",
  "uuouu",
  "mlmmm",
  "nnknn",
  "oaooo",
  "sswss",
  "eeeqq",
  "cggcc",
  "kaaak",
  "uucuu",
  "jjhhh",
  "qqqbq",
  "bebbe",
  "ljljl",
  "buuuu",
  "hyhhy",
  "pppnp",
  "iiiff",
  "tmmtm",
  "iiuuu",
  "ccckk",
  "vhvhv",
  "wuuwu",
  "auuuu",
  "xxhhh",
  "suusu",
  "kknkn",
  "ddttd",
  "hsssh",
  "dddpd",
  "xgxxg",
  "yyyjj",
  "qqhhq",
  "wnnnn",
  "qqqwq",
  "hhyyy",
  "bbbkb",
  "reere",
  "ebebb",
  "uuuff",
  "cbcbc",
  "eeyyy",
  "ngnnn",
  "rccrc",
  "bfbfb",
  "annaa",
  "xtxtx",
  "uquuu",
  "eaeae",
  "bxxbb",
  "klklk",
  "zjjjz",
  "foffo",
  "fffpp",
  "xjjjj",
  "fffjj",
  "rprpp",
  "qqppp",
  "vnnnn",
  "diddd",
  "ctctc",
  "yuuyy",
  "ubbbu",
  "wwbwb",
  "uqqqq",
  "sisii",
  "diiii",
  "zhhhz",
  "ajaaa",
  "yyyss",
  "oqoqq",
  "vlvvl",
  "hvhvv",
  "nbnnn",
  "yyccy",
  "nnsnn",
  "feeee",
  "lllgl",
  "avavv",
  "ipiii",
  "uauuu",
  "ddeed",
  "uunuu",
  "bvvvb",
  "ffffy",
  "bbbxb",
  "rrnnr",
  "wwzzz",
  "yyyyf",
  "wwwwu",
  "hphhp",
  "yyyky",
  "bbbqb",
  "mwmwm",
  "rryry",
  "eejej",
  "vtvvt",
  "olool",
  "qyyyy",
  "liiii",
  "ddddh",
  "ybbyy",
  "wyywy",
  "msmmm",
  "hoooo",
  "jejej",
  "qlqql",
  "yyxxy",
  "wttww",
  "qqttq",
  "lllvl",
  "wwdww",
  "jajaj",
  "zhzzz",
  "qqwqq",
  "gmmgm",
  "qqqhh",
  "srssr",
  "uquuq",
  "iiiiu",
  "wzwwz",
  "uuuuw",
  "pypyp",
  "yyjjy",
  "xuuuu",
  "tttnn",
  "iigii",
  "httth",
  "quuuq",
  "uuaua",
  "rcrrr",
  "sgggg",
  "mmmkm",
  "occco",
  "rllll",
  "ssssy",
  "ccyyy",
  "bbbba",
  "ccrrc",
  "ikkii",
  "aaaal",
  "ybybb",
  "nnnqq",
  "qcqcq",
  "rrrrw",
  "yyyyk",
  "dxxxx",
  "mmmjj",
  "xyxxx",
  "tyytt",
  "qqqcc",
  "drddd",
  "hhxhh",
  "bbbjb",
  "mmtmt",
  "vvvss",
  "oojjo",
  "jjsss",
  "vuvuu",
  "ccece",
  "zbzzz",
  "ccfcc",
  "rrbrr",
  "shshs",
  "btbtb",
  "yyuyy",
  "xjjjx",
  "rtrrr",
  "hhshs",
  "fffgf",
  "llwww",
  "yyppp",
  "xqxxx",
  "kvvkk",
  "iissi",
  "ccicc",
  "totto",
  "pippp",
  "fqfff",
  "tqttt",
  "bbqqb",
  "biibb",
  "kzzkz",
  "aoaao",
  "nlnln",
  "dddmd",
  "uuuju",
  "yypyp",
  "mxxmm",
  "llcll",
  "eejje",
  "ioooo",
  "qqxqx",
  "xtxxt",
  "kkvvk",
  "uuugu",
  "nninn",
  "ldldl",
  "noono",
  "rrrgr",
  "ofoof",
  "rvrrv",
  "tvvtt",
  "ddddy",
  "ccllc",
  "ssbbs",
  "ztttz",
  "jdjjd",
  "qqxxq",
  "hppph",
  "yyrry",
  "xxxxe",
  "laaaa",
  "qqqvq",
  "wnwwn",
  "hqqhh",
  "cjccj",
  "toott",
  "prppp",
  "uqqqu",
  "bbddb",
  "llllx",
  "vvvvm",
  "hdddd",
  "ccdcc",
  "oeooe",
  "bbbgg",
  "ttttf",
  "foooo",
  "ddddq",
  "iibbb",
  "uwwuw",
  "ccccx",
  "nnggn",
  "zszzz",
  "sksss",
  "xdxxx",
  "lllff",
  "bbbrb",
  "ffffs",
  "ozooo",
  "aaiii",
  "hhffh",
  "fmmmm",
  "ppppd",
  "dtdtd",
  "ggggs",
  "yoyyy",
  "nmmmn",
  "gggge",
  "bbfbb",
  "ygggy",
  "mqqqq",
  "qnnqn",
  "hchch",
  "rttrr",
  "uuujj",
  "apaap",
  "jfjjf",
  "bbbxx",
  "rrtrt",
  "affaa",
  "seses",
  "vtvvv",
  "sddsd",
  "eeehe",
  "srrsr",
  "wwwqq",
  "ttttm",
  "tlltt",
  "spspp",
  "veevv",
  "qqqqy",
  "mpmpm",
  "ittti",
  "cllll",
  "crccc",
  "bbnbn",
  "kkeke",
  "joooo",
  "jdjdj",
  "ddddm",
  "bbbbc",
  "orrrr",
  "uuvuv",
  "fffsf",
  "ccwcw",
  "vqqvv",
  "nnssn",
  "yyygg",
  "kkttt",
  "sssqs",
  "eeded",
  "efffe",
  "xgxgx",
  "ppnnn",
  "mmmmj",
  "yttty",
  "gdggg",
  "gccgc",
  "rhhhr",
  "wwwbb",
  "yynyn",
  "ununu",
  "zzbzz",
  "dhdhd",
  "jfffj",
  "nzzzn",
  "xxxgx",
  "bbtbt",
  "iihih",
  "xppxp",
  "ioiii",
  "zgzgg",
  "nnqnn",
  "udddd",
  "rwrrr",
  "yyfyy",
  "ylyly",
  "nwwwn",
  "npnnp",
  "cchhh",
  "piiip",
  "hhppp",
  "ogogg",
  "mmccc",
  "jjjrr",
  "wgggg",
  "nrnrr",
  "zszzs",
  "cdddd",
  "ololo",
  "buubu",
  "prrrr",
  "nnfnf",
  "zhzhh",
  "wwyww",
  "yynnn",
  "eggge",
  "bbiii",
  "ccmmc",
  "ghhgh",
  "ylyll",
  "hhxxx",
  "swssw",
  "ttllt",
  "qmqmm",
  "bobbb",
  "sssyy",
  "amama",
  "cxxcc",
  "plppp",
  "wxwww",
  "kwkwk",
  "looll",
  "vooov",
  "imiii",
  "lelle",
  "kkkoo",
  "dddyy",
  "gxggg",
  "vccvv",
  "mhhhh",
  "sseee",
  "gvggg",
  "uuuee",
  "vvhvh",
  "ihiii",
  "tmttt",
  "fkkfk",
  "bbibi",
  "wowoo",
  "ddzdd",
  "xxppx",
  "wwwjw",
  "uuucc",
  "ppopo",
  "ssfff",
  "mmmaa",
  "quuuu",
  "imimm",
  "kckkc",
  "tjtjt",
  "zzccz",
  "sxsxs",
  "zzeez",
  "vuuvv",
  "ibibi",
  "bbrbr",
  "iicii",
  "ofoff",
  "xxxvx",
  "dmmdd",
  "vxxxx",
  "egege",
  "rrrnr",
  "aakkk",
  "ppnpn",
  "pppjj",
  "pppxx",
  "pwppp",
  "mmwmw",
  "jaaaa",
  "rrbbb",
  "ujuju",
  "beebb",
  "fbbff",
  "aggga",
  "ksskk",
  "vvvvs",
  "mmoom",
  "igiii",
  "ggdgg",
  "tkktt",
  "kokkk",
  "kkkgk",
  "yryry",
  "zwzzz",
  "lllsl",
  "ckkkc",
  "ffnff",
  "dyddy",
  "wjwwj",
  "igggg",
  "oxxxo",
  "yaaay",
  "jjccj",
  "ffoff",
  "hmmhm",
  "xttxt",
  "ymmmy",
  "eeggg",
  "ltllt",
  "jlllj",
  "hhqhh",
  "uusss",
  "qqqzz",
  "ttltl",
  "lcccc",
  "klkkk",
  "owoww",
  "phhhp",
  "kkgkk",
  "tnnnn",
  "zvzzv",
  "eeeey",
  "sssps",
  "aaaao",
  "ararr",
  "vvbbv",
  "hhhww",
  "zxzxx",
  "pvpvv",
  "ccccy",
  "oaooa",
  "aajaa",
  "jjjmm",
  "ckkkk",
  "miiii",
  "zvzvz",
  "oooho",
  "bwbww",
  "mmmcm",
  "uuuue",
  "aaaja",
  "wwrrw",
  "zszss",
  "lllmm",
  "dsddd",
  "rsrss",
  "nxxxn",
  "qkqqq",
  "cbccc",
  "vvivv",
  "wggww",
  "ynynn",
  "hihhi",
  "ggjgj",
  "zhhzh",
  "zxxzx",
  "ztttt",
  "tvvtv",
  "txxtt",
  "kzkzz",
  "ydydy",
  "aaaaw",
  "myyym",
  "ccggc",
  "xpxpx",
  "dcdcd",
  "nknnn",
  "zbbzb",
  "cuuuc",
  "cbbbc",
  "ccczz",
  "nvnnn",
  "zvzvv",
  "beeee",
  "kkwkk",
  "mmmbb",
  "knnnn",
  "zjzzz",
  "mssss",
  "oooso",
  "ggfff",
  "rrggr",
  "obbbb",
  "zvzzz",
  "nppnn",
  "byyby",
  "sjjss",
  "jjlll",
  "adaad",
  "zoooo",
  "hhhbh",
  "uunnn",
  "dfdff",
  "xhhhh",
  "diddi",
  "ojjoj",
  "wwbbw",
  "zyzzz",
  "iuiui",
  "yyyiy",
  "yynny",
  "ppttt",
  "vnnvv",
  "bbppp",
  "jgjgj",
  "wwwlw",
  "qbqbb",
  "mtttm",
  "egegg",
  "flfff",
  "hahah",
  "dwdwd",
  "ssnns",
  "csccc",
  "mhhhm",
  "yvvvy",
  "rxxxr",
  "iuiii",
  "gggag",
  "lylyy",
  "rrhrh",
  "ppqpp",
  "attat",
  "yyydy",
  "tvttt",
  "eeyye",
  "lplll",
  "eeeeu",
  "nmmnn",
  "ceecc",
  "oyyoo",
  "dddnd",
  "ggngn",
  "wuuww",
  "hddhh",
  "hjjhj",
  "zzeee",
  "wwoow",
  "nwnnn",
  "fzfzz",
  "vmvvv",
  "eveve",
  "kktkk",
  "rvrvv",
  "ewwwe",
  "fvvvf",
  "qqlqq",
  "qmmqm",
  "ddwdw",
  "qkqqk",
  "hmmmm",
  "brrrb",
  "oojjj",
  "bttbt",
  "hnnhh",
  "foofo",
  "tctcc",
  "wrrww",
  "ppppb",
  "qrrrr",
  "nnwnw",
  "gdgdg",
  "oiioi",
  "fyfff",
  "sbbss",
  "gnngg",
  "nnyyn",
  "bbbsb",
  "xxhhx",
  "ooovo",
  "hrrhh",
  "kiiii",
  "kkskk",
  "xtttx",
  "pvvpp",
  "jjjjc",
  "vxxxv",
  "kvkkk",
  "qqmmq",
  "llyly",
  "ifiii",
  "ttaat",
  "qrqqq",
  "dddbb",
  "cccyy",
  "igigi",
  "vmmmv",
  "aaaqa",
  "llzzz",
  "yyyoy",
  "nnnhn",
  "ewwee",
  "fffnn",
  "rrrbb",
  "jjwjw",
  "zazza",
  "vivvi",
  "spssp",
  "wwwnw",
  "xxxox",
  "krkrk",
  "kakaa",
  "feffe",
  "nhnnh",
  "ffzff",
  "svsvs",
  "kekee",
  "uuuuk",
  "nnndd",
  "qkkqk",
  "uduud",
  "jcccc",
  "uubub",
  "wwmmw",
  "coooc",
  "eheee",
  "oooss",
  "cdccc",
  "zozzz",
  "qqqqb",
  "rrjjj",
  "bbbdd",
  "mmttm",
  "zzeze",
  "wwwtw",
  "ygygg",
  "offfo",
  "ssqqq",
  "keeke",
  "ikiki",
  "pxxpp",
  "rnrnn",
  "phhph",
  "pppll",
  "ydyyd",
  "vlvvv",
  "rrmrm",
  "svvvs",
  "pzzzp",
  "bbyby",
  "jxxxj",
  "aafaa",
  "bbjbb",
  "xsxsx",
  "pjpjj",
  "ococc",
  "peepe",
  "ssssm",
  "xjjxj",
  "vvppv",
  "qgqqg",
  "ururr",
  "fxxxx",
  "jjjcj",
  "aayaa",
  "dddjj",
  "ffggf",
  "aaaga",
  "aqaaq",
  "mmlml",
  "uuuvu",
  "ovoov",
  "icici",
  "jujjj",
  "hxhxh",
  "zzdzd",
  "kkkzk",
  "uuxxu",
  "ssdds",
  "cnccc",
  "llfll",
  "fgggg",
  "vsvvv",
  "oqqqo",
  "eeeea",
  "nwwww",
  "lkllk",
  "xxccx",
  "ccjcc",
  "ngnng",
  "ddddw",
  "gnnnn",
  "bxbbb",
  "lalla",
  "aaaae",
  "yyypy",
  "bsbbb",
  "qqvvv",
  "ccffc",
  "hqhhq",
  "jccjj",
  "zxxxz",
  "qoooq",
  "llljj",
  "cjjjj",
  "mlllm",
  "roooo",
  "yyyyq",
  "srsrr",
  "hhhhu",
  "sdsss",
  "hcchh",
  "uffff",
  "mnmnn",
  "yzzzy",
  "uufuf",
  "zgggg",
  "ssuss",
  "aadda",
  "pbbpp",
  "qqqee",
  "llddl",
  "xfxxf",
  "yzyzy",
  "xssxx",
  "ccmcm",
  "mdmdm",
  "caacc",
  "sssii",
  "ohhoh",
  "nnsns",
  "nnqqq",
  "ugggu",
  "jjjjr",
  "eeeel",
  "ggrrg",
  "aakak",
  "jxjxx",
  "uulll",
  "iizzz",
  "yeeye",
  "ltltt",
  "wwwzw",
  "brbrb",
  "ddhhd",
  "wjwjj",
  "yqqqy",
  "eyyye",
  "ncnnc",
  "bbbyb",
  "xxaxa",
  "qzqzz",
  "vgggg",
  "jzjzz",
  "cccac",
  "kkkpp",
  "cffcc",
  "aaapa",
  "ffnnn",
  "dssds",
  "hhhph",
  "bnbnb",
  "pyyyy",
  "qcqqc",
  "uulul",
  "uumuu",
  "jjmmm",
  "nqqqq",
  "tqttq",
  "eeeze",
  "mccmm",
  "jjppp",
  "kkkff",
  "xiixx",
  "haaha",
  "jejje",
  "ittii",
  "vdddv",
  "fiiii",
  "nnnnt",
  "pcppp",
  "ggkkg",
  "mmzzz",
  "vvvvn",
  "hrhrr",
  "rrbrb",
  "owwww",
  "vvvvu",
  "atatt",
  "qqbbb",
  "bdddd",
  "nzzzz",
  "qkkqq",
  "nenen",
  "llggl",
  "jwjww",
  "lylll",
  "mmmym",
  "vvccc",
  "errrr",
  "htttt",
  "lttll",
  "hhhhv",
  "pgggp",
  "afaaf",
  "qqqqt",
  "wwwwm",
  "oonoo",
  "ssdsd",
  "kknnn",
  "qqfff",
  "tlttt",
  "svvsv",
  "wrwrw",
  "ookko",
  "rrhhr",
  "avvaa",
  "vrrrr",
  "yyzzy",
  "ppppg",
  "mmhhm",
  "kkkkl",
  "jpjjp",
  "kkkku",
  "spppp",
  "eevve",
  "zzddz",
  "dddxd",
  "wssws",
  "gjjjg",
  "wvvww",
  "fffee",
  "brrbr",
  "uuudd",
  "epeee",
  "gggmm",
  "jkjjk",
  "uufff",
  "ixiii",
  "zddzz",
  "kkkkz",
  "xaxxx",
  "yyyby",
  "ggaag",
  "mgmgg",
  "oqooo",
  "jfjfj",
  "ddwww",
  "yyyyg",
  "iiwiw",
  "lalaa",
  "vveve",
  "vbvbb",
  "ddkdk",
  "tkttt",
  "vhhhh",
  "bjbjb",
  "odddo",
  "dnndn",
  "aoaoo",
  "ggoog",
  "susuu",
  "xxqqx",
  "oococ",
  "nnnnl",
  "ddsds",
  "lllhh",
  "hhghg",
  "zbbzz",
  "ryrrr",
  "ggglg",
  "ooeoo",
  "fffzz",
  "xexxx",
  "ffzzz",
  "fffbf",
  "gagag",
  "qfffq",
  "uuiui",
  "sszss",
  "tztzt",
  "drrrr",
  "tttdd",
  "abbba",
  "hshhs",
  "xqqxx",
  "aaayy",
  "sqsss",
  "memee",
  "cyccy",
  "wwwrw",
  "eewww",
  "fwwww",
  "mmvmm",
  "emeem",
  "zzggz",
  "unnnu",
  "vvvmv",
  "fcfcc",
  "ssssd",
  "oomoo",
  "bbbcb",
  "qqqqg",
  "hheee",
  "ssssh",
  "kokoo",
  "rtrrt",
  "igggi",
  "qxxxx",
  "khhhk",
  "iiiin",
  "kkhkk",
  "xxfff",
  "dtttt",
  "zgzzz",
  "xxjjj",
  "ayaay",
  "akaka",
  "kvvvk",
  "ffzfz",
  "izzzz",
  "iirri",
  "ghhgg",
  "djdjd",
  "kkkko",
  "ncncc",
  "ykkkk",
  "zzccc",
  "lluul",
  "mvvmm",
  "xxxkx",
  "xxexx",
  "xixii",
  "tooot",
  "hpphh",
  "kkuuk",
  "rdrrr",
  "ieeii",
  "obobb",
  "pqqqq",
  "pfpfp",
  "ppkkp",
  "tuuuu",
  "jdddj",
  "ssees",
  "ggkgk",
  "zllll",
  "llolo",
  "oeeeo",
  "cococ",
  "wmmmw",
  "hqhqq",
  "thtth",
  "pptpp",
  "ooyoo",
  "nnppn",
  "hhqhq",
  "gppgp",
  "ooaao",
  "qyyqy",
  "kkkkv",
  "nwnwn",
  "jxxxx",
  "uuwuu",
  "iiiaa",
  "jjjss",
  "oioio",
  "qqqmq",
  "vbvvv",
  "sjjjj",
  "tqtqt",
  "mtmtm",
  "lilll",
  "xxzxx",
  "ktktt",
  "mzzzm",
  "yyyyc",
  "ffvfv",
  "llaal",
  "nnnoo",
  "peeep",
  "xxrrx",
  "eelee",
  "xxbbx",
  "axxxx",
  "awawa",
  "qsssq",
  "iuuii",
  "ukkuu",
  "ukukk",
  "lhlll",
  "glgll",
  "aanaa",
  "eeeem",
  "aaala",
  "ccqqc",
  "skksk",
  "oqqqq",
  "aaajj",
  "ffffq",
  "mmnmm",
  "zcczz",
  "iciic",
  "nnmnm",
  "yywyy",
  "aaaia",
  "drdrr",
  "dddll",
  "vpvpp",
  "ymyyy",
  "hhmhh",
  "mmmmy",
  "gglgg",
  "lhlhh",
  "sjjjs",
  "uvvvv",
  "qqppq",
  "qaqqq",
  "vvvcv",
  "allal",
  "fjffj",
  "cnccn",
  "vwvvv",
  "iiiti",
  "hhhhg",
  "ccctt",
  "mmomo",
  "ddmdd",
  "vvvav",
  "rxxrx",
  "kgkgk",
  "xxxdd",
  "iiifi",
  "gxxgg",
  "iiili",
  "uoooo",
  "arrra",
  "lnnln",
  "kkkee",
  "zdddd",
  "ssddd",
  "cwccw",
  "eecee",
  "jrjrr",
  "rrwww",
  "qbqqq",
  "ninin",
  "wuuuu",
  "jjnjn",
  "ffwfw",
  "qaaqq",
  "bbfff",
  "dddvv",
  "cccca",
  "ffafa",
  "tjjjt",
  "kkkdd",
  "ccvcc",
  "kkkaa",
  "hhhyy",
  "ppwpp",
  "jujuj",
  "aaana",
  "fffcc",
  "kddkd",
  "kkkqq",
  "txxxt",
  "rvvrv",
  "fnfff",
  "gbbbb",
  "tvttv",
  "xddxx",
  "avaav",
  "wwwuw",
  "nnqnq",
  "nunun",
  "wwwtt",
  "llzlz",
  "gcgcg",
  "jqqqj",
  "wwsww",
  "giiii",
  "aaaam",
  "iiaii",
  "uuuss",
  "aahah",
  "kkzzk",
  "dbbbd",
  "fffoo",
  "tjjtt",
  "cxccx",
  "esees",
  "dddud",
  "nffff",
  "yyiii",
  "fffvv",
  "gggog",
  "cffff",
  "aapaa",
  "iivvv",
  "ppwpw",
  "suuus",
  "mmmfm",
  "sssvv",
  "eeese",
  "eeiee",
  "wkkwk",
  "ettte",
  "twtwt",
  "pppph",
  "xlxlx",
  "popoo",
  "hghgg",
  "fjjfj",
  "hhhxx",
  "drdrd",
  "jjmjj",
  "asssa",
  "jjhhj",
  "vvuvv",
  "kkkxk",
  "hqhqh",
  "qqqhq",
  "xfxxx",
  "zqzzz",
  "nbbbb",
  "mggmg",
  "susss",
  "mssms",
  "ttktt",
  "ipppp",
  "sfffs",
  "kooko",
  "ffwff",
  "lqlll",
  "mmxxm",
  "vuuuv",
  "lelll",
  "qccqq",
  "yyysy",
  "zjjzj",
  "rrmrr",
  "viiiv",
  "dddkk",
  "jjjmj",
  "jjyjy",
  "zxxzz",
  "mcmcc",
  "ncncn",
  "ehhhh",
  "ddmmm",
  "ttktk",
  "nnznz",
  "oooou",
  "wwgwg",
  "evevv",
  "nrrnn",
  "upuuu",
  "fffaa",
  "khhkh",
  "zrzrz",
  "onoon",
  "rrxxx",
  "kfkkk",
  "nnvnn",
  "fffuf",
  "ijiii",
  "bbbbz",
  "gggdd",
  "sssnn",
  "vqvqq",
  "ccbbc",
  "eccec",
  "taaat",
  "llllw",
  "gqggg",
  "vvddv",
  "nnnnx",
  "qqqzq",
  "lllgg",
  "txxxx",
  "lklkl",
  "jssjj",
  "iieei",
  "khkkk",
  "acaaa",
  "fpppp",
  "bbxbx",
  "oxxoo",
  "yyyjy",
  "xxxxr",
  "ppppq",
  "mmmhh",
  "knkkk",
  "ptttp",
  "qqqiq",
  "ggjjg",
  "gxgxg",
  "cccnn",
  "nqnnn",
  "akkaa",
  "bjjjj",
  "ppvvp",
  "gssgg",
  "ununn",
  "ahhhh",
  "exxex",
  "jjvvv",
  "iiccc",
  "diidi",
  "xxaxx",
  "jpppj",
  "gggbb",
  "fpffp",
  "lllzl",
  "vvvtt",
  "yyfyf",
  "jccjc",
  "ymmyy",
  "nncnc",
  "gcggc",
  "wuwuw",
  "ttvtv",
  "sassa",
  "oogoo",
  "lllls",
  "yayay",
  "lllkl",
  "gmgmg",
  "rrgrr",
  "vuvuv",
  "aaaww",
  "cggcg",
  "bnnbn",
  "ddddt",
  "joojj",
  "yyaya",
  "ffyfy",
  "pippi",
  "fqfqq",
  "gooog",
  "yykky",
  "mzzzz",
  "jjzzj",
  "vhvvh",
  "kkjkk",
  "yppyp",
  "wyyyy",
  "ooror",
  "cgccc",
  "ppttp",
  "gkkgk",
  "xdxdd",
  "qfqqq",
  "ooeoe",
  "agaaa",
  "cczzz",
  "cchcc",
  "nnlll",
  "ggxgx",
  "ttvvt",
  "oooxo",
  "zaaaa",
  "zmzzz",
  "uququ",
  "nsnnn",
  "deeee",
  "xxgxg",
  "swwws",
  "tzztz",
  "nwwnw",
  "kkddk",
  "wwwwf",
  "lllxl",
  "gqqqq",
  "nqnqq",
  "jcccj",
  "hhiii",
  "xxdxd",
  "mppmm",
  "sssxs",
  "vvvvr",
  "ootot",
  "uhuhh",
  "vvgvg",
  "wwwhh",
  "caaac",
  "jwwjj",
  "cmmmc",
  "wgwww",
  "aaafa",
  "hhshh",
  "edddd",
  "sssaa",
  "eyeyy",
  "lllpp",
  "iikkk",
  "llbbb",
  "eezee",
  "ggxgg",
  "ffflf",
  "ffgff",
  "ttzzt",
  "ptptp",
  "lcllc",
  "ggnnn",
  "bbbnb",
  "afaff",
  "bbobo",
  "bbbrr",
  "wawaw",
  "ccchh",
  "bbbww",
  "zpzzz",
  "dcccd",
  "bbccc",
  "qqmqm",
  "bbpbb",
  "ybbby",
  "qlqqq",
  "yyyww",
  "yymmm",
  "yyyyt",
  "dzzzz",
  "yfyyf",
  "nnmnn",
  "xxxvv",
  "tttll",
  "rfrff",
  "aattt",
  "yfffy",
  "xxvxv",
  "gggrr",
  "iriii",
  "ffmmm",
  "iiyii",
  "trtrt",
  "whwhw",
  "llxxl",
  "cccci",
  "dduuu",
  "tytyt",
  "jjhjh",
  "nnddn",
  "dgggd",
  "nxnnx",
  "jvvvv",
  "tttjj",
  "vvvzz",
  "uyyuu",
  "kmkmk",
  "ikkki",
  "uuoou",
  "mmmzm",
  "xtxtt",
  "ttuut",
  "jjjee",
  "eeegg",
  "fccfc",
  "qqddd",
  "axaaa",
  "mmmms",
  "hhhxh",
  "lglll",
  "ffoof",
  "hoohh",
  "kxxxx",
  "fvvff",
  "wxwwx",
  "nwwnn",
  "ffpfp",
  "dddee",
  "yqqyy",
  "qtttq",
  "offof",
  "ffffj",
  "bqqbb",
  "zzhhh",
  "wrrwr",
  "qqeqe",
  "jjyyy",
  "nnnww",
  "sssbs",
  "eqeqe",
  "mqmmq",
  "okkoo",
  "xwwww",
  "aanan",
  "dfffd",
  "qqpqp",
  "lljll",
  "bhbhb",
  "yeeyy",
  "uuuuv",
  "wwwgg",
  "hhhoo",
  "hrhhr",
  "mmsmm",
  "pllpp",
  "aavav",
  "cpccp",
  "wwwxx",
  "cyccc",
  "sysss",
  "hhhqh",
  "hcchc",
  "ztzzt",
  "ccjjj",
  "jbjbj",
  "hhhhl",
  "bllll",
  "sccsc",
  "llqll",
  "ejeej",
  "sssoo",
  "xzzxx",
  "kktkt",
  "xcccc",
  "sssst",
  "xaaax",
  "guugu",
  "auuaa",
  "rrrkk",
  "ttctt",
  "mmfmm",
  "alall",
  "nrnnr",
  "ccucc",
  "iieie",
  "yyyee",
  "oyyyo",
  "dddxx",
  "wwnwn",
  "vvnvn",
  "iiiih",
  "ssssu",
  "vzzvv",
  "aqqqa",
  "jkjkj",
  "nddnd",
  "ttett",
  "uuudu",
  "uuyyu",
  "aaaba",
  "dddsd",
  "uuunn",
  "zlllz",
  "vvvhh",
  "qbbbb",
  "iilli",
  "dddhh",
  "nmnmm",
  "zuzuu",
  "ttttj",
  "lffff",
  "kkkll",
  "juujj",
  "dddgd",
  "mbbmm",
  "uuueu",
  "xxuuu",
  "iqqqq",
  "eeeve",
  "kkekk",
  "lnlln",
  "lflll",
  "qxxqx",
  "mvmmm",
  "qqtqt",
  "xxexe",
  "tllll",
  "babaa",
  "qqggg",
  "xxyxx",
  "lclcc",
  "lllii",
  "bbbtb",
  "eeezz",
  "zttzt",
  "hbhhh",
  "kkfff",
  "boobb",
  "shssh",
  "pjppp",
  "oeooo",
  "ttqqt",
  "qqqrr",
  "vvvhv",
  "ddcdd",
  "eeeke",
  "tmmtt",
  "dhddh",
  "mmimm",
  "pzzzz",
  "cctcc",
  "lqqqq",
  "lllaa",
  "bbwbb",
  "ejjej",
  "bbabb",
  "kkttk",
  "qfqff",
  "yyyqq",
  "pllpl",
  "rrrvv",
  "lgggl",
  "tuutt",
  "vjvjj",
  "qqyqq",
  "haahh",
  "kukuu",
  "vvqvv",
  "allaa",
  "iwwii",
  "bvbbv",
  "gaaag",
  "zzddd",
  "axxxa",
  "qnnnn",
  "pyypy",
  "sfsfs",
  "rrllr",
  "lnlnn",
  "qmqqq",
  "caaaa",
  "nnnnk",
  "nttnn",
  "xjxjj",
  "meeme",
  "xnxxx",
  "ooogg",
  "tlltl",
  "euuee",
  "sshss",
  "rlrll",
  "awwww",
  "chhhc",
  "qrrqr",
  "sbsbs",
  "nnwwn",
  "xoxxx",
  "qqkqk",
  "ddvvd",
  "ffuff",
  "heeee",
  "gvvgv",
  "rpprp",
  "yyzyz",
  "xxddd",
  "ooiio",
  "lmllm",
  "iriir",
  "ppooo",
  "yyycc",
  "bbfbf",
  "bhhhb",
  "iyiyy",
  "dqqqq",
  "jjjzz",
  "lolol",
  "xxzzx",
  "kdkkd",
  "mmppp",
  "yrryr",
  "huuuh",
  "zdzdd",
  "dhdhh",
  "nnlnl",
  "aayya",
  "eeeye",
  "knnnk",
  "ddydy",
  "eeyee",
  "oooob",
  "zuuuu",
  "znnzn",
  "yuuuu",
  "eiiie",
  "uxuux",
  "xcxcx",
  "ddldd",
  "nnnni",
  "aacac",
  "zzffz",
  "nnnnq",
  "jfjjj",
  "wwhhh",
  "rttrt",
  "zjjjj",
  "hhnhh",
  "cccco",
  "clclc",
  "bcccb",
  "jjjaj",
  "uluul",
  "kbbkb",
  "ddkdd",
  "jjjoo",
  "sxxsx",
  "hhzzh",
  "sssse",
  "hthtt",
  "gdggd",
  "gjggg",
  "yrrry",
  "jojoj",
  "liiil",
  "ddndd",
  "vvzzv",
  "aafaf",
  "qlqll",
  "qqdqq",
  "ttztz",
  "aqqaa",
  "wwwjj",
  "hhthh",
  "hpppp",
  "oouoo",
  "hchhc",
  "yyykk",
  "hkkhk",
  "tpttt",
  "vlvll",
  "uiuii",
  "aaqqq",
  "hhhcc",
  "emeee",
  "oouou",
  "kffkf",
  "iqiqq",
  "lcccl",
  "jyjyj",
  "cdcdd",
  "vvmmm",
  "kknkk",
  "wrwrr",
  "ndnnd",
  "rzrrz",
  "bllbb",
  "pppup",
  "tttgt",
  "ppapp",
  "iirrr",
  "zqzqz",
  "bjbbj",
  "axxax",
  "yyyyw",
  "nhhhn",
  "xvxxx",
  "bbrrb",
  "dudud",
  "mmgmm",
  "gvggv",
  "yyxyx",
  "gzgzg",
  "mmzmm",
  "wwwwi",
  "vkkkv",
  "vvjjv",
  "aaaay",
  "aaasa",
  "xxwxx",
  "gcggg",
  "nnnon",
  "xxxrr",
  "bbgbg",
  "fftff",
  "dzdzd",
  "lzlzz",
  "vvccv",
  "ssuuu",
  "ssssi",
  "xooxx",
  "uuuus",
  "neeee",
  "bbbgb",
  "zzaaz",
  "qjqqj",
  "bbjjj",
  "cccff",
  "jjjdd",
  "ahaha",
  "jdddd",
  "qqlql",
  "mmwmm",
  "wwaaw",
  "kdkdd",
  "zqqqq",
  "uuumu",
  "wnwww",
  "pbbpb",
  "eeene",
  "ozzzz",
  "ccaac",
  "eeeae",
  "hhhhr",
  "boobo",
  "eeqeq",
  "vgggv",
  "fgfgg",
  "lvvlv",
  "iimmm",
  "rrrrs",
  "diidd",
  "mmrmm",
  "ujujj",
  "bbuuu",
  "mvvmv",
  "fccff",
  "keeee",
  "uumum",
  "efeef",
  "mcmcm",
  "qqqpp",
  "pppcc",
  "hhhss",
  "uwuwu",
  "poppp",
  "mmlll",
  "wmmww",
  "vvyyv",
  "qnnqq",
  "kqkkq",
  "vsvvs",
  "yhhyh",
  "kjjkj",
  "vrvvv",
  "hshhh",
  "nfffn",
  "drrdr",
  "exeex",
  "ouuuo",
  "kbbkk",
  "ppccc",
  "hbbbh",
  "ffffn",
  "cicic",
  "ussus",
  "nnmmm",
  "obbbo",
  "llttl",
  "wkwww",
  "ippip",
  "bmmbb",
  "xxxxy",
  "tfftt",
  "tzttt",
  "kkkdk",
  "eeewe",
  "lljlj",
  "iiiig",
  "cccss",
  "bbrrr",
  "krrkr",
  "qququ",
  "ajjaj",
  "ypppy",
  "nnyny",
  "effef",
  "pppaa",
  "nnkkk",
  "yyaay",
  "kkxkk",
  "bbbff",
  "ddsdd",
  "ghggg",
  "wiiiw",
  "bjjbb",
  "wuwuu",
  "fwwff",
  "yyyyo",
  "nnhnn",
  "uupuu",
  "qoooo",
  "lmlmm",
  "kfkfk",
  "hhhwh",
  "ttdtd",
  "hlhhl",
  "yoyoo",
  "wwlll",
  "vvwvv",
  "uttuu",
  "ccccm",
  "uuggu",
  "oofof",
  "mbmmm",
  "lrlll",
  "jmmmm",
  "ggccc",
  "wwwuu",
  "iiiid",
  "fhhfh",
  "sqqss",
  "uuulu",
  "hhoho",
  "utuuu",
  "cooco",
  "ooonn",
  "bqbbq",
  "ififf",
  "rrnrr",
  "tqqqq",
  "lsssl",
  "qoqqo",
  "lnnnl",
  "hhzzz",
  "hheeh",
  "mpmmp",
  "xjxxj",
  "hhhho",
  "bubub",
  "qqqqm",
  "xixxx",
  "yysss",
  "ghhhg",
  "jjjjy",
  "ggvgv",
  "hhhgg",
  "wwzww",
  "jjajj",
  "sfsff",
  "gkkkg",
  "fhhhf",
  "iiooo",
  "jvjjj",
  "kkook",
  "tkktk",
  "qqqjq",
  "llmll",
  "ffmfm",
  "vvjvj",
  "ootto",
  "iqqiq",
  "fzfzf",
  "popop",
  "ffsfs",
  "iiaai",
  "cctct",
  "doood",
  "jbjjj",
  "ttttp",
  "cwwww",
  "xxbbb",
  "uvvuu",
  "ssxxx",
  "bbbdb",
  "ooggo",
  "qwqqw",
  "xxxxb",
  "eweew",
  "uusuu",
  "gghgh",
  "ayaaa",
  "mmmml",
  "xxpxx",
  "ekekk",
  "dwwdd",
  "hhkhk",
  "ggkgg",
  "nppnp",
  "llyyy",
  "xxxmx",
  "fuuuu",
  "ejjjj",
  "okoko",
  "lflfl",
  "jejjj",
  "hzzzz",
  "blblb",
  "zfzff",
  "nmnmn",
  "yyqyy",
  "tgttg",
  "yyddy",
  "iibbi",
  "kgkkg",
  "ggpgg",
  "cckck",
  "sfssf",
  "nnnne",
  "dndnd",
  "azzaa",
  "bgggb",
  "zzaza",
  "eelll",
  "ececc",
  "xrxrr",
  "grgrr",
  "rrrsr",
  "ottot",
  "xxxxo",
  "yyyuu",
  "mmymy",
  "ojojo",
  "rddrr",
  "vvvvy",
  "ppprr",
  "cnncc",
  "dedde",
  "bbgbb",
  "bbmmm",
  "gxxxx",
  "wmwmm",
  "fkffk",
  "nnaan",
  "opppp",
  "wnwnn",
  "sllls",
  "rxxrr",
  "lbbll",
  "sffss",
  "eezzz",
  "hhfff",
  "qllql",
  "dxxxd",
  "hvvhh",
  "xxllx",
  "vvvyy",
  "pppvp",
  "ggggv",
  "gggrg",
  "fffii",
  "nnnnz",
  "pppff",
  "emmee",
  "ffuuu",
  "yysyy",
  "hhwhh",
  "peppe",
  "dvddd",
  "vlvlv",
  "zrzrr",
  "ssspp",
  "uuuou",
  "hhhgh",
  "ppmpm",
  "zyzyz",
  "pgggg",
  "qqvvq",
  "wwwew",
  "ummmm",
  "zmmmm",
  "fssss",
  "oolll",
  "ibbib",
  "cjjjc",
  "gllgg",
  "aooao",
  "ggbbg",
  "myyyy",
  "kkccc",
  "uzzuu",
  "aaaka",
  "deddd",
  "mimii",
  "kssss",
  "fvvfv",
  "zzfzf",
  "ziziz",
  "ttuuu",
  "zhzzh",
  "kkpkk",
  "xlxxx",
  "bbzbz",
  "jjeje",
  "lzlzl",
  "zttzz",
  "wwwoo",
  "zkkkk",
  "vvvkk",
  "aiiai",
  "iibii",
  "uffuu",
  "pqpqp",
  "bbffb",
  "sssts",
  "ixxix",
  "crrrc",
  "ffrrf",
  "uukuk",
  "zwwzz",
  "tyyyy",
  "ffffl",
  "ffefe",
  "uouou",
  "mwwmw",
  "jjsjs",
  "kkkkx",
  "azaaa",
  "ulull",
  "zyyyy",
  "ommmo",
  "uuuku",
  "tvtvt",
  "vqqvq",
  "nnnee",
  "fjjjj",
  "lnlnl",
  "uuuaa",
  "zezez",
  "sooos",
  "gygyy",
  "kkkhk",
  "oowow",
  "fffww",
  "jvvjj",
  "bebbb",
  "gggig",
  "iiici",
  "eeooo",
  "ppypy",
  "xxeex",
  "igiig",
  "xzxzx",
  "jjkjj",
  "ajaaj",
  "wmmwm",
  "uukkk",
  "zwzwz",
  "ukuku",
  "xffff",
  "fddff",
  "xmmxx",
  "nbnbb",
  "vxvxv",
  "mmsss",
  "ododo",
  "ggqqq",
  "qooqo",
  "xoooo",
  "quqqq",
  "ybbbb",
  "khhkk",
  "cgggc",
  "ssssj",
  "rqrqr",
  "vvvvb",
  "rreee",
  "llllb",
  "bqbqq",
  "ullll",
  "iihhh",
  "aagga",
  "ttttc",
  "ftttt",
  "aawwa",
  "ggggz",
  "llhhh",
  "wmwwm",
  "eeeeo",
  "iifif",
  "jjjjl",
  "ooohh",
  "wkwkk",
  "xxxxj",
  "hhmmm",
  "xzxzz",
  "bpppp",
  "jzzzj",
  "sskks",
  "xwxxw",
  "hhzhz",
  "oooaa",
  "ooddd",
  "pkppk",
  "hbhhb",
  "fifff",
  "rrggg",
  "mmmff",
  "qqrrq",
  "ffccf",
  "sswww",
  "nkknn",
  "kggkg",
  "ttwwt",
  "uuuug",
  "kokok",
  "yymmy",
  "zwwww",
  "wwwnn",
  "kttkt",
  "mamma",
  "rpppr",
  "huhuh",
  "xwxxx",
  "xbbbb",
  "vggvg",
  "kvvvv",
  "uyuuu",
  "jjtjj",
  "xvxvx",
  "wwwiw",
  "ookok",
  "ffffv",
  "rrrrf",
  "duddu",
  "ayyaa",
  "eeemm",
  "xxooo",
  "eweee",
  "rrsrs",
  "twwtt",
  "zkzzz",
  "sosos",
  "yyyuy",
  "bkbbk",
  "uuuhu",
  "hhchh",
  "dxdxd",
  "vvvlv",
  "kkbbk",
  "hphph",
  "iiqqq",
  "wjjjw",
  "bbbbu",
  "lzzll",
  "chccc",
  "wuwww",
  "hkkhh",
  "tffff",
  "dpppd",
  "wwwhw",
  "yyyll",
  "xeexx",
  "lllee",
  "ukkku",
  "qqqqp",
  "uyyyu",
  "ddffd",
  "xkkxk",
  "fxfxf",
  "hhhsh",
  "oyyyy",
  "iiuiu",
  "iitii",
  "eoeoo",
  "prrpr",
  "kkkjk",
  "lllcl",
  "cjjcc",
  "rsssr",
  "oioii",
  "qpppq",
  "yyyya",
  "ttttr",
  "rvvrr",
  "aacaa",
  "hohoh",
  "vqqqv",
  "fofoo",
  "rsrrr",
  "aiaaa",
  "rrrqr",
  "tgttt",
  "gcgcc",
  "ppppe",
  "jjjjg",
  "kcccc",
  "pwppw",
  "kaaka",
  "wwssw",
  "hwhwh",
  "ijjij",
  "kykyk",
  "kfkff",
  "iixix",
  "dkkdk",
  "nttnt",
  "fmmfm",
  "keekk",
  "tgggg",
  "ffcfc",
  "tbbtb",
  "ccqcc",
  "xsxxs",
  "aqaaa",
  "qdddd",
  "iinii",
  "jggjj",
  "annnn",
  "lklll",
  "kkykk",
  "eelle",
  "kpkkp",
  "hhwhw",
  "dddtd",
  "qjqqq",
  "geeeg",
  "xexxe",
  "hhihh",
  "uuuuz",
  "ggggn",
  "hhaah",
  "lwwlw",
  "oojoj",
  "zwzzw",
  "ekeek",
  "ddzdz",
  "ggllg",
  "iddid",
  "ffffg",
  "jljjj",
  "jjccc",
  "vbbbb",
  "zffff",
  "weeew",
  "qhqqh",
  "kkkhh",
  "ifiif",
  "qqlll",
  "heheh",
  "fffqq",
  "vtttt",
  "ppppl",
  "ddddr",
  "wwywy",
  "kkkuk",
  "rrtrr",
  "lpplp",
  "hhjjh",
  "ssrrr",
  "xkxkk",
  "wwdwd",
  "wwddd",
  "xxssx",
  "ddaad",
  "nnnnu",
  "dbddb",
  "lolll",
  "xxxxm",
  "zuzzz",
  "iicic",
  "qqggq",
  "bbbcc",
  "rbbrb",
  "ototo",
  "rcccc",
  "yyxyy",
  "ssssf",
  "ydydd",
  "jjmmj",
  "bbbhb",
  "cmcmm",
  "cccxx",
  "ypypy",
  "zyyzz",
  "yuyuy",
  "ieiee",
  "uoouu",
  "jjjjv",
  "ttyty",
  "gsggs",
  "mmqmm",
  "qqhhh",
  "wffww",
  "tooto",
  "bmmmm",
  "zrzzz",
  "eaaaa",
  "bbvbv",
  "eeett",
  "zwwwz",
  "beebe",
  "xxyyx",
  "bbbkk",
  "zzbbz",
  "mmiii",
  "gmmmg",
  "aauuu",
  "fwwwf",
  "ttatt",
  "uuiii",
  "yyydd",
  "kkkkf",
  "drddr",
  "uuuuy",
  "tttxx",
  "yvyyy",
  "qqzqq",
  "jjooo",
  "ccctc",
  "fhfff",
  "ggmmm",
  "hffhf",
  "cecee",
  "hdhdd",
  "uukku",
  "yzyyy",
  "cchhc",
  "deede",
  "gtttt",
  "cvvvc",
  "kwwwk",
  "kkktk",
  "ysyys",
  "zyyyz",
  "xwwxw",
  "ppuuu",
  "tftft",
  "chcch",
  "dydyy",
  "annan",
  "wowow",
  "titit",
  "xooxo",
  "azaza",
  "kxxxk",
  "frrrf",
  "llvvl",
  "tssts",
  "mmmhm",
  "xuuux",
  "jfjff",
  "vfvff",
  "eueuu",
  "eehhh",
  "dfddd",
  "ybbyb",
  "lllkk",
  "vdvdd",
  "kksks",
  "kqkkk",
  "rlllr",
  "arraa",
  "zcccc",
  "yyuyu",
  "tttto",
  "tnttt",
  "ooopo",
  "zppzp",
  "lkkkl",
  "pppip",
  "rrrvr",
  "qvqqq",
  "hhfhh",
  "ggqgg",
  "wffff",
  "cccdd",
  "wwwwt",
  "ssass",
  "ddrdr",
  "dqqqd",
  "wwwgw",
  "lwlll",
  "oxoxo",
  "czczz",
  "irirr",
  "pphpp",
  "iifff",
  "unuun",
  "qwwqq",
  "mmyym",
  "oozoo",
  "tetee",
  "pssps",
  "xxxpx",
  "qqiii",
  "jjxxj",
  "reree",
  "vvmvv",
  "jjfff",
  "uffuf",
  "ssooo",
  "ssaas",
  "kgggk",
  "rqrrr",
  "ckckc",
  "ncnnn",
  "zrrzz",
  "kkppp",
  "xrxrx",
  "eyeee",
  "afafa",
  "bttbb",
  "ssscc",
  "gghhh",
  "kkkke",
  "yyymy",
  "fffmm",
  "ooojj",
  "xxxxf",
  "hhhll",
  "ooomm",
  "ommom",
  "yyyyz",
  "gnggn",
  "mooom",
  "cwcww",
  "iooio",
  "tbttb",
  "ggqgq",
  "aaall",
  "ssese",
  "jsjsj",
  "fnfnf",
  "eveev",
  "ygyyg",
  "qqqsq",
  "ycyyy",
  "vvvxx",
  "vwwvw",
  "vvvgv",
  "euuue",
  "bbbqq",
  "cppcp",
  "xqxxq",
  "iiiei",
  "yuuyu",
  "bzzbb",
  "jkjkk",
  "snnss",
  "yxxyy",
  "jlljl",
  "dydyd",
  "fyyfy",
  "bqqqq",
  "zpzpz",
  "ehhee",
  "lllly",
  "btbbt",
  "fffkk",
  "iivii",
  "nnggg",
  "ttxtt",
  "aaaua",
  "ezzze",
  "gwgww",
  "kkkii",
  "yffyf",
  "xxrxr",
  "tssss",
  "yyyvy",
  "arara",
  "yeeee",
  "zqzzq",
  "qeqqq",
  "azzzz",
  "iiiix",
  "yqqyq",
  "ivivv",
  "ieeei",
  "ccsss",
  "rjjjr",
  "iaaaa",
  "ppptp",
  "vvvww",
  "uuzuz",
  "tetet",
  "ososs",
  "czzzc",
  "vvvva",
  "udduu",
  "zzfff",
  "aaara",
  "ylyyl",
  "aalla",
  "hwwww",
  "uuurr",
  "appaa",
  "wwfwf",
  "fftft",
  "ocooo",
  "wwnww",
  "zuuzz",
  "hrrrh",
  "zozoz",
  "dhddd",
  "ppcpp",
  "gwwwg",
  "qqnqq",
  "tttts",
  "biiib",
  "llsss",
  "ceeec",
  "hhhhc",
  "iiiik",
  "hhooo",
  "nxxnn",
  "ooovv",
  "ccccs",
  "hhyhh",
  "hfhhf",
  "iffii",
  "gmggm",
  "sppps",
  "roorr",
  "ouuoo",
  "bbbee",
  "llnnn",
  "zeeez",
  "ottto",
  "bbubb",
  "babba",
  "nnnny",
  "ccwcc",
  "gogoo",
  "bwbbb",
  "eoeoe",
  "dpdpp",
  "hhlhl",
  "zdzdz",
  "qqooo",
  "rrrra",
  "ppccp",
  "zyzyy",
  "gggff",
  "anaan",
  "cccnc",
  "nnnnf",
  "dwwwd",
  "cttct",
  "ssszz",
  "xxccc",
  "emmem",
  "xhhxh",
  "aaaai",
  "mmxmm",
  "akaaa",
  "qqccc",
  "exexx",
  "kkkyy",
  "assss",
  "ijiij",
  "gjgjg",
  "nnrrn",
  "jwjjw",
  "llllt",
  "mmmoo",
  "gllgl",
  "ldldd",
  "rwrrw",
  "crccr",
  "oooyo",
  "gyggy",
  "eeaee",
  "aqqqq",
  "ljlll",
  "kqkqk",
  "alala",
  "eekee",
  "uuutu",
  "sgggs",
  "xrxxr",
  "dnndd",
  "crrrr",
  "ppppk",
  "oooao",
  "eeiii",
  "yyeee",
  "bbbmm",
  "qqaqq",
  "ppkpp",
  "vvvvo",
  "kkwww",
  "jwwjw",
  "gggkk",
  "qwwqw",
  "nnxnx",
  "csscc",
  "dcddc",
  "qqqbb",
  "hhhhp",
  "raraa",
  "siisi",
  "yyyyp",
  "rtttr",
  "ukkuk",
  "wwwbw",
  "sbsbb",
  "rsrrs",
  "nssss",
  "jxxjj",
  "vpppv",
  "lyyyl",
  "wwjjj",
  "tuttu",
  "ozozz",
  "oooff",
  "bblbb",
  "uxxuu",
  "zbzbz",
  "tjtjj",
  "ysyss",
  "nnnin",
  "nuuun",
  "fdffd",
  "iziiz",
  "iniii",
  "fxffx",
  "bbhhb",
  "vvvxv",
  "jyjjy",
  "jsjjs",
  "issss",
  "dxxdx",
  "ppiip",
  "yygyg",
  "wwtwt",
  "dddod",
  "ffaaa",
  "zoooz",
  "dddds",
  "oohhh",
  "oiiio",
  "uauua",
  "zvvzz",
  "wbwbb",
  "rwwrw",
  "pkkkk",
  "xxxxa",
  "kekek",
  "kkkkm",
  "xxxxu",
  "hrrrr",
  "zkkkz",
  "zzjjz",
  "eyeey",
  "mmmmn",
  "vppvp",
  "yyyaa",
  "aqaqa",
  "iikki",
  "ffqqq",
  "oowoo",
  "dhhdd",
  "ffbfb",
  "dbdbb",
  "rnnrr",
  "lllvv",
  "xxxxh",
  "sbsss",
  "ggggf",
  "cnnnn",
  "tutut",
  "huhuu",
  "ggeeg",
  "vvlll",
  "qssqq",
  "llllu",
  "cjccc",
  "yoyyo",
  "nnnqn",
  "ddccc",
  "odooo",
  "cecce",
  "vnvnv",
  "ujuuj",
  "wiwwi",
  "oonno",
  "ckkcc",
  "uccuu",
  "ahhah",
  "oosso",
  "jjjhh",
  "aaarr",
  "jjjoj",
  "vuvvu",
  "bkkbk",
  "gggxx",
  "tottt",
  "ebeee",
  "ogogo",
  "uuguu",
  "zszsz",
  "tttjt",
  "gsgsg",
  "roroo",
  "llkkl",
  "llxll",
  "hwwhw",
  "jjwww",
  "dzdzz",
  "xxhxh",
  "icicc",
  "oooeo",
  "llloo",
  "gpppg",
  "efeee",
  "tttta",
  "xxoxx",
  "hmhmh",
  "mxxxm",
  "eesss",
  "wrrrw",
  "zzfzz",
  "zizii",
  "iyiiy",
  "nnnng",
  "ennnn",
  "eeece",
  "vvvvc",
  "zozzo",
  "xmmmx",
  "jjijj",
  "mmmvv",
  "fjjjf",
  "llbbl",
  "qqqnq",
  "kvkvv",
  "ffbbb",
  "bvvbb",
  "uunun",
  "cccxc",
  "yiyii",
  "zyyzy",
  "jjllj",
  "siiss",
  "xxxll",
  "sszsz",
  "uuuub",
  "uuruu",
  "mmyyy",
  "eeeuu",
  "nnnnh",
  "vtvtt",
  "ztztt",
  "hvhhv",
  "kxkxx",
  "iiiee",
  "ppipi",
  "jkkjj",
  "lnnnn",
  "bybbb",
  "tmtmm",
  "yyytt",
  "yybby",
  "mmggm",
  "aarra",
  "nnnun",
  "gggzg",
  "nhnhh",
  "ffrrr",
  "jjojo",
  "hhyyh",
  "essse",
  "pyppy",
  "xxlll",
  "vvsvs",
  "uaaau",
  "kkqkk",
  "hkkkh",
  "llvll",
  "kzkzk",
  "vyyvy",
  "jrjjr",
  "nnnnj",
  "vvbbb",
  "fdddf",
  "iimii",
  "dffdd",
  "sssuu",
  "yyypp",
  "wywyw",
  "llllz",
  "ggccg",
  "rrrry",
  "xxzxz",
  "xxjjx",
  "kxkkx",
  "jjjyj",
  "pdppd",
  "ujuuu",
  "euuuu",
  "mmymm",
  "fqfqf",
  "pxpxp",
  "zfffz",
  "ijijj",
  "nnnjj",
  "wwwee",
  "cmmmm",
  "qqjjq",
  "ooqoq",
  "whhhw",
  "xxxyx",
  "wwwii",
  "ebbeb",
  "sssgg",
  "mpppp",
  "ffkkf",
  "nnfnn",
  "mjjjj",
  "lllld",
  "rrryy",
  "wtttt",
  "sttst",
  "qnqnq",
  "ccxxx",
  "ccccp",
  "kkksk",
  "hhehe",
  "tttmm",
  "vvfvf",
  "riiri",
  "cucuc",
  "bbsbb",
  "gglll",
  "dddbd",
  "adaaa",
  "mmkkm",
  "whhww",
  "oooof",
  "ggghh",
  "uuuwu",
  "nllnl",
  "ffjjj",
  "mxmxm",
  "ffffp",
  "zeeee",
  "kkkwk",
  "jtjjt",
  "mnnmn",
  "uurur",
  "llclc",
  "wcwww",
  "effff",
  "oiooi",
  "ttzzz",
  "yymyy",
  "sddds",
  "isisi",
  "rmmrr",
  "lslls",
  "wwpwp",
  "kkxxk",
  "veeev",
  "wwqww",
  "vjjvv",
  "jnjnn",
  "gggee",
  "xxxee",
  "yffff",
  "aiaia",
  "scccc",
  "xxfxx",
  "wwgww",
  "rriri",
  "lppll",
  "nnnnw",
  "rrrhh",
  "nsssn",
  "jjjbb",
  "weeee",
  "uuzzu",
  "pspsp",
  "nnttt",
  "ttppt",
  "jjxjx",
  "dmmmd",
  "innnn",
  "nsnns",
  "znzzz",
  "aamma",
  "fbbbf",
  "ntttn",
  "kkggg",
  "xxxjx",
  "ykkyy",
  "gpggg",
  "dtttd",
  "tthhh",
  "zlzzl",
  "bbbii",
  "bbggb",
  "qgqqq",
  "ojojj",
  "ufuuu",
  "ssmms",
  "kkkki",
  "apaaa",
  "bbqbb",
  "fufff",
  "vcvvc",
  "guugg",
  "oobob",
  "yrrrr",
  "dddoo",
  "xvvvv",
  "wwwow",
  "vfvvv",
  "ebbbb",
  "nlnnn",
  "bnnbb",
  "wvwww",
  "ooxxo",
  "zizzz",
  "ccrrr",
  "vssvs",
  "wtwtw",
  "cclll",
  "fpppf",
  "vvssv",
  "gguuu",
  "yyyty",
  "znnnn",
  "beeeb",
  "dadad",
  "zvvzv",
  "mmsms",
  "wwccc",
  "wtttw",
  "jjjja",
  "lzlll",
  "yykkk",
  "tthht",
  "goggo",
  "eeeek",
  "zhhzz",
  "nnrrr",
  "dhhhh",
  "ixxxi",
  "ccqcq",
  "tyyyt",
  "ccwwc",
  "udddu",
  "ohohh",
  "fdfdf",
  "jjtjt",
  "aaaaj",
  "kaaaa",
  "eneee",
  "vvvmm",
  "bsbss",
  "pappp",
  "zvvvz",
  "eeccc",
  "wwwwl",
  "wwwfw",
  "qqqqn",
  "rdddd",
  "wwyyw",
  "ddddb",
  "gddgd",
  "rpppp",
  "uueue",
  "zjzjz",
  "qqyqy",
  "vvevv",
  "kkkkn",
  "mhmhh",
  "mymyy",
  "ixixx",
  "jkkkj",
  "llccc",
  "ktttt",
  "vvvdd",
  "jmmjj",
  "zssss",
  "faaaf",
  "dssss",
  "wwyyy",
  "ccccz",
  "fcffc",
  "bdbbb",
  "lzzzz",
  "jxjjj",
  "vvvsv",
  "fffrr",
  "fifii",
  "jjkjk",
  "duduu",
  "yyvyy",
  "nnlnn",
  "bsssb",
  "isiii",
  "jxjjx",
  "ydddd",
  "wwwzz",
  "vvvuv",
  "eeeeh",
  "sssls",
  "pnppp",
  "ffffh",
  "rrrii",
  "trrtr",
  "hphhh",
  "wpppp",
  "essss",
  "ppbbp",
  "uuuxx",
  "hhiih",
  "ququu",
  "tttwt",
  "zpzzp",
  "rrffr",
  "mmmum",
  "cxcxx",
  "pppqp",
  "dyyyd",
  "tjjtj",
  "nlnll",
  "whhhh",
  "qqkqq",
  "kkrkk",
  "ssysy",
  "ycyyc",
  "fqqfq",
  "ggugg",
  "txxtx",
  "niini",
  "uuugg",
  "tfttf",
  "cczcc",
  "yycyc",
  "iiivv",
  "uullu",
  "bkkkb",
  "sxxss",
  "rrruu",
  "mammm",
  "hhhnn",
  "sooso",
  "wnnnw",
  "zrrrr",
  "dpddd",
  "owwwo",
  "tytty",
  "llhhl",
  "sssbb",
  "lslsl",
  "ppppc",
  "nnnan",
  "tthth",
  "ccbcc",
  "dkkkk",
  "cgcgg",
  "htthh",
  "vvsvv",
  "pffpp",
  "jtttt",
  "jtttj",
  "yiiii",
  "cqccc",
  "mlmll",
  "tsstt",
  "cqqqq",
  "osooo",
  "eeeii",
  "xxuux",
  "tttst",
  "ammam",
  "nanaa",
  "vbbvv",
  "szzzs",
  "jujju",
  "jjzjj",
  "tataa",
  "wewee",
  "jjjii",
  "ffggg",
  "ykkyk",
  "hnhhh",
  "mvmmv",
  "ucccc",
  "mmmmp",
  "vffvf",
  "ntnnn",
  "wssww",
  "dkdkd",
  "xxyxy",
  "cfffc",
  "atata",
  "kqqqq",
  "ukuuu",
  "ooolo",
  "otoot",
  "hooho",
  "ooobb",
  "rerre",
  "kkkkb",
  "ddggd",
  "cccii",
  "mimim",
  "xkxxx",
  "sscss",
  "babab",
  "vvzzz",
  "fgfff",
  "zozoo",
  "ayaya",
  "yeyee",
  "ppspp",
  "vveev",
  "kssks",
  "drrrd",
  "oosos",
  "yjjyj",
  "zuuzu",
  "bcccc",
  "zpppp",
  "syyss",
  "yxyxx",
  "cncnc",
  "uhuuh",
  "zmzmz",
  "fgfgf",
  "allla",
  "ddwdd",
  "aarrr",
  "kyyyk",
  "xfxfx",
  "szszz",
  "aaapp",
  "uyyyy",
  "hxhhx",
  "pggpg",
  "daada",
  "cciic",
  "qgggg",
  "iiqqi",
  "rgrgg",
  "iiiil",
  "loooo",
  "ppnpp",
  "wwwyy",
  "izziz",
  "ddddz",
  "hhaaa",
  "bbrbb",
  "lfflf",
  "snnns",
  "nnjnn",
  "dedee",
  "uzuzu",
  "iizzi",
  "eeeep",
  "tdtdt",
  "kkbbb",
  "ttnnn",
  "nmnnn",
  "hhddh",
  "ulllu",
  "zzbzb",
  "kkxkx",
  "cczzc",
  "wwfff",
  "ovovo",
  "ttott",
  "gggga",
  "wwmww",
  "ljjlj",
  "hghhh",
  "peeee",
  "bxbbx",
  "lllrl",
  "chhhh",
  "wwwws",
  "rraar",
  "xrrxx",
  "ibiii",
  "svvss",
  "yryrr",
  "oiooo",
  "eeaea",
  "ujjjj",
  "ffqqf",
  "weeww",
  "vpvvv",
  "ecece",
  "vgvvg",
  "zvvvv",
  "yyyyx",
  "oooro",
  "ddddi",
  "vdvdv",
  "bvbvv",
  "kukkk",
  "pcpcp",
  "ssrrs",
  "xxmxx",
  "ddede",
  "ffjjf",
  "hhhnh",
  "mcccc",
  "xfxff",
  "eemem",
  "cccjc",
  "aeaae",
  "vvvyv",
  "qjqjj",
  "ntttt",
  "hhlll",
  "uouuu",
  "ffffi",
  "iggig",
  "wwhhw",
  "meeem",
  "xxxrx",
  "oboob",
  "qqvqv",
  "kyyyy",
  "ggggh",
  "ikikk",
  "iooii",
  "ciicc",
  "pppww",
  "oooox",
  "ljllj",
  "mmmbm",
  "jffjj",
  "xnxnx",
  "uuvuu",
  "fyfyf",
  "bbeee",
  "xxxxw",
  "fuufu",
  "vvttv",
  "kuukk",
  "vvvee",
  "rtrtr",
  "xkkkx",
  "jzzjj",
  "mmmam",
  "aaaoa",
  "lvvll",
  "szsss",
  "xxcxx",
  "nnaaa",
  "ivviv",
  "sqqqq",
  "xxyyy",
  "cjcjc",
  "tttkt",
  "szzss",
  "rrppr",
  "ybyyy",
  "ololl",
  "umuuu",
  "wwxxw",
  "rrarr",
  "iikii",
  "eoooo",
  "oxoox",
  "ekkee",
  "vssvv",
  "gagga",
  "vvxxv",
  "ypppp",
  "lxxxx",
  "qqsqq",
  "wwwpp",
  "ffxff",
  "ffnfn",
  "ddyyd",
  "ccchc",
  "iinnn",
  "qaaaa",
  "lalal",
  "ziizz",
  "mmmsm",
  "wyyww",
  "ffccc",
  "eieie",
  "heehh",
  "gmmgg",
  "iiaia",
  "zxzxz",
  "zppzz",
  "jjjfj",
  "huuhu",
  "rrryr",
  "axaax",
  "pqpqq",
  "yiiyy",
  "krkkk",
  "khkhk",
  "ooool",
  "ttggg",
  "nsnsn",
  "qiqii",
  "nnnpn",
  "bbbwb",
  "qqbbq",
  "qqqxx",
  "ddudu",
  "qcccq",
  "pyypp",
  "ziizi",
  "wsssw",
  "amaaa",
  "ffrfr",
  "ttjjj",
  "ututt",
  "nnnkk",
  "msmsm",
  "rrrlr",
  "yyyyu",
  "nanan",
  "ayyay",
  "xdddx",
  "zoozz",
  "coccc",
  "swwsw",
  "xxsxs",
  "rryyr",
  "wwwwp",
  "ziiiz",
  "ffifi",
  "rrrqq",
  "accac",
  "yytty",
  "yoyoy",
  "ixixi",
  "yyyzz",
  "hdhdh",
  "iiiyi",
  "oohho",
  "cocoo",
  "uquqq",
  "eppee",
  "zcccz",
  "ttvtt",
  "hlhhh",
  "bbppb",
  "cvccv",
  "ttctc",
  "wweee",
  "eeuue",
  "eejjj",
  "ssmss",
  "jjjju",
  "ninii",
  "vsssv",
  "eceee",
  "rrrrl",
  "ififi",
  "nnvvv",
  "jvvjv",
  "uvuvu",
  "cicii",
  "tttyy",
  "iiiiw",
  "aaauu",
  "wnnww",
  "xexee",
  "paapp",
  "vyvvy",
  "akkkk",
  "eeeie",
  "kkrrr",
  "sssff",
  "cwcwc",
  "obooo",
  "spsss",
  "hyhyh",
  "rrrrq",
  "wwzwz",
  "byybb",
  "tmmmm",
  "jiiij",
  "ttptt",
  "ssssa",
  "httht",
  "eiiii",
  "qhqhh",
  "vfvvf",
  "vvttt",
  "xxxii",
  "llllv",
  "vvfff",
  "cceec",
  "sspss",
  "lddll",
  "wwppp",
  "qnqqq",
  "ltltl",
  "kpkpp",
  "xrrxr",
  "eettt",
  "zzczc",
  "hhhhi",
  "mxxxx",
  "nnppp",
  "dqqdq",
  "bzzzz",
  "usuuu",
  "mtmmt",
  "luuuu",
  "llfff",
  "ggggl",
  "kkkmk",
  "sesse",
  "dtddd",
  "eeree",
  "dlddl",
  "oommo",
  "rrrru",
  "rrrxx",
  "qqqoq",
  "lssss",
  "iiisi",
  "giigi",
  "iirir",
  "mmpmm",
  "fyyyf",
  "yuyuu",
  "zziii",
  "pcpcc",
  "smmms",
  "lqllq",
  "eeeue",
  "iidid",
  "ululu",
  "fffll",
  "mommo",
  "ddlll",
  "qwwww",
  "jjjll",
  "ccccq",
  "wwwll",
  "mmllm",
  "ihhhi",
  "ogooo",
  "bwwww",
  "srsss",
  "kccck",
  "ucuuc",
  "uuzuu",
  "pplll",
  "shshh",
  "kfffk",
  "zdzzd",
  "qrqqr",
  "ppfff",
  "hhjjj",
  "rrrmm",
  "ddxxd",
  "hhggh",
  "ttttd",
  "gegeg",
  "xxtxx",
  "ttwtw",
  "ftftt",
  "mymym",
  "sssis",
  "wwlwl",
  "tttty",
  "bssbb",
  "ddccd",
  "asasa",
  "llmmm",
  "yyiyi",
  "kkooo",
  "uuuhh",
  "qtqtq",
  "rqqqr",
  "gogog",
  "iimim",
  "xpxpp",
  "oouuo",
  "iiimi",
  "iiuii",
  "ttdtt",
  "xyxxy",
  "ccccn",
  "jnnnj",
  "rrfrr",
  "kkpkp",
  "qbqqb",
  "iimmi",
  "wkwwk",
  "dzzdd",
  "doodd",
  "mmmmi",
  "iilii",
  "hhuuh",
  "nnvnv",
  "zuzuz",
  "ssssn",
  "ttoto",
  "mllml",
  "ppprp",
  "omomm",
  "aaagg",
  "tmmmt",
  "svsss",
  "bbvvv",
  "rrrar",
  "yyyay",
  "zqzqq",
  "qaqqa",
  "yybbb",
  "ssssk",
  "fffdf",
  "wowwo",
  "eeuee",
  "wssss",
  "pvppp",
  "bbbhh",
  "ssyys",
  "jjnnn",
  "mkmkm",
  "ttbbt",
  "nnnuu",
  "wwhww",
  "ggege",
  "nlnnl",
  "ststs",
  "ihhih",
  "jjjqj",
  "ggegg",
  "gggnn",
  "aaaag",
  "rrzrr",
  "tiitt",
  "cccoc",
  "yyyry",
  "pkpkk",
  "iaiaa",
  "oiioo",
  "uuyuu",
  "daaad",
  "uuluu",
  "rrccr",
  "wwwcc",
  "nunnu",
  "yyygy",
  "zsssz",
  "pbpbb",
  "xjxjx",
  "bbccb",
  "ggrrr",
  "hhphp",
  "ihiih",
  "hgghh",
  "eeaae",
  "exeee",
  "mmmmv",
  "sssrs",
  "ccppp",
  "qqrrr",
  "eqqee",
  "yyyxx",
  "qqcqc",
  "jiiii",
  "oaaoa",
  "eegee",
  "ttxtx",
  "lleel",
  "mmmmu",
  "iiipp",
  "jjdjj",
  "tdttt",
  "vavaa",
  "qqooq",
  "ccxcc",
  "plllp",
  "tttbb",
  "eerer",
  "xxiii",
  "eeehh",
  "mmmqq",
  "eeeej",
  "ljjjj",
  "hhbbh",
  "dgddg",
  "iijii",
  "kpppp",
  "yyyny",
  "sjsss",
  "mnnnn",
  "rrrrk",
  "zwzww",
  "pbppp",
  "pkkpk",
  "pgpgp",
  "ccttt",
  "ojjjj",
  "eezze",
  "jjjjp",
  "ssoos",
  "cckcc",
  "tttqt",
  "gvgvg",
  "egggg",
  "rrrrx",
  "aawaw",
  "ttoot",
  "bwwbw",
  "ssyss",
  "pupup",
  "zzczz",
  "bbnbb",
  "ikkkk",
  "ossso",
  "nfnff",
  "ajaja",
  "dvdvd",
  "yyjjj",
  "ppyyp",
  "ttqtt",
  "yjyyj",
  "ffhhh",
  "fsssf",
  "wwwkw",
  "idddd",
  "ztzzz",
  "yyssy",
  "rrrer",
  "jjqqj",
  "lllxx",
  "llldl",
  "nniii",
  "xxxdx",
  "ffyff",
  "aaaqq",
  "vivii",
  "avvav",
  "ellel",
  "zmmzz",
  "mmumu",
  "ooozz",
  "ddodo",
  "mmmpm",
  "gqqgg",
  "tttut",
  "ssssp",
  "ccici",
  "flffl",
  "cciii",
  "kkckk",
  "qsqqq",
  "uuppp",
  "nunnn",
  "lulul",
  "wwwdd",
  "rrrzz",
  "ujjju",
  "ltlll",
  "bbbll",
  "rrvrr",
  "qqjqq",
  "jhhjh",
  "edeee",
  "bybyb",
  "nnini",
  "ffxfx",
  "iwwwi",
  "wqqww",
  "qxqqq",
  "ccnnn",
  "acaca",
  "eezez",
  "oouuu",
  "yayaa",
  "zqqqz",
  "rrkkr",
  "fxxxf",
  "cccll",
  "gqqgq",
  "ttiii",
  "kzkkk",
  "jwwwj",
  "buubb",
  "mmmmh",
  "llffl",
  "kvkvk",
  "qtqqq",
  "ndddn",
  "iuiiu",
  "zzbbb",
  "wywww",
  "owowo",
  "jjjjt",
  "pnnnn",
  "kkvkv",
  "tttet",
  "eqqeq",
  "ntnnt",
  "bmbmb",
  "juuju",
  "mmjmm",
  "ttlll",
  "zzaaa",
  "nssnn",
  "duudu",
  "rorro",
  "yywyw",
  "hhnnh",
  "nllnn",
  "nffnn",
  "ufuuf",
  "eeses",
  "nnnbn",
  "iiiiy",
  "wfwff",
  "oaaaa",
  "awaaa",
  "hhggg",
  "dwwdw",
  "afaaa",
  "wweew",
  "yyyxy",
  "bgbgg",
  "wiiwi",
  "zgzzg",
  "rriii",
  "bbcbc",
  "nrnnn",
  "vrvvr",
  "ummum",
  "aabaa",
  "iikik",
  "ooqoo",
  "bbobb",
  "tdttd",
  "cacca",
  "bpbpb",
  "ppgpg",
  "yyffy",
  "hhhlh",
  "vtvtv",
  "pfppp",
  "kikki",
  "xnxxn",
  "ttutt",
  "sssdd",
  "xxppp",
  "ududu",
  "wswws",
  "zzhhz",
  "aamam",
  "gsggg",
  "wwbww",
  "hhmhm",
  "ydyyy",
  "cgcgc",
  "yyqqq",
  "nbnbn",
  "ozzoz",
  "xuuxx",
  "nnknk",
  "dddff",
  "jhhhh",
  "pgppg",
  "flflf",
  "rrxxr",
  "ffttf",
  "cpppp",
  "fxxff",
  "bbwbw",
  "snnnn",
  "qqaaq",
  "mmmzz",
  "ijjii",
  "sssxx",
  "wwwvw",
  "elele",
  "hpphp",
  "plpll",
  "ddvdv",
  "lplpl",
  "tttzz",
  "ddodd",
  "qqqqf",
  "rmmmr",
  "kklkl",
  "mxmmm",
  "cccct",
  "aaaad",
  "ieeee",
  "kbkbk",
  "qqqqv",
  "tyyty",
  "gttgt",
  "iyyyi",
  "aalaa",
  "cqqcc",
  "yyyyd",
  "ttttw",
  "ffdff",
  "ynnny",
  "rrsss",
  "ccccl",
  "zzazz",
  "fffyf",
  "ddggg",
  "ngngn",
  "eqeee",
  "leele",
  "zqqzz",
  "mmooo",
  "mmgmg",
  "gsgss",
  "xxxxi",
  "pgpgg",
  "pttpt",
  "zsszs",
  "ooooj",
  "idiii",
  "sxsxx",
  "oowww",
  "bbttt",
  "poopo",
  "dwdww",
  "ycycc",
  "vssss",
  "iiuui",
  "llqqq",
  "jcjjj",
  "nnnhh",
  "cfccc",
  "uqquu",
  "swsss",
  "uuuzz",
  "mwwwm",
  "rrrtt",
  "yyyyn",
  "oggog",
  "totot",
  "fzfff",
  "urruu",
  "yyyyi",
  "xxbxb",
  "ffgfg",
  "ccckc",
  "pplpl",
  "llkll",
  "sspsp",
  "dldll",
  "hjjhh",
  "rrree",
  "sskss",
  "pvvvp",
  "mhmmh",
  "qffqq",
  "yyyfy",
  "ffffk",
  "bbmmb",
  "zwwzw",
  "tttkk",
  "nnann",
  "kffff",
  "ppmmm",
  "hvvvh",
  "vvivi",
  "gjgjj",
  "yyuuu",
  "bbbbt",
  "saass",
  "tpttp",
  "ffpff",
  "rrrhr",
  "dhhdh",
  "gggmg",
  "yyyyv",
  "bzbbb",
  "gtggg",
  "ssvvs",
  "ttsts",
  "ddvvv",
  "uuqqq",
  "vvvpv",
  "reeee",
  "bcbbc",
  "eebbe",
  "oooon",
  "fhfhh",
  "xxjxx",
  "sffff",
  "ndddd",
  "ttttg",
  "pqqpp",
  "hhvhv",
  "aajja",
  "yvyvv",
  "yuyyu",
  "ddbdd",
  "mrrmr",
  "ftfff",
  "nkkkn",
  "opopo",
  "umuum",
  "yyyym",
  "jjjij",
  "awwaa",
  "dmmdm",
  "oaaoo",
  "zzgzg",
  "jjjjb",
  "llldd",
  "vuuvu",
  "uiuui",
  "ccddc",
  "mpmpp",
  "sasaa",
  "deeed",
  "wwcww",
  "jbbbj",
  "offoo",
  "cxxxc",
  "muumm",
  "qhqhq",
  "mlmlm",
  "uvvuv",
  "ooxox",
  "vzvzv",
  "tttnt",
  "uuukk",
  "uuaau",
  "wcccw",
  "rorrr",
  "dddda",
  "aaass",
  "rffrf",
  "rjjrr",
  "upppu",
  "wdwwd",
  "cqcqc",
  "hiihi",
  "ooott",
  "jffff",
  "yylll",
  "dttdd",
  "eesee",
  "rrrgg",
  "ututu",
  "bcbbb",
  "iiiai",
  "oqqoq",
  "ccccd",
  "hmmmh",
  "oodoo",
  "ttttz",
  "njjjn",
  "ssoss",
  "sszzz",
  "araaa",
  "eeecc",
  "gggng",
  "kkkmm",
  "baaba",
  "ulluu",
  "dcccc",
  "rzzzr",
  "xxxcx",
  "iyyyy",
  "fmffm",
  "jjjuj",
  "zzezz",
  "rrrrv",
  "laala",
  "oooov",
  "bbjjb",
  "nddnn",
  "jjjej",
  "rxrxx",
  "kwkww",
  "ezzzz",
  "qdqdd",
  "ipipi",
  "sssys",
  "chhch",
  "pppdd",
  "aaaea",
  "jrrjr",
  "yooyo",
  "hffff",
  "daddd",
  "bssbs",
  "ppdpd",
  "aaaan",
  "vmmmm",
  "bbbbw",
  "cgccg",
  "ccxcx",
  "vbbbv",
  "qqqqz",
  "jjjjz",
  "xcxcc",
  "mrrmm",
  "jwwww",
  "nntnt",
  "cxcxc",
  "ihhhh",
  "xeexe",
  "cdccd",
  "rrcrr",
  "ddmmd",
  "jgggg",
  "ttttb",
  "gpgpg",
  "zxzzz",
  "eetee",
  "jjrjr",
  "dvvvv",
  "pppqq",
  "hhhkk",
  "kklkk",
  "oyoyo",
  "oooko",
  "txtxt",
  "bwbwb",
  "jjjwj",
  "fffkf",
  "rrrtr",
  "vvava",
  "mkmmm",
  "aoooo",
  "aaaak",
  "aeeea",
  "lllle",
  "rrlrl",
  "qqpqq",
  "kpppk",
  "czzcz",
  "iiiij",
  "jjggj",
  "zbbbb",
  "zzdzz",
  "yyooy",
  "aapap",
  "vvvvx",
  "lblbb",
  "vnvvn",
  "nbbnn",
  "hhuhu",
  "hhheh",
  "ususu",
  "dfddf",
  "babbb",
  "vkvvv",
  "wwwpw",
  "ojooj",
  "pppmp",
  "ooaaa",
  "jmmmj",
  "pappa",
  "suuuu",
  "znnnz",
  "kukku",
  "yiiiy",
  "ppeee",
  "rkkkr",
  "llmml",
  "xyxyy",
  "iyyii",
  "avvvv",
  "aaazz",
  "aiiii",
  "fhfhf",
  "aoaoa",
  "oooog",
  "rrrzr",
  "wwkkw",
  "xvxvv",
  "hhbbb",
  "aaaah",
  "naana",
  "vffvv",
  "iiiiv",
  "tttqq",
  "vffff",
  "eeees",
  "oovov",
  "ccgcg",
  "uuuud",
  "rjrrj",
  "yzzyy",
  "ajjaa",
  "gglgl",
  "dkddk",
  "nhnhn",
  "llyyl",
  "jdjdd",
  "ddidd",
  "ummuu",
  "mcmmm",
  "auuau",
  "ujjuj",
  "uzzzz",
  "hmhhh",
  "aayay",
  "qqqqx",
  "nllll",
  "ppvvv",
  "syyyy",
  "rurrr",
  "bbzzz",
  "ddydd",
  "ryrry",
  "jjiii",
  "ckccc",
  "iihhi",
  "mmiim",
  "ssbss",
  "ooowo",
  "rruur",
  "mfmmf",
  "vuvvv",
  "itttt",
  "iffif",
  "ozzzo",
  "olooo",
  "cuucu",
  "ffffw",
  "qkkkk",
  "cclcl",
  "vvkkv",
  "hwwwh",
  "nzznn",
  "eeejj",
  "lzzzl",
  "tteet",
  "jjjje",
  "xxeee",
  "ijjjj",
  "vkvvk",
  "eegeg",
  "qqqqd",
  "nknkk",
  "bxxxx",
  "mmmii",
  "ppsss",
  "kdkkk",
  "uuuun",
  "gbbgg",
  "uuuuc",
  "kkkkg",
  "uuuua",
  "gwwww",
  "izzzi",
  "aaaav",
  "rrrrd",
  "nggng",
  "xvvxx",
  "ddttt",
  "auaaa",
  "ajjjj",
  "cclcc",
  "vvlvl",
  "llllf",
  "aaaya",
  "jjiji",
  "pppuu",
  "maaaa",
  "ynyny",
  "jojjj",
  "uudud",
  "ggiig",
  "ywyyy",
  "zpppz",
  "mccmc",
  "wwxwx",
  "uuuul",
  "buuub",
  "oppoo",
  "eeeoe",
  "zuzzu",
  "ootoo",
  "lllyy",
  "vvaav",
  "gsssg",
  "jbbjb",
  "jkjjj",
  "aazaz",
  "cssss",
  "vpvvp",
  "jjjtt",
  "skkss",
  "uzuuu",
  "lmmmm",
  "oonnn",
  "llddd",
  "ssoso",
  "hyhyy",
  "nnnrr",
  "qjjjq",
  "hnhhn",
  "aaaaz",
  "nonoo",
  "xrrrr",
  "jjjff",
  "ccfcf",
  "bubbb",
  "oaoaa",
  "dffff",
  "ccgcc",
  "xxxlx",
  "bbmbm",
  "hhhvv",
  "rrrmr",
  "nnzzn",
  "uvvvu",
  "iiibb",
  "nnnnr",
  "qzzqq",
  "mmlmm",
  "yyyey",
  "bkbkb",
  "vvhhh",
  "mrrrr",
  "lllww",
  "xaaxa",
  "djjjd",
  "lrlrl",
  "ikkik",
  "zbzbb",
  "nnznn",
  "rmmmm",
  "araar",
  "cccce",
  "reeer",
  "ibibb",
  "zuuuz",
  "hahha",
  "xgggg",
  "nsnss",
  "tttft",
  "yyyzy",
  "ssccc",
  "twttw",
  "zlzzz",
  "mgmmm",
  "pppvv",
  "ememm",
  "cccck",
  "ffttt",
  "hohoo",
  "yyyyl",
  "aaaap",
  "nnncc",
  "rrrff",
  "xxxxk",
  "hnhnn",
  "kttkk",
  "jjyjj",
  "ellll",
  "qrqrr",
  "vevve",
  "etete",
  "mmmqm",
  "uuxxx",
  "yyynn",
  "fofof",
  "dddss",
  "xxtxt",
  "iiimm",
  "etett",
  "ggkkk",
  "eeedd",
  "hkkkk",
  "aakka",
  "aamaa",
  "uussu",
  "wzzwz",
  "apapp",
  "gkkkk",
  "ssssr",
  "omomo",
  "zezzz",
  "zrzzr",
  "bibii",
  "okooo",
  "qbbqq",
  "rrkrr",
  "hhhbb",
  "uuuuo",
  "nwnww",
  "znznz",
  "uxxux",
  "sxsss",
  "vvfvv",
  "ggjjj",
  "eweww",
  "quqqu",
  "guguu",
  "rrrww",
  "issis",
  "hwwhh",
  "umumu",
  "eaaee",
  "rreer",
  "gngng",
  "ryyyy",
  "hhnnn",
  "lrllr",
  "nnnpp",
  "mmmmt",
  "sszzs",
  "fwffw",
  "mmnnn",
  "ctttt",
  "ayyya",
  "occoo",
  "cccee",
  "uuuup",
  "luull",
  "xxwxw",
  "caaca",
  "vvrvv",
  "vvvvj",
  "wcwwc",
  "bbxxx",
  "hllhh",
  "lyyyy",
  "bbbib",
  "dddqd",
  "ggsgg",
  "jhhjj",
  "oollo",
  "bbbbv",
  "qkkkq",
  "yyvyv",
  "jjqjq",
  "dllll",
  "myymm",
  "yxxxy",
  "riirr",
  "uuggg",
  "xxxbx",
  "aaraa",
  "lflff",
  "aaxaa",
  "xbbbx",
  "ssssl",
  "vvcvc",
  "vwwwv",
  "eeeaa",
  "hhhdd",
  "iuuui",
  "maama",
  "xnxnn",
  "mmmmo",
  "gpgpp",
  "ftfft",
  "jwjwj",
  "czczc",
  "kkiii",
  "hhbhb",
  "eeeje",
  "qrqrq",
  "ebbbe",
  "djddd",
  "uxxxx",
  "hhhhq",
  "bbbfb",
  "mttmt",
  "ffkfk",
  "iijji",
  "qqhqq",
  "jqjqq",
  "kkkik",
  "sllll",
  "cscss",
  "nnbnb",
  "fllfl",
  "nnnns",
  "lvvvl",
  "ygyyy",
  "memem",
  "ahhaa",
  "fjfjj",
  "xxwww",
  "piipp",
  "ggwww",
  "dodoo",
  "loloo",
  "jbbbb",
  "wwwwr",
  "llrrl",
  "gggqg",
  "leell",
  "owwoo",
  "hhhii",
  "pkkpp",
  "vvjvv",
  "liill",
  "qqaaa",
  "ywyyw",
  "xxaaa",
  "qjjqq",
  "vdvvv",
  "xdxxd",
  "oxoxx",
  "pvpvp",
  "jjjjh",
  "cpcpc",
  "dccdc",
  "vjjjv",
  "aaaoo",
  "totoo",
  "zziiz",
  "dddzz",
  "ovooo",
  "xxhxx",
  "miimi",
  "pssss",
  "wjjww",
  "ennne",
  "kkllk",
  "qtqqt",
  "ffwww",
  "toooo",
  "iiggg",
  "ccccj",
  "uiiiu",
  "hxhxx",
  "lllqq",
  "gaggg",
  "qqqqc",
  "bbbbs",
  "momoo",
  "sussu",
  "lollo",
  "jggjg",
  "mrrrm",
  "ekkek",
  "ejeje",
  "mmmpp",
  "qllqq",
  "ssess",
  "ymymy",
  "jtjtt",
  "yyiiy",
  "ctttc",
  "uuuux",
  "ziiii",
  "wbbwb",
  "yyyly",
  "ccaca",
  "wbwww",
  "aaabb",
  "kckcc",
  "eedde",
  "qqzzz",
  "dmddd",
  "eedee",
  "rrrpp",
  "ssksk",
  "oooom",
  "dddld",
  "xxxxg",
  "rrjjr",
  "bibib",
  "syysy",
  "dsssd",
  "tcttc",
  "iviii",
  "qqcqq",
  "occcc",
  "gdddd",
  "vavvv",
  "ssnsn",
  "zxzzx",
  "uurru",
  "btbtt",
  "duuud",
  "ookkk",
  "jajja",
  "yymym",
  "fsfss",
  "wwbbb",
  "yhyhh",
  "kwwkk",
  "faffa",
  "gdddg",
  "kkrkr",
  "llblb",
  "yyuuy",
  "mmmmx",
  "siiii",
  "wfffw",
  "qqgqq",
  "zaaaz",
  "lylly",
  "yayya",
  "zqqzq",
  "ccccg",
  "ynnyy",
  "jaaja",
  "kkkak",
  "iiwww",
  "juuuu",
  "vvdvd",
  "hhhhw",
  "rbbbb",
  "nnnaa",
  "uuumm",
  "uguug",
  "zyzzy",
  "ynnyn",
  "nmnnm",
  "mmmrm",
  "kdddk",
  "xxxkk",
  "ccvvv",
  "qrrqq",
  "rrurr",
  "gbggg",
  "rprrr",
  "pypyy",
  "nnnjn",
  "bbbzb",
  "bbibb",
  "bbxxb",
  "ssfsf",
  "jrrrr",
  "iyiyi",
  "rwrwr",
  "hkhhh",
  "gggqq",
  "pdpdd",
  "zxxxx",
  "kvvkv",
  "tgtgt",
  "nnnyn",
  "rrddd",
  "kkkbk",
  "zsszz",
  "fffcf",
  "dbbbb",
  "ouuou",
  "qqiqq",
  "uuppu",
  "cccvv",
  "hhhhd",
  "kookk",
  "uujjj",
  "ggmgg",
  "llplp",
  "eeeeg",
  "ddddu",
  "ccccu",
  "qdddq",
  "nynnn",
  "shsss",
  "cfcff",
  "nznzz",
  "gnnng",
  "ezzez",
  "qqqqj",
  "tttct",
  "oddoo",
  "vvhvv",
  "vllll",
  "sdddd",
  "uaaaa",
  "rrprr",
  "ttooo",
  "ouoou",
  "pllll",
  "dldld",
  "wwkkk",
  "llall",
  "jqqjq",
  "aazza",
  "hhhch",
  "oggoo",
  "qhqqq",
  "ktttk",
  "kkkcc",
  "vvvbv",
  "vddvd",
  "rbbrr",
  "mmmme",
  "aasss",
  "vvpvv",
  "dddcc",
  "qqqfq",
  "hlllh",
  "fffef",
  "innii",
  "rqrrq",
  "rrrir",
  "gggjg",
  "ssses",
  "ppbpb",
  "hyyhy",
  "lilli",
  "wggwg",
  "yrryy",
  "rrkkk",
  "mmmmq",
  "sksks",
  "ddiid",
  "wwrrr",
  "dddmm",
  "aajjj",
  "mmmnm",
  "rhrrr",
  "ccfff",
  "hhuhh",
  "ajjja",
  "cccfc",
  "ddqqq",
  "csccs",
  "ftttf",
  "vqvvv",
  "ffvff",
  "bbbbx",
  "vveee",
  "qyqqy",
  "xqxqx",
  "yyjyj",
  "tdddt",
  "mmmmg",
  "bbiib",
  "svssv",
  "alaal",
  "jffjf",
  "mxmmx",
  "uuduu",
  "occoc",
  "ssxss",
  "nnbbn",
  "ppjjj",
  "oomom",
  "nnyyy",
  "mmmma",
  "puuuu",
  "xxxzx",
  "nnkkn",
  "bbbss",
  "oozoz",
  "vvkvv",
  "gssss",
  "qccqc",
  "lullu",
  "iaaai",
  "ooooh",
  "ddppp",
  "ohhhh",
  "qqddq",
  "txttt",
  "duuuu",
  "vwvww",
  "swwss",
  "attaa",
  "veeve",
  "zzhzh",
  "yeyye",
  "lllal",
  "xxiix",
  "uurrr",
  "hhrhh",
  "izizz",
  "paaaa",
  "zrrzr",
  "jppjp",
  "uaauu",
  "uuyyy",
  "rfffr",
  "xiixi",
  "gggjj",
  "uupup",
  "fyyyy",
  "gkggg",
  "mamaa",
  "uouuo",
  "rrrrg",
  "gggoo",
  "ukkkk",
  "lddld",
  "zzhzz",
  "cdcdc",
  "jjnnj",
  "adddd",
  "iieee",
  "eeffe",
  "odood",
  "mkkmk",
  "ccooc",
  "tttyt",
  "uujuj",
  "xccxx",
  "dsdss",
  "zjzzj",
  "nccnn",
  "oyooy",
  "aafff",
  "xaaxx",
  "ppjjp",
  "zzjjj",
  "xbxbb",
  "ggggi",
  "ppnnp",
  "mmbbb",
  "jnnjj",
  "wwwwz",
  "rssrr",
  "uhhhh",
  "hhqqh",
  "eseee",
  "nnuuu",
  "xpxxx",
  "cpccc",
  "lhllh",
  "tmtmt",
  "xqqqq",
  "ezeze",
  "olloo",
  "nnnff",
  "qqxxx",
  "qsqqs",
  "jjljj",
  "ttnnt",
  "ggogg",
  "eeeer",
  "cccqq",
  "iixxx",
  "jjjgg",
  "sssqq",
  "ttsss",
  "bibbi",
  "uufuu",
  "iwiwi",
  "vqvvq",
  "sosoo",
  "vvnnn",
  "zffzf",
  "meemm",
  "ksssk",
  "hhjhj",
  "rrvrv",
  "uuunu",
  "vvnvv",
  "yssyy",
  "wwaaa",
  "ryryy",
  "siiis",
  "mkkmm",
  "iiiss",
  "wuwwu",
  "vvwww",
  "zzggg",
  "bbbtt",
  "aaxax",
  "jyjjj",
  "kkkjj",
  "rrrrj",
  "rffrr",
  "dbddd",
  "fflll",
  "jwjjj",
  "uaaua",
  "hkhhk",
  "lccll",
  "gbbgb",
  "ipiip",
  "zznzz",
  "uujuu",
  "yyeyy",
  "xooox",
  "zzqzq",
  "krrrr",
  "lliil",
  "geeee",
  "vvvoo",
  "yttyt",
  "gzgzz",
  "ssiss",
  "laaal",
  "hrhrh",
  "gyygg",
  "jjejj",
  "zzozz",
  "zzqqz",
  "ktkkk",
  "xmxxm",
  "rrerr",
  "ggtgg",
  "aatat",
  "yyayy",
  "ggdgd",
  "cccqc",
  "kjkkk",
  "zzlzz",
  "uvuvv",
  "crrcr",
  "rxrxr",
  "lvlll",
  "zzkkz",
  "xlxll",
  "zznnn",
  "uhuuu",
  "llsll",
  "zzuuu",
  "qtttt",
  "wowww",
  "uuwwu",
  "zztzz",
  "cckkc",
  "zzjzj",
  "apapa",
  "nnooo",
  "baaaa",
  "yyyrr",
  "vllvl",
  "orrro",
  "cxccc",
  "ydddy",
  "xxkxk",
  "omooo",
  "kdddd",
  "vvvaa",
  "bbbub",
  "bwbbw",
  "wwwwk",
  "kkkck",
  "mmumm",
  "yjjyy",
  "zzsss",
  "nnnnv",
  "mmggg",
  "vvovo",
  "assaa",
  "jiiji",
  "kkfkk",
  "ffwwf",
  "zzuzz",
  "ezeez",
  "ffhff",
  "flfll",
  "erere",
  "zzttt",
  "zzozo",
  "nnynn",
  "zzttz",
  "uuvvu",
  "bsbbs",
  "nvnvv",
  "eyeye",
  "dtdtt",
  "yyyhy",
  "vhhvh",
  "oaoao",
  "kkdkk",
  "sssks",
  "tlllt",
  "rrprp",
  "hhhhe",
  "zzkzk",
  "wwccw",
  "bbvvb",
  "ttyyt",
  "ttjjt",
  "mmemm",
  "lcclc",
  "auaua",
  "tttbt",
  "jssjs",
  "qqzzq",
  "unnun",
  "zzxzx",
  "xxvvx",
  "rrrrp",
  "zzuzu",
  "wiiww",
  "jjqjj",
  "pkkkp",
  "avaaa",
  "yccyy",
  "zzkzz",
  "zzszs",
  "tttat",
  "vvxvx",
  "rnnrn",
  "bbbeb",
  "wjwjw",
  "tttvv",
  "ooooe",
  "xdxdx",
  "eeqee",
  "yeyyy",
  "nnnnm",
  "xxvvv",
  "kcckc",
  "aaawa",
  "kykyy",
  "nznzn",
  "rrrrm",
  "uuccu",
  "xxwwx",
  "cchch",
  "rrrre",
  "lwwww",
  "xbxxx",
  "zzxxx",
  "rrrrt",
  "ititi",
  "cccvc",
  "aaoaa",
  "lllol",
  "hhhqq",
  "gaaaa",
  "lrrll",
  "vvmvm",
  "nnnno",
  "mimmm",
  "ccooo",
  "nnnnp",
  "kiiki",
  "uggug",
  "tttvt",
  "fffss",
  "jbjjb",
  "okkok",
  "mjmjm",
  "fkfkk",
  "zzrrz",
  "kkzkk",
  "jyyjy",
  "nnnvv",
  "wwrwr",
  "eqeeq",
  "oooow",
  "gxxgx",
  "nqqnn",
  "hhhee",
  "sffsf",
  "nnnzn",
  "zrrrz",
  "gvvgg",
  "zzwzw",
  "cppcc",
  "dddnn",
  "vyvyv",
  "zzpzz",
  "lzzlz",
  "mmvvv",
  "fmmff",
  "uhhuu",
  "ddddv",
  "xddxd",
  "mmmuu",
  "kmmmk",
  "bvvbv",
  "yqyqq",
  "kkktt",
  "xqxqq",
  "jjjaa",
  "jzzzz",
  "gegge",
  "qqqyq",
  "eefee",
  "oooio",
  "cccuc",
  "mffmm",
  "owwow",
  "vviii",
  "aaiia",
  "nnnyy",
  "ttwtt",
  "oooto",
  "vvovv",
  "hhhff",
  "sslss",
  "mummm",
  "rrrrc",
  "zzqzz",
  "ctcct",
  "yooyy",
  "xiiii",
  "bbmbb",
  "lfllf",
  "ddyyy",
  "ssscs",
  "hahaa",
  "ffyyy",
  "egeeg",
  "zzvvz",
  "zzwzz",
  "xvvxv",
  "hhxxh",
  "cccuu",
  "kzzzz",
  "nssns",
  "iwiii",
  "ppplp",
  "ccycy",
  "ccssc",
  "xxxff",
  "kkrrk",
  "nennn",
  "wpwpw",
  "iiiww",
  "dddrd",
  "ysyyy",
  "ipipp",
  "loool",
  "qqqdq",
  "nnccn",
  "kjkkj",
  "ovvov",
  "sasas",
  "ossss",
  "ththt",
  "ooqqo",
  "hhghh",
  "bbyyy",
  "ixxii",
  "niinn",
  "oyoyy",
  "dzddd",
  "ididi",
  "cccpc",
  "eddee",
  "zzzoz",
  "xggxg",
  "zzzrz",
  "qvvvq",
  "slsls",
  "cuccc",
  "vevev",
  "zzpzp",
  "pfpff",
  "voooo",
  "hqqqq",
  "dddtt",
  "nknkn",
  "tatta",
  "llxxx",
  "qqqpq",
  "oooii",
  "uuwww",
  "qvqqv",
  "zzzrr",
  "nnwww",
  "kzzkk",
  "zzzjj",
  "aaqaa",
  "zzziz",
  "ddddc",
  "zzssz",
  "kpkpk",
  "hhvvh",
  "xqqqx",
  "kkggk",
  "zzzkz",
  "zzqqq",
  "qgqgq",
  "ueuuu",
  "saasa",
  "fffmf",
  "okokk",
  "ppddd",
  "zzrrr",
  "vvbvb",
  "zzzii",
  "ppiii",
  "iyyiy",
  "rrbbr",
  "dxddd",
  "ddqdd",
  "xxggg",
  "tttxt",
  "ncccc",
  "cccjj",
  "cccwc",
  "zzzaa",
  "ggugu",
  "zzrzr",
  "ppbpp",
  "mamam",
  "ggeee",
  "cycyc",
  "lvlvl",
  "aahaa",
  "eeqqq",
  "mwmmm",
  "mbbbb",
  "ooffo",
  "wawwa",
  "qqqqk",
  "qqqdd",
  "ppppr",
  "jjzzz",
  "dddwd",
  "gfggf",
  "wwoww",
  "gkgkg",
  "iiwii",
  "vvxvv",
  "kkkrk",
  "cckkk",
  "ruuru",
  "zzzmm",
  "zzzhz",
  "xoxoo",
  "tzzzz",
  "ccggg",
  "qqqjj",
  "zzzuz",
  "zzzlz",
  "dxxdd",
  "iniin",
  "dmdmm",
  "sllsl",
  "mmxmx",
  "zzuuz",
  "fbbbb",
  "bbkkb",
  "hhhhs",
  "ozzoo",
  "mmmmk",
  "uuvvv",
  "bbebe",
  "waawa",
  "zzzez",
  "mkmmk",
  "zznzn",
  "xxxsx",
  "zzzff",
  "dqqdd",
  "rrwrr",
  "kjkjj",
  "yyhhh",
  "mmwww",
  "heeeh",
  "yssss",
  "ssssw",
  "iippi",
  "dmdmd",
  "rrmmr",
  "dmmmm",
  "zzwww",
  "kbkkb",
  "iittt",
  "mmmss",
  "wdwww",
  "nnene",
  "mmmlm",
  "hhhuh",
  "ossoo",
  "qiqqq",
  "zzrzz",
  "crrcc",
  "nmmnm",
  "rurru",
  "fgffg",
  "ododd",
  "llool",
  "zzzfz",
  "nffnf",
  "zzmzz",
  "rrqqr",
  "iaiii",
  "zzzss",
  "aaaxx",
  "hhhht",
  "zzzdz",
  "wwwsw",
  "fooff",
  "qqssq",
  "rqqrq",
  "zzzoo",
  "vvvvd",
  "qqtqq",
  "duudd",
  "bmbmm",
  "sssjs",
  "wwiww",
  "rprrp",
  "mumum",
  "wwiwi",
  "djddj",
  "lmmlm",
  "pnpnn",
  "mmmtt",
  "oeoeo",
  "hhkhh",
  "huuuu",
  "jjpjp",
  "vvvve",
  "iisss",
  "rrere",
  "ffkff",
  "aammm",
  "saaas",
  "ljljj",
  "sqqqs",
  "scccs",
  "mkkkk",
  "wwttw",
  "zzzgg",
  "rrrss",
  "qvvvv",
  "zzxxz",
  "zzzsz",
  "vvvtv",
  "ueeuu",
  "yzyzz",
  "zzzbz",
  "ivvvv",
  "bbttb",
  "bzbbz",
  "pppnn",
  "lslss",
  "rvvvv",
  "hhkkk",
  "xnnnx",
  "dgggg",
  "zzlll",
  "vvvvg",
  "ilill",
  "zzznz",
  "vtttv",
  "ppptt",
  "luuul",
  "zzzgz",
  "caccc",
  "zzzpp",
  "ppvpp",
  "isiis",
  "wwwqw",
  "grrrr",
  "rooro",
  "oooee",
  "hcccc",
  "qquqq",
  "zzmmz",
  "ooodo",
  "xxxtx",
  "lylyl",
  "sslll",
  "zzvzv",
  "qqqgq",
  "aayyy",
  "wllll",
  "zztzt",
  "rwwrr",
  "lwlwl",
  "wlllw",
  "soooo",
  "kkkvv",
  "ttbbb",
  "dyydd",
  "zzppz",
  "wdwdd",
  "ooboo",
  "bbyyb",
  "ttmtm",
  "oyooo",
  "yhyyh",
  "vvvpp",
  "kxkkk",
  "shhsh",
  "zzkkk",
  "xxxuu",
  "ooccc",
  "hyyyy",
  "zzzdd",
  "zzzpz",
  "cyycc",
  "xxxzz",
  "qqqss",
  "wpwww",
  "qqqqi",
  "zzxzz",
  "huuhh",
  "pzzpz",
  "oocco",
  "zzznn",
  "llccl",
  "vvvvf",
  "zzyzz",
  "zpzpp",
  "zzzmz",
  "mmmyy",
  "sssds",
  "zzszz",
  "hhhzh",
  "qqmmm",
  "zzzcc",
  "snsnn",
  "ththh",
  "xxxyy",
  "mnnmm",
  "uugug",
  "fufuu",
  "vvvqv",
  "zzppp",
  "kkkpk",
  "zzzee",
  "cucuu",
  "hhhzz",
  "xeeex",
  "mkkkm",
  "mmmll",
  "fwfff",
  "mmmww",
  "uutut",
  "pppop",
  "mqqmq",
  "hhaha",
  "hdddh",
  "vvvov",
  "zzzhh",
  "wwjjw",
  "ebbee",
  "qqqcq",
  "pdpdp",
  "zzlzl",
  "zzyyy",
  "pppoo",
  "uubbb",
  "sssso",
  "jeeej",
  "ovvvv",
  "zzvvv",
  "mxxmx",
  "yggyy",
  "jpjpp",
  "zzzqq",
  "mmmkk",
  "ssssq",
  "wdwdw",
  "dbbdb",
  "zzzjz",
  "dppdp",
  "ttrtr",
  "pppmm",
  "zzzqz",
  "ooozo",
  "kkkqk",
  "zzzzt",
  "rrjrj",
  "vvvvh",
  "whwhh",
  "kikkk",
  "zzzkk",
  "uwuuw",
  "zznnz",
  "sslls",
  "zggzg",
  "uyyuy",
  "ppddp",
  "ippii",
  "dkdkk",
  "exxxx",
  "pjjpp",
  "rzrzz",
  "vvgvv",
  "zzzwz",
  "zzzvz",
  "zzzxx",
  "yyggy",
  "tjttj",
  "lbblb",
  "koooo",
  "paaap",
  "zzjzz",
  "ggtgt",
  "zzwwz",
  "bsbsb",
  "zzmzm",
  "zzyyz",
  "gtgtg",
  "emmmm",
  "qdqdq",
  "zzllz",
  "emmme",
  "wccwc",
  "ztztz",
  "zzmmm",
  "ovvvo",
  "yzyyz",
  "xoxox",
  "nvvnn",
  "vvvvi",
  "vavva",
  "otooo",
  "nllln",
  "nvvnv",
  "ssstt",
  "zczzc",
  "jhjjh",
  "acaac",
  "zzzxz",
  "epeep",
  "zzzvv",
  "zzzzd",
  "hhhhz",
  "zzooo",
  "zzztt",
  "uuffu",
  "pzzpp",
  "iggii",
  "zzzzs",
  "zzzzn",
  "xxzzz",
  "bggbg",
  "zzzzx",
  "mrmrr",
  "ssmmm",
  "iiiqq",
  "gddgg",
  "mmmmd",
  "zzgzz",
  "yyzyy",
  "wswww",
  "wiwww",
  "zzzzf",
  "jeeee",
  "zzzww",
  "zzztz",
  "zzzzy",
  "zzzza",
  "gaaga",
  "zzzzi",
  "jjfjf",
  "zzzcz",
  "fllll",
  "ugguu",
  "zggzz",
  "bxbxb",
  "rrzzz",
  "jjjjx",
  "zzzbb",
  "zzzzl",
  "zzzll",
  "hhhvh",
  "zzzzu",
  "attta",
  "zzzyz",
  "zzyzy",
  "wwsws",
  "zzzzq",
  "ttrrt",
  "ooorr",
  "zzzuu",
  "zzzzw",
  "ooopp",
  "zzzzh",
  "oookk",
  "hhhhf",
  "iitit",
  "zzizz",
  "zzizi",
  "zzvzz",
  "zzzzg",
  "zzzzv",
  "zzzzp",
  "qjqjq",
  "lfffl",
  "zfzfz",
  "zzooz",
  "zzzzj",
  "zzzze",
  "zzzyy",
  "uuubu",
  "lgggg",
  "hhhrr",
  "hhhhk",
  "hgghg",
  "zzzzb",
  "vvaaa",
  "zzzzr",
  "hhhhm",
  "zzzzc",
  "hhehh",
  "wcwcc",
  "zzzzk",
  "zczcc",
  "zzzaz",
  "zzzzm",
  "zzzzo",
  "kuuuu",
  "nefnf",
  "asfff",
  "dmxdd",
  "lptpt",
  "bbajb",
  "iviiq",
  "khkjk",
  "ffeep",
  "ivivk",
  "ifddd",
  "bcfcf",
  "ffpip",
  "lppps",
  "kkmll",
  "bbrry",
  "hurru",
  "cnkkk",
  "iiitu",
  "agvgg",
  "mggms",
  "bbttl",
  "lpppx",
  "dddqi",
  "ivivl",
  "husuu",
  "ffmmr",
  "akkai",
  "khkkv",
  "hobho",
  "ksiss",
  "fzzjj",
  "huulu",
  "ffouo",
  "fxgxx",
  "arror",
  "aufau",
  "hcxxc",
  "hhaca",
  "klkek",
  "iiigc",
  "iihim",
  "gaaca",
  "ebeae",
  "dvbvd",
  "annha",
  "cczaz",
  "ciycc",
  "fefaa",
  "ffuur",
  "bzczb",
  "hhrri",
  "grrlr",
  "ciuui",
  "lpppt",
  "gjyjy",
  "itdtd",
  "cijcc",
  "fefuu",
  "khjhh",
  "dddnp",
  "cczch",
  "arzza",
  "gewgw",
  "dddqe",
  "ffufa",
  "ivjvi",
  "cwwtt",
  "eaqqe",
  "bfbfo",
  "dmmdx",
  "iocio",
  "kvukk",
  "ilggg",
  "cdcdg",
  "hnllh",
  "gxygg",
  "ggxqg",
  "bybrb",
  "jxjbj",
  "lrttr",
  "kwkkt",
  "dmmdj",
  "hgrrr",
  "cwuwu",
  "barab",
  "ffdif",
  "crcoc",
  "csasa",
  "hhajj",
  "ererg",
  "grghg",
  "iihia",
  "bbqwb",
  "fcaca",
  "hhtqh",
  "bbwwd",
  "jzzdz",
  "ltbll",
  "irzri",
  "gfffn",
  "cycym",
  "eeqce",
  "eeook",
  "ddegg",
  "hhkka",
  "gxxgh",
  "hhrff",
  "dhvhd",
  "fzlzf",
  "chhlc",
  "avvaf",
  "lpppo",
  "iijss",
  "kxkvx",
  "kwwwp",
  "hlhuh",
  "ioaii",
  "eeovv",
  "chsss",
  "ffbll",
  "fiiiu",
  "cdcoc",
  "ddgod",
  "eqejj",
  "gxxhg",
  "ipiai",
  "dnnnp",
  "lidli",
  "ivivd",
  "gnkgg",
  "iiifv",
  "kwckw",
  "ababf",
  "gydyy",
  "hhgbg",
  "axxxm",
  "bfbqf",
  "fftfd",
  "klpkp",
  "mbmpp",
  "fffis",
  "ddewd",
  "gffig",
  "iiigy",
  "hhkeh",
  "azaya",
  "lzfll",
  "jjyqj",
  "cixxc",
  "euehe",
  "azvvz",
  "grqrg",
  "hgshh",
  "eggtt",
  "iihrr",
  "iikit",
  "hnyyn",
  "lrrmm",
  "fafba",
  "jjxxf",
  "dddqj",
  "azeaz",
  "ehepp",
  "etyty",
  "avxav",
  "buuob",
  "agggd",
  "eeroo",
  "hyshh",
  "mcttm",
  "ffssg",
  "khmmk",
  "cczcl",
  "ggbba",
  "kjbjk",
  "grege",
  "mfamf",
  "ggafa",
  "lieie",
  "eevpe",
  "jeccc",
  "chahh",
  "ahfhh",
  "bfbab",
  "bcbcs",
  "crrnr",
  "dddrz",
  "lnloo",
  "lgugu",
  "dmomd",
  "lnllt",
  "mjjbm",
  "kwwnw",
  "hyaaa",
  "jxnjj",
  "bnnvn",
  "dlzdd",
  "lznll",
  "hhtte",
  "kzzkn",
  "aohoo",
  "ghgsg",
  "awawo",
  "buuku",
  "khkpk",
  "hhtww",
  "hhhzi",
  "eheeu",
  "kwwkv",
  "eccem",
  "hhfeh",
  "aggha",
  "mbmbc",
  "crcry",
  "gigvi",
  "cdcdj",
  "biibn",
  "jeheh",
  "lrrol",
  "khkhi",
  "hhrcc",
  "ffpbp",
  "jjxjl",
  "jgggq"
];